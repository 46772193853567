import './register.css'

import * as Yup from 'yup'

import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'
import { Formik } from 'formik'
import swal from 'sweetalert'

const nameRegex = /^[0-9a-zA-Z ,.]*$/i
const phoneRegExp = /^(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/

const registerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Company Name is Too Short!')
    .max(100, 'Company Name is Too Long!')
    .matches(
      nameRegex,
      'Company Name should not contain any Non-Alpha characters'
    )
    .required('Company Name is Required'),
  businessYears: Yup.number()
    .min(0, 'Business Year cannot be smaller than 1')
    .max(100, 'Business Years cannot exceed 100 years')
    .required('Business Years is Required'),
  companyType: Yup.string()
    .oneOf([
      'Education',
      'Enterprise',
      'Government - State & Local',
      'Government - Federal',
      'Non-profit',
      'Small-Medium Business',
      'Start-up',
    ])
    .required('Company Type is Required'),
  employeeCount: Yup.number()
    .min(1, 'Number of Employees cannot be smaller than 1')
    .max(100000, 'Number of Employees cannot exceed 100000')
    .required('Employee Count is Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone Number is Required'),
  cmmcLevel: Yup.string()
    .oneOf(['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'])
    .required('CMMC Level is Required'),
  timeframe: Yup.string()
    .oneOf(['1-3 months', '3-6 months', '6-9 months', '12+ months'])
    .required('Timeframe is Required'),
})

class RegisterOrgUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      name: '',
      businessYears: undefined,
      companyType: '',
      employeeCount: undefined,
      cmmcLevel: '',
      timeframe: '',
      firstName: '',
      lastName: '',
    }
  }

  componentDidMount() {
    let registrationData = JSON.parse(localStorage.getItem('registrationData'))
    this.setState({
      firstName: registrationData.UserAttributes[0].Value,
      lastName: registrationData.UserAttributes[0].Value,
    })
  }

  submitForm = async (values, history) => {
    //Let's Create an Organization first. IF it succeeds, we will create an user.
    let orgId = await this.createOrg(values)
    if (orgId && orgId !== '') {
      //Organization is created. Create the user
      let userId = await this.createUser(orgId, values.phone)
      if (userId && userId !== '') {
        // swal('Success', 'User Created Successfully!', 'success')
        localStorage.setItem('isUserLoggedIn', 'true')
        history.push('/assessment')
      } else {
        swal('Error!', 'Error in Creating the User', 'error')
      }
    } else {
      swal('Error!', 'Error in Creating the Organization', 'error')
    }
  }

  createUser = async (orgId, phone) => {
    let userId = ''
    let email = JSON.parse(localStorage.getItem('registerEmail'))

    // const payload = {
    //   "username": email
    // }
    // const userInfo = await ApiAuth(
    //   HTTPMETHODS.POST,
    //   '/user/currentuserinfo',
    //   payload,
    //   true,
    //   email
    // )
    // if (!userInfo) return ''

    const apiUserInfo = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: email,
      // cognitoId: id,
      phone: phone,
      organizationId: orgId,
    }
    const userReturnObj = await ApiAuth(
      HTTPMETHODS.POST,
      '/user',
      apiUserInfo,
      true,
      email,
      orgId
    )

    if (userReturnObj.status === 200) {
      localStorage.setItem('userInfo', JSON.stringify(userReturnObj.data))
      userId = userReturnObj.data.id
    }

    return userId
  }

  createOrg = async (values) => {
    let orgId = ''
    const { phone, ...orgObject } = values
    const userObj = JSON.parse(localStorage.getItem('userEmail'))
    const orgInfo = await ApiAuth(
      HTTPMETHODS.POST,
      '/organization',
      orgObject,
      true,
      userObj
    )
    if (orgInfo.status === 200) {
      orgId = orgInfo.data.id
    }
    return orgId
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">Company Name </label>
          <div className="col-sm-6">
            <input
              type="text"
              name="name"
              className={
                errors.name && touched.name
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && touched.name ? (
              <small className="text-danger">{errors.name}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">
            How many years in business
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              name="businessYears"
              placeholder=""
              className={
                errors.businessYears && touched.businessYears
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.businessYears}
              onChange={handleChange}
            />
            {errors.businessYears && touched.businessYears ? (
              <small className="text-danger">{errors.businessYears}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">Company Type</label>
          <div className="col-sm-6">
            <select
              className={
                errors.companyType && touched.companyType
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              name="companyType"
              onChange={handleChange}
              value={values.companyType}
            >
              <option></option>
              <option>Education</option>
              <option>Enterprise</option>
              <option>Government - State & Local</option>
              <option>Government - Federal</option>
              <option>Non-profit</option>
              <option>Small-Medium Business</option>
              <option>Start-up</option>
            </select>
            {errors.companyType && touched.companyType ? (
              <small className="text-danger">{errors.companyType}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">Number of Employees</label>
          <div className="col-sm-6">
            <input
              type="text"
              name="employeeCount"
              placeholder=""
              className={
                errors.employeeCount && touched.employeeCount
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.employeeCount}
              onChange={handleChange}
            />
            {errors.employeeCount && touched.employeeCount ? (
              <small className="text-danger">{errors.employeeCount}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">
            Business Phone Number
          </label>
          <div className="col-sm-6">
            <input
              type="phone"
              name="phone"
              className={
                errors.phone && touched.phone
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              maxLength="14"
              value={values.phone}
              onChange={handleChange}
              onKeyUp={(e) => {
                values.phone = e.target.value.replace(
                  /(\d{3})-?(\d{3})-?(\d{4})/,
                  '($1)-$2-$3'
                )
              }}
            />
            {errors.phone && touched.phone ? (
              <small className="text-danger">{errors.phone}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">
            Which CMMC Certification is your company pursuing?
          </label>
          <div className="col-sm-6">
            <select
              className={
                errors.cmmcLevel && touched.cmmcLevel
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              name="cmmcLevel"
              onChange={handleChange}
              value={values.cmmcLevel}
            >
              <option></option>
              <option>Level 1</option>
              <option>Level 2</option>
              <option>Level 3</option>
              <option>Level 4</option>
              <option>Level 5</option>
            </select>
            {errors.cmmcLevel && touched.cmmcLevel ? (
              <small className="text-danger">{errors.cmmcLevel}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">
            Estimated timeframe for CMMC audit readiness?
          </label>
          <div className="col-sm-6">
            <select
              className={
                errors.timeframe && touched.timeframe
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              name="timeframe"
              onChange={handleChange}
              value={values.timeframe}
            >
              <option></option>
              <option>1-3 months</option>
              <option>3-6 months</option>
              <option>6-9 months</option>
              <option>12+ months</option>
            </select>
            {errors.timeframe && touched.timeframe ? (
              <small className="text-danger">{errors.timeframe}</small>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4" />
          <div className="col-sm-3 noBorder">
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary btn-rounded btn-block"
            >
              Complete Registration
            </button>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className="login-page">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h3 className="register-box-msg">Complete Company Information</h3>
              <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  businessYears: '',
                  companyType: '',
                  employeeCount: '',
                  cmmcLevel: '',
                  timeframe: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history)
                  setSubmitting(false)
                }}
                validationSchema={registerSchema}
              >
                {(props) => this.showForm(props)}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RegisterOrgUser
