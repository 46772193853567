export const bcAssessmentSummary = {
  topic: 'Assessment',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      title: 'Assessment',
    },
  ],
}

export const bcDocumentRepository = {
  topic: 'Document Repository',
  paths: [
    {
      path: 'documentRepository',
      title: 'Home',
    },
    {
      title: 'Document Repository',
    },
  ],
}

export const bcTemplateRepository = {
  topic: 'Template Repository',
  paths: [
    {
      path: 'templateRepository',
      title: 'Home',
    },
    {
      title: 'Template Repository',
    },
  ],
}

export const bcAssessmentCreate = {
  topic: 'Assessment',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      title: 'Create Assessment',
    },
  ],
}

export const bcAssessmentEdit = {
  topic: 'Assessment',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      title: 'Edit Assessment',
    },
  ],
}

export const bcPracticeSummary = {
  topic: '',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      title: 'Assessment',
    },
  ],
}

export const bcReports = {
  topic: 'Reports',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      title: 'Reports',
    },
  ],
}

export const bcManageUsers = {
  topic: 'Manage Users',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      title: 'Manage Users',
    },
  ],
}

export const bcAccountSettings = {
  topic: 'Account Settings',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      title: 'Account Settings',
    },
  ],
}

export const bcChangePassword = {
  topic: 'Change Password',
  paths: [
    {
      path: 'dashboard',
      title: 'Home',
    },
    {
      path: 'accountsettings',
      title: 'Account Settings',
    },
    {
      title: 'Change Password',
    }
  ],
}