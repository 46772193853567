import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'

import FixedHeader from './core/fixedHeader'
import Footer from './core/footer'
import Header from './core/header'
import HelpBar from './core/helpbar'
import IdleTimer from 'react-idle-timer'
import { Logout } from './core/logout'
import React from 'react'
import ScrollToTop from './ScrollToTop'
import SideBar from './core/sidebar'
import { componentPaths } from './sidebarpaths'

const isLoggedIn = (page) => {
  return localStorage.getItem('isUserLoggedIn') != null
}

const SecuredRoute = ({
  component: Component,
  index,
  handleExpand,
  handlePracticeCode,
  showInfo,
  practiceCode,
  capabilityCode,
  ...rest
}) => (
  <>
    <div className="wrapper">
      <Header />
      <div style={{ display: 'flex' }}>
        <SideBar />

        <Route
          {...rest}
          render={(props) =>
            isLoggedIn() === true ? (
              <Component
                {...props}
                handleExpand={handleExpand}
                handlePracticeCode={handlePracticeCode}
              />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        {index === 14 && (
          <HelpBar
            handleExpand={handleExpand}
            isShowInfo={showInfo}
            practiceCode={practiceCode}
            capabilityCode={capabilityCode}
          />
        )}
      </div>
      <Footer />
    </div>
  </>
)

const UnSecuredRoute = ({ component: Component, index, ...rest }) => (
  <>
    <div className="wrapper">
      {index !== 0 && index !== 12 && index !== 13 && <FixedHeader />}
      {/* <Route {...rest} render={props => (isLoggedIn() === false ? <Component {...props} /> : <Redirect to='/dashboard' />)} /> */}
      <Route {...rest} render={(props) => <Component {...props} />} />
      {index !== 0 && index !== 12 && index !== 13 && index !== 0 && <Footer />}
    </div>
  </>
)

class AppRouter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      timeout: 1000 * 60 * process.env.REACT_APP_TIME_OUT_MIN, //Time is in milliseconds. So, Setting it for 15 Minutes
      showModal: false,
      isTimedOut: false,
      showInfo: false,
      practiceCode: null,
      capabilityCode: null,
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  _onAction(e) {
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      //If the path is login, ignore the timeout
      this.idleTimer.reset()
      this.setState({ isTimedOut: false })
      return <Redirect to="/" />
    } else {
      this.handleLogout()
    }
  }

  handleExpand = () => {
    const oldFlag = this.state.showInfo
    this.setState({ showInfo: !oldFlag })
  }
  handlePracticeCode = (practiceCode, capabilityCode) => {
    this.setState({ practiceCode, capabilityCode })
  }
  handleLogout = async () => {
    this.setState({ isTimedOut: true })
    await Logout()
    return <Redirect to="/" />
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <div className="">
          <Router>
            <ScrollToTop />

            <Switch>
              {componentPaths
                .filter((item) => !item.secured)
                .map((item) => {
                  return (
                    <UnSecuredRoute
                      key={item.id}
                      index={item.id}
                      exact
                      path={item.path}
                      component={item.component}
                    />
                  )
                })}

              {componentPaths
                .filter((item) => item.secured)
                .map((item) => {
                  return (
                    <SecuredRoute
                      key={item.id}
                      index={item.id}
                      exact
                      path={item.path}
                      component={item.component}
                      handleExpand={this.handleExpand}
                      handlePracticeCode={this.handlePracticeCode}
                      showInfo={this.state.showInfo}
                      practiceCode={this.state.practiceCode}
                      capabilityCode={this.state.capabilityCode}
                    />
                  )
                })}
            </Switch>
          </Router>
        </div>
      </>
    )
  }
}

export default AppRouter
