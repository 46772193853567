import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'

import ReportsTable from '../reports/reportsTable';
import Breadcrumb from '../core/breadcrumb'
import { Card } from 'antd'
import GapReport from '../Dashboard/gapReport'

import ResponseCountReport from '../Dashboard/responseCountReport'
import ResponsePercentageReport from '../Dashboard/responsePercentageReport'
import { bcReports } from '../core/breadcrumbpaths'

class Reports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      selectedRowKey: null,
      orgName: null,
      isFreeOrg: true,
      cmmcVersion: null,
      cmmcLevel: null,
      score: 0,
      baseURL1: null,
      baseURL2: null,
      baseURL3: null,
      gapResponseData: [],
      isAnswered: null,
    }
    this.onSelectChange = this.onSelectChange.bind(this)
    this.handleBaseURL = this.handleBaseURL.bind(this)
  }

  componentDidMount = async () => {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    this.getReports(userObj);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(this.state.reports) !==
        JSON.stringify(nextState.reports) ||
      this.state.selectedRowKey !== nextState.selectedRowKey ||
      this.state.baseURL1 !== nextState.baseURL1 ||
      this.state.baseURL2 !== nextState.baseURL2 ||
      this.state.baseURL3 !== nextState.baseURL3
    )
      return true
    return false
  }

  getReports = async (userObj) => {
    try{
      const lstReports = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/reports?orgId=${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (lstReports.data !== null) {
        let downloadUrl;
        let url
        try {
          downloadUrl = await ApiAuth(
            HTTPMETHODS.GET,
            `/organization/generatetoken/sts?orgId=${userObj.organizationId}&file=System_Security_Plan_Level_1.docx`,
            null,
            false,
            userObj.email,
            userObj.organizationId
          )
          console.log('download URL',downloadUrl)
          url = downloadUrl.data;
          console.log('URL', url);

        } catch (error) {
          console.log(error.message)
        }   
        await Promise.all( 
          lstReports.data.map(async (item) => {
            if (item.reportType.length > 20) {
              item.reportType = item.reportType.substring(0, 20)
            }
           /*  var oldDate = item.generatedDate.split(' ')[0]
            var mm = oldDate.split('-')[1]
            var dd = oldDate.split('-')[2]
            var yy = oldDate.split('-')[0]
            var newDate = mm + '/' + dd + '/' + yy
            var newTime = item.generatedDate.split(' ')[1].split('.')[0]
            item.generatedDate = newDate + ' ' + newTime */
            console.log('BEFORE RETURN URL', url);
            item.url = url;
            return item
          })
        )
        console.log('reports', lstReports.data);
        this.setState({ reports: lstReports.data })
        return lstReports.data;
      }
    } catch (error) {
      console.log(error)
      this.setState({ reports: null })
      this.setState({ error_message: error.message })
    }
  }

  

  onSelectChange = async (key, level, isAnswered) => {
    if (this.state.isFreeOrg && this.state.isAnswered)
      document.getElementById('generateReport1').disabled = true
    this.setState({
      selectedRowKey: key,
      cmmcLevel: level,
      isAnswered: isAnswered,
    })
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    try {
      let gapResponseData = await ApiAuth(
        HTTPMETHODS.GET,
        `/response/actionPlanPriority?assessId=${key[0]}&level=${level}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      gapResponseData = gapResponseData.data.sort(function (a, b) {
        if (a.practiceCode > b.practiceCode) {
          return 1
        }
        if (a.practiceCode < b.practiceCode) {
          return -1
        }
        return 0
      })
      gapResponseData = gapResponseData.filter((item) => item.isFree === true)
      this.setState({ gapResponseData })
    } catch (error) {
      console.log(error.message)
    }
  }

  handleBaseURL = (index, val) => {
    if (index === 1 && val) this.setState({ baseURL1: val })
    else if (index === 2 && val) this.setState({ baseURL2: val })
    else if (index === 3 && val) this.setState({ baseURL3: val })
  }
  render() {
    const {
      reports,
      selectedRowKey,
      isAnswered,
      cmmcLevel,
      isFreeOrg,
      baseURL1,
      baseURL2,
      baseURL3,
      gapResponseData,
    } = this.state
    if (
      isFreeOrg &&
      selectedRowKey &&
      isAnswered &&
      baseURL1 &&
      baseURL2 &&
      baseURL3 &&
      gapResponseData
    ) {
      setTimeout(function () {
        document.getElementById('generateReport').disabled = false
      }, 1200)
    }
    return (
      <div
        className="content-wrapper"
        style={{
          width: '100%',
          height: '100%',
          marginLeft: '0px',
          overflow: 'hidden',
        }}
      >
        <Breadcrumb pathsObject={bcReports} />
        <section className="content">
          <div className="container-fluid">
            <Card className="card" bordered={false}>
              {reports && (
                <div className="row mb-2">
                  <div style={{ width: '100%' }}>
                    <ReportsTable
                      onSelectChange={this.onSelectChange}
                      reports={reports}
                    />
                  </div>
                </div>
              )}
            </Card>
            {selectedRowKey && isAnswered && (
              <>
                <div style={{ marginLeft: '5000px' }}>
                  <ResponseCountReport
                    handleBaseURL={this.handleBaseURL}
                    assessId={selectedRowKey[0]}
                    level={cmmcLevel}
                    width={800}
                    height={320}
                  />
                </div>
                <div style={{ display: 'none' }}>
                  <ResponsePercentageReport
                    handleBaseURL={this.handleBaseURL}
                    assessId={selectedRowKey[0]}
                    level={cmmcLevel}
                    // width={500}
                    // height={200}
                  />
                  <GapReport
                    handleBaseURL={this.handleBaseURL}
                    // width={600}
                    // height={250}
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    )
  }
}

export default Reports
