import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import './register.css'
import { ApiAuth, HTTPMETHODS, ApiAuthInitialState } from './api'
import { decode as base64_decode, encode as base64_encode } from 'base-64';

const confirmSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Email is Too Short!')
    .max(50, 'Email is Too Long!')
    .required('Email is Required'),
  code: Yup.string()
    .min(2, 'Code is Too Short!')
    .max(50, 'Code is Too Long!')
    .required('Code is Required'),
})

class ConfirmRegisterInviteUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      name: '',
      businessYears: undefined,
      companyType: '',
      employeeCount: undefined,
      cmmcLevel: '',
      timeframe: '',
      password: '',
      key: ''
    }
  }
  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('registereduserDetailKey'))
    const decoded = base64_decode(userInfo);
    this.setState({
      key: decoded
    })
  }

  submitForm = async (values, history) => {
    try {
      const payload =
      {
        "confirmationCode": values.code,
        "username": values.email
      }
      const user = await ApiAuthInitialState(
        HTTPMETHODS.POST,
        `/confirmregister`,
        payload,
        false,
        payload.Username
      )
      if (user) {
        const userLogin = await this.signin(payload.username)
        if (userLogin) {
          const orgUserCreation = await this.createUser(values.email)
          if (orgUserCreation) {
            history.push('/login')
          }
        }
      }
    } catch (error) {
      swal('Error!', error.message, 'error')
      console.log('error signing in', error)
    }
  }

  signin = async (values, history) => {
    try {
      let userName = values
      let userNameInLowerCase = userName
      const payload = {
        "email": userNameInLowerCase,
        "password": this.state.key
      }
      const user = await ApiAuthInitialState(
        HTTPMETHODS.POST,
        `/signin`,
        payload,
        false,
        payload.email,
        // userObj.organizationId
      )

      if (user) {
        const token = user.data.token
        const accessToken = user.data.accessToken
        localStorage.setItem('token', token)
        localStorage.setItem('accessToken', accessToken)
        return user
      }
    } catch (error) {
      swal('Error!', error.message, 'error')
      console.log('error signing in', error)
    }
  }

  createUser = async (values) => {
    let userId = ''
    const userInfo = JSON.parse(localStorage.getItem('registereduserDetail'))
    if (!userInfo) return ''
    const { firstName, lastName, orgId, phone, email } = userInfo
    const apiUserInfo = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      // cognitoId: '',
      phone: phone,
      organizationId: orgId,
      role:'member'
    }
    const userReturnObj = await ApiAuth(
      HTTPMETHODS.POST,
      '/user',
      apiUserInfo,
      true,
      email,
      orgId
    )

    if (userReturnObj.status === 200) {
      localStorage.setItem('userInfo', JSON.stringify(userReturnObj.data))
      userId = userReturnObj.data.id
    }

    return userId
  }

  resendOTPMethod = async () => {
    try {
      var usernameData = JSON.parse(localStorage.getItem('registereduserDetail'))
      const usernameString = usernameData.email
      const payload =
        { "username": usernameString }
      const resendOTPResponse = await ApiAuthInitialState(
        HTTPMETHODS.POST,
        `/resendotp`,
        payload,
        false,
        usernameString
      )
      if (resendOTPResponse) {
        swal('Success!', "Code has been sent to your registered email", 'success')
      }
    } catch (error) {
      swal('Error!', error.message, 'error')
      console.log('error signing in', error)
    }
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <div className="form-group has-feedback row">
          <label className="col-sm-3 col-form-label">Email</label>
          <div className="col-sm-9">
            <input style={{ border: 'none' }}
              type="email"
              name="email"
              placeholder="name@example.com"
              className={
                errors.email && touched.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-3 col-form-label">Code</label>
          <div className="col-sm-9">
            <input style={{ border: 'none' }}
              name="code"
              type="text"
              placeholder="Confirmation Code"
              className={
                errors.code && touched.code
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.code}
              onChange={handleChange}
            />
            {errors.code && touched.code ? (
              <small className="text-danger">{errors.code}</small>
            ) : null}
          </div>
        </div>

        <div className="row justify-content-md-center">
          <button
            disabled={isSubmitting}
            type="submit"
            className="col-6 btn btn-primary btn-rounded btn-block"
          >
            Confirm Signup
          </button>
        </div>
        <br />
      </form>
    )
  }

  render() {
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="card">
            <div className="card-body">
              <h3 className="register-box-msg">Confirm Sign Up</h3>
              <Formik
                initialValues={{
                  email: '',
                  code: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history)
                  setSubmitting(false)
                }}
                validationSchema={confirmSchema}
              >
                {(props) => this.showForm(props)}
              </Formik>
              <p className="mb-1">
                {/* <Link to="/register/resendCode">Resend Code ? </Link> */}
                <Link onClick={() => this.resendOTPMethod()} >Resend Code?</Link>
                <Link
                  to="/login"
                  style={{ position: 'absolute', right: '20px' }}
                >
                  Sign In
                </Link>
              </p>
            </div>
            {/* /.register-card-body */}
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmRegisterInviteUser
