import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'

import ChartElement from './ChartElement'
class responsePercentageReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      labels: [
        'Yes',
        'No',
        'Partially',
        'Does Not Apply',
        'Alternative Approach',
        // 'Not Answered',
      ],

      backgroundColor: [
        '#3498db',
        '#e67300',
        '#C8C8C8',
        '#ffbf00',
        '#0033cc',
        // '#ff0000',
      ],
      responsePercentageData: [],
    }

    this.getResponsePercentage = this.getResponsePercentage.bind(this)
  }

  componentDidMount = async () => {
    await this.getResponsePercentage()
    const { backgroundColor, responsePercentageData, labels } = this.state
    const chartData = {
      labels: labels,
      datasets: [
        {
          backgroundColor: backgroundColor,
          data: responsePercentageData,
        },
      ],
    }

    const chartOptions = {
      responsive: false,
      legend: {
        labels: {
          fontSize: 10,
        },
        position: 'bottom',
        onClick: (e) => e.stopPropagation(),
      },
      animation: {
        animateScale: false,
        animateRotate: false,
        easing: 'easeOutQuart',

        onComplete: function () {
          var ctx = this.chart.ctx
          ctx.textAlign = 'center'
          ctx.textBaseline = 'center'

          chartData.datasets.forEach(function (dataset) {
            for (var i = 0; i < dataset.data.length; i++) {
              var model =
                  dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                mid_radius =
                  model.innerRadius +
                  (model.outerRadius - model.innerRadius) / 2,
                start_angle = model.startAngle,
                end_angle = model.endAngle,
                mid_angle = start_angle + (end_angle - start_angle) / 2

              var x = mid_radius * Math.cos(mid_angle)
              var y = mid_radius * Math.sin(mid_angle)

              ctx.fillStyle = '#000'
              var percent
              if (dataset.data[i] / total === 0) percent = ''
              else
                percent =
                  String(Math.round((dataset.data[i] / total) * 100)) + '%'
              // ctx.fillText(dataset.data[i], model.x + x, model.y + y)
              // Display percent in another line, line break doesn't work for fillText
              ctx.fillText(percent, model.x + x, model.y + y)
            }
          })
        },
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
    }
    this.setState({ chartData, chartOptions })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }
  async getResponsePercentage() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    try {
      let responsePercentageData = await ApiAuth(
        HTTPMETHODS.GET,
        `/response/percentage?assessId=${this.props.assessId}&level=${this.props.level}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      let tempData = []
      let tempLabel = [
        'yes',
        'no',
        'partial',
        'donotApply',
        'alternatives',
        // 'notAnswered',
      ]
      tempLabel.forEach((item) => {
        tempData.push(responsePercentageData.data[item])
      })
      this.setState({
        responsePercentageData: tempData,
      })
    } catch (e) {
      console.log(e.message)
    }
  }

  render() {
    const { chartData, chartOptions } = this.state
    const { height, width } = this.props
    return (
      <div>
        <ChartElement
          chartId="responsePercentageChart"
          type="pie"
          data={chartData}
          options={chartOptions}
          height={height}
          width={width}
          handleBaseURL={this.props.handleBaseURL}
        />
      </div>
    )
  }
}

export default responsePercentageReport
