// import { Auth } from 'aws-amplify'
import Axios from 'axios'
import swal from 'sweetalert'

export async function API(
  method,
  path,
  payload = null,
  sendContentType = false,
  props
) {
  let headers = {}

  Axios.interceptors.response.use(
    (response) => {
      // Return a successful response back to the calling service
      return response
    },
    (error) => {
      const {
        response: { status },
      } = error
      // Return any error which is not due to authentication back to the calling service
      if (status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      } else {
        alertOnError(error)
      }
    }
  )

  if (sendContentType) {
    headers = {
      'content-type': 'application/json;',
    }
  }

  const options = {
    method: method,
    url: process.env.REACT_APP_API_URL + path,
    headers: headers,
    data: payload,
  }

  return Axios(options)
}

const alertOnError = async (error, props) => {
  console.log('in Error log')
  await swal('Sorry!', error.message, 'error').then((value) => {
    delete Axios.defaults.headers.common.Authorization
    // Auth.signOut()
    localStorage.removeItem('isUserLoggedIn')
    localStorage.removeItem('userInfo')
    props.history.push('/login')
  })
}

export const HTTPMETHODS = {
  PUT: 'put',
  POST: 'post',
  GET: 'get',
  DELETE: 'delete',
}

export const ApiAuth = async (
  method,
  path,
  payload = null,
  sendContentType = false,
  userEmail = null,
  orgId = null,
  multipartFile = false,
  signal = null
) => {
  let headers = {}

  Axios.defaults.headers.common.Authorization =  'Bearer ' + localStorage.getItem('token')

  if (sendContentType) {
    headers = {
      'content-type': 'application/json;',
    }
  }
  if (multipartFile) {
    headers = {
      'content-type': 'multipart/form-data',
    }
  }
  headers = { ...headers, 'User-Email': userEmail, 'Org-Id': orgId }
  const options = {
    method: method,
    url: process.env.REACT_APP_API_AUTH_URL + path,
    headers: headers,
    data: payload,
  }
  return Axios(options, signal)
}

export const ApiAuthInitialState = async (
  method,
  path,
  payload = null,
  sendContentType = false,
  userEmail = null,
  orgId = null,
  multipartFile = false,
  signal = null
) => {
  let headers = {}

  if (sendContentType) {
    headers = {
      'content-type': 'application/json;',
    }
  }
  if (multipartFile) {
    headers = {
      'content-type': 'multipart/form-data',
    }
  }
  headers.Authorization = '';
  headers = { ...headers, 'User-Email': userEmail, 'Org-Id': orgId }
  const options = {
    method: method,
    url: process.env.REACT_APP_API_AUTH_URL + path,
    headers: headers,
    data: payload,
  }
  return Axios(options, signal)
}
