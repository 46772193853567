import React, { Component } from 'react'
import swal from 'sweetalert'
import { withRouter } from 'react-router-dom'
import './layout.css'
import Logo from '../../assets/logo_new.jpg'

import { Auth } from 'aws-amplify'

// const getBtnProps = () => {
//   // if (path === '/') {
//   //   return false
//   // }
//   // console.log('path', path)
//   // if (path.includes('login')) {
//   //   return { label: 'SignUp in the Console', link: '/register' }
//   // } else {
//   return { label: 'Sign in the Console', link: '/login' }
// }

class FixedHeader extends Component {
  Logout = (e) => {
    e.preventDefault()
    swal('Are your sure to SignOut?', {
      buttons: {
        nope: {
          text: 'Let me back',
          value: 'nope',
        },
        sure: {
          text: "I'm, Sure",
          value: 'sure',
        },
      },
    }).then(async (value) => {
      switch (value) {
        case 'sure':
          try {
            // await Auth.signOut()
            localStorage.removeItem('isUserLoggedIn')
            localStorage.removeItem('userInfo')
            swal('Signed Out Successfully', 'success').then((val) => {
              return this.props.history.push('/login')
            })
          } catch (ex) {
            localStorage.removeItem('token')
            localStorage.removeItem('accessToken')
            swal('Session Expired', 'Session Expired. Try login again!')
          }
          break
        case 'nope':
          swal('Ok', 'success')
          break
        default:
          swal('Got away safely!')
      }
    })
  }

  render() {
    // const btnProps = getBtnProps()
    return (
      <nav className="main-header navbar navbar-expand navbar-expand nav_new" style={{  marginLeft: '0px',padding: '0' , height: '61px' }}>
        {/* Brand Logo */}
        <a href="/">
          <div className="brand-link">
            <img
              src={Logo}
              alt="Security Compliance Logo"
              height="48"
              width="150"
            ></img>
          </div>
        </a>
        <span className="header-title" style={{ marginLeft: '260px' }}>
          CyberSecurity Maturity Model Certification
        </span>
        {/* <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a
              href={btnProps.link}
              className="nav-link btn btn-dark"
              style={{ marginRight: '10px' }}
            >
              {btnProps.label}
            </a>
          </li>
        </ul> */}
      </nav>
    )
  }
}

export default withRouter(FixedHeader)
