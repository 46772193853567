import React, { Component } from 'react'
import { ApiAuth, HTTPMETHODS } from '../../components/core/api'
import ChartElement from './ChartElement'

class AssessmentLevel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      labels: []
    }

    this.getLevelProgressData = this.getLevelProgressData.bind(this)
  }

  componentDidMount() {
    this.setState({

      // initChartoptions: {"cutoutPercentage":80,"responsive":true,"maintainAspectRatio":false,"animation":{"animateScale":false,"animateRotate":false},"tooltips":{"yAlign":"bottom","callbacks":{label:"sampleData"}}},

      initChartoptions: { "cutoutPercentage": 80, "responsive": true, "maintainAspectRatio": false, "animation": { "animateScale": false, "animateRotate": false }, "tooltips": { "yAlign": "bottom", "callbacks": {} } },
      initData: { "datasets": [{ "labels": ["Vanilla", "Chocolate"], "data": [0, 100], "backgroundColor": ["#00b6e9", "rgb(230, 230, 230)"], "borderColor": ["#ffffff", "#ffffff"] }] }
    })
    this.getLevelProgressData()
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }
  async getLevelProgressData() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const orgId = encodeURIComponent(userObj.organizationId)
      let levels = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment/levels?orgId=${orgId}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      levels = levels.data.sort()

      this.setState({ labels: levels })
      const lvData = []
      for (let i = 0; i < levels.length; i++) {
        const score = await ApiAuth(
          HTTPMETHODS.GET,
          `/assessment/progress/level?orgId=${orgId}&level=${levels[i]}`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        )
        lvData.push(parseInt(score.data))
      }
      this.setState({ data: lvData })
    } catch (e) {
      console.log('error in fetching compliance score', e.message)
    }
  }

  render() {
    const { height } = this.props

    let chartData = [],
      chartOptions = []
    this.state.data.forEach((item) => {
      chartData.push({
        datasets: [
          {
            labels: ['Vanilla', 'Chocolate'],
            data: [item, 100 - item],
            backgroundColor: ['#00b6e9', '#f7f7f7'],
            borderColor: ['#ffffff', '#ffffff'],
          },
        ],
      })
      chartOptions.push({
        cutoutPercentage: 80,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: false,
          animateRotate: false,
        },
        tooltips: {
          yAlign: 'bottom',
          callbacks: {
            title: function (tooltipItems, chartData) {
              return ''
            },
            label: function (tooltipItem, chartData) {
              var completedStatus
              tooltipItem.index === 0
                ? (completedStatus = 'Completed')
                : (completedStatus = 'Not Completed')
              return (
                completedStatus +
                ': ' +
                chartData.datasets[tooltipItem.datasetIndex].data[
                tooltipItem.index
                ]>100?100:chartData.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                  ] +
                '%'
              )
            },
          },
        },
      })
    })

    return (
      <>
        <div className="white_box">
          <h3 class="graph_title">Assessment Progress By Level</h3>
          <div
           style={{ 
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            margin: '9px auto',
            width: '100%',
            height: '170px',
            }}
          >
            {/* {this.state.data.map((item, i) => ( */}
            {/* <div key={i}> */}
            <ChartElement
              chartId="assessmentLevelChart"
              type="doughnut"
              data={!chartData[0] ? this.state.initData : chartData[0]}
              options={!chartOptions[0] ? this.state.initChartoptions : chartOptions[0]}
              width={200}
              height={height}
            />
            <div className="assessment_heading"
              style={{

              }}
            > 
              {this.state.data[0] ? this.state.data[0] : 0}%
       <h5 className="assessment_sub_heading" style={{ textAlign: 'center', }}>
                Level {this.state.labels[0] ? this.state.labels[0] : 1}
              </h5>
            </div>

            {/* </div> */}
            {/* ))} */}
          </div>

        </div>
      </>
    )
  }
}

export default AssessmentLevel