import React, { Component } from 'react'
import { ApiAuth, HTTPMETHODS } from '../../components/core/api'
import ChartElement from './ChartElement'

class ComplianceScore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      score: 0,
    }

    this.getComplianceScore = this.getComplianceScore.bind(this)
  }

  componentDidMount() {
    this.setState({ score: parseInt(0) })
    this.getComplianceScore()
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }

  async getComplianceScore() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))

      const score = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment/score?orgId=${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      this.setState({ score: parseInt(score.data) })
    } catch (e) {
      console.log('error in fetching compliance score', e.message)
    }
  }

  render() {
    const { height, width } = this.props
    const chartData = {
      datasets: [
        {
          data: [this.state.score, 100 - this.state.score],
          backgroundColor: ['#00b6e9', 'rgb(230, 230, 230)'],
          borderColor: ['#ffffff', '#ffffff'],
          label: 'compliance score',
        },
      ],
    }

    const chartOptions = {
      responsive: true,   maintainAspectRatio: false,
      cutoutPercentage: 80,
      rotation: Math.PI,
      circumference: Math.PI,
      animation: {
        animateScale: false,
        animateRotate: false,
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItems, chartData) {
            return ''
          },
          label: function (tooltipItem, chartData) {
            var completedStatus
            tooltipItem.index === 0
              ? (completedStatus = 'Completed')
              : (completedStatus = 'Not Completed')
            return (
              completedStatus +
              ': ' +
              chartData.datasets[tooltipItem.datasetIndex].data[
                tooltipItem.index
              ]
            )
          },
        },
      },
    }

    return (
      <>
	  <div className="white_box" >
        <h3 class="graph_title">Compliance Score</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            maxHeight: 280,
            marginTop:'10px'
          }}
        > <ChartElement
            chartId="complianceChart"
            type="doughnut"
            data={chartData}
            options={chartOptions}
            height={height}
            width={width}
          />
          <p className="compliance_text low_txt">
            Low
          </p><br/>
          <p className="compliance_num low_num">
            0%
          </p>
          <p className="compliance_num high_txt_compliance_score">
            100%
          </p><br/>
         <p className="compliance_text high_num">
            High
          </p>

          <div className="com_sub_heading">
            {this.state.score}%
          </div>
          <div className="com_heading">
            Compliance Score
          </div>
          
        </div>
        </div>
      </>
    )
  }
}

export default ComplianceScore