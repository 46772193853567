import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import './login.css'
import { ApiAuth, HTTPMETHODS, ApiAuthInitialState } from './api'
const password_lowercase_Regex = /^(?=.*[a-z]).*$/
const password_uppercase_Regex = /^(?=.*[A-Z]).*$/
const password_special_Regex = /^(?=.*[@#$%^&+=]).*$/
const email_common = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho))/

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .matches(
      email_common,
      'Email Address cannot be in one of the common Email Domains'
    )
    .required('Email is Required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password has to be at least 8 characters in length!')
    .matches(
      password_lowercase_Regex,
      'Password should contain at least 1 LowerCase Character!'
    )
    .matches(
      password_uppercase_Regex,
      'Password should contain at least 1 UpperCase Character!'
    )
    .matches(
      password_special_Regex,
      'Password should contain at least 1 Special (@#$%^&+=) Character!'
    ),
})

class Login extends Component {
  componentDidMount() {
    if (localStorage.getItem('isUserLoggedIn') != null) {
      return this.props.history.push('/dashboard')
    }
  }

  getAssessments = async (userObj) => {
    try {
      const lstAssessments = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (lstAssessments.data !== null) {
        await Promise.all(
          lstAssessments.data.map(async (item) => {
            /*  if (item.name.length > 20) {
               item.name = item.name.substring(0, 20)
             } */
            var oldDate = item.updatedDate.split(' ')[0]
            var mm = oldDate.split('-')[1]
            var dd = oldDate.split('-')[2]
            var yy = oldDate.split('-')[0]
            var newDate = mm + '/' + dd + '/' + yy
            var newTime = item.updatedDate.split(' ')[1].split('.')[0]
            item.updatedDate = newDate + ' ' + newTime
            let newItem = item
            try {
              let responseCountData = await ApiAuth(
                HTTPMETHODS.GET,
                `/response/count?assessId=${item.id}&level=${item.cmmcLevel}`,
                null,
                false,
                userObj.email,
                userObj.organizationId
              )
              let totalQuestions = responseCountData.data.totalQuestions
              let answeredQuestion = totalQuestions - responseCountData.data.notAnsweredTotal
              let notAnsweredTotal = responseCountData.data.notAnsweredTotal
              if (totalQuestions === notAnsweredTotal) {
                newItem.processStatus = 'Created';
              }
              else if (notAnsweredTotal === 0) {
                newItem.processStatus = 'Completed';
              }
              else if (notAnsweredTotal > 0) {
                newItem.processStatus = 'In Progress';
              }

              newItem.questionCount = answeredQuestion + ' / ' + totalQuestions
              newItem.isAnswered = answeredQuestion === totalQuestions
            } catch (error) {
              console.log(error.message)
            }
            return newItem
          })
        )
        this.setState({ assessments: lstAssessments.data[0] })
        if(this.state.assessments.processStatus == 'Created' && this.state.assessments)
        {
           this.props.history.push('/assessment')
        }
        else {
          this.props.history.push('/dashboard')
        }
      }
    } catch (error) {
      console.log(error)
      this.setState({ assessments: null })
      if(this.state.assessments == null)
      {
         this.props.history.push('/assessment')
      }
      this.setState({ error_message: error.message })
    }
  }

  submitForm = async (values, history) => {
    try {
      let userName = values.email
      let userNameInLowerCase = userName.toLocaleLowerCase()
      const payload = {
        "email": userNameInLowerCase,
        "password": values.password
      }
      const user = await ApiAuthInitialState(
        HTTPMETHODS.POST,
        `/signin`,
        payload,
        false,
        payload.email,
        // userObj.organizationId
      )

      if (user) {
        const token = user.data.token
        const accessToken = user.data.accessToken
        localStorage.setItem('token', token)
        localStorage.setItem('accessToken', accessToken)

        const isUserAvailable = await this.getUserInfo(userNameInLowerCase)
        if (isUserAvailable === true) {
          localStorage.setItem('isUserLoggedIn', 'true')
          // history.push('/dashboard')
          this.getAssessments(this.state.userDetails)
        } else {
          history.push('/register/user')
        }
      }
    } catch (error) {
      swal('Error!', 'Invalid Username or Password!', 'error')
      console.log('error signing in', error)
      localStorage.removeItem('isUserLoggedIn')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('reloadCount')
      localStorage.removeItem('token')
      localStorage.removeItem('accessToken')
    }
  }

  getUserInfo = async (email) => {
    let userFound = false
    localStorage.setItem('userEmail', JSON.stringify(email))
    try {
      const userReturnObj = await ApiAuth(
        HTTPMETHODS.GET,
        `/user?email=${encodeURIComponent(email)}`,
        null,
        false,
        email
      )
      if (userReturnObj.status === 200) {
        this.setState({
          userDetails:userReturnObj.data
        })
        localStorage.setItem('userInfo', JSON.stringify(userReturnObj.data))
        userFound = true
      }
    } catch (error) {
      console.log('User could not be traced...', error)
    }
    return userFound
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group has-feedback row">
          <div className="col-sm-12 form_outer"><i className="fas fa-envelope"></i>
            <input
              type="email"
              name="email"
              placeholder="Name@example.com"
              className={
                errors.email && touched.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <div className="col-sm-12 form_outer"><i className="fas fa-lock"></i>
            <input
              name="password"
              type="password"
              placeholder="Password"
              className={
                errors.password && touched.password
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.password}
              onChange={handleChange}
            />
            {errors.password && touched.password ? (
              <small className="text-danger">{errors.password}</small>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary btn-rounded btn-block sign_btn"
            >
              Sign In
            </button>
          </div>
          {/* /.col */}
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="card">
            <div className="card-body">
              <p className="login-box-msg">Sign In to the Application</p>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history)
                  setSubmitting(false)
                }}
                validationSchema={loginSchema}
              >
                {(props) => this.showForm(props)}
              </Formik>
              <p className="mb-1">
                <Link to="/password/reset" style={{ color: '#1d6fb8' }} className="forget_link">Forgot Password ? </Link>
                <Link
                  to="/register"
                  style={{ float: 'right', color: '#1d6fb8' }}
                  className="register_link">
                  Register
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
