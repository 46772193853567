import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'

import FootLogo1 from '../../assets/cmmi-svs3.png'
import FootLogo2 from '../../assets/iso-9002.png'
import FootLogo3 from '../../assets/iso-27001.jpg'
import Logo from '../../assets/logo3.png'
import PracticesLogo from '../../assets/practice.png'
import RoadmapLogo from '../../assets/roadmap.png'
import boldFont from '../../assets/fonts/roboto/Roboto-Medium.ttf'
import boldItalicFont from '../../assets/fonts/roboto/Roboto-MediumItalic.ttf'
import italicFont from '../../assets/fonts/roboto/Roboto-Italic.ttf'
import regFont from '../../assets/fonts/roboto/Roboto-Regular.ttf'

// Create styles
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: regFont,
    },
    {
      src: boldFont,
      fontWeight: 'bold',
    },
    {
      src: italicFont,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: boldItalicFont,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
})

let count_AC = 0,
  count_IA = 0,
  count_MP = 0,
  count_PE = 0,
  count_SC = 0,
  count_SI = 0
const styles = StyleSheet.create({
  body: {
    padding: '90 60 90 60',
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  mainImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  footLayout: {
    width: '33%',
    height: 50,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'gray',
    padding: '5, 5, 5, 5',
    marginRight: 10,
  },
  footImage: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontSize: 26,
    textAlign: 'center',
  },
  summary: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
    paddingTop: 15,
  },
  tableContent: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#003566',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 40,
    left: 0,
    right: 55,
    textAlign: 'right',
  },
  pageFootTitle: {
    position: 'absolute',
    fontSize: 12,
    bottom: 40,
    left: 60,
    textAlign: 'left',
  },
  circle: {
    width: 100,
    height: 100,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableHeader: {
    margin: 'auto',
    flexDirection: 'row',
    height: 25,
    backgroundColor: '#1b71b7',
  },
  tableCell: {
    marginTop: 2,
    fontSize: 10,
    textAlign: 'center',
    paddingLeft: 3,
    paddingRight: 3,
  },
  tableRow: {
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'center',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  lowTitle: {
    paddingTop: 15,
    fontSize: 14,
    fontWeight: 'bold',
  },
  dot: {
    paddingTop: -15,
    fontSize: 20,
    position: 'relative',
  },
  dotText: {
    paddingTop: 3,
    paddingLeft: 7,
    fontSize: 12,
  },
  gaps: {
    marginTop: 15,
    width: '47%',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: '15, 15, 15, 15',
    borderColor: 'gray',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  responseCount: {
    marginTop: 15,
    width: '80%',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: '15, 15, 15, 15',
    borderColor: 'gray',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
})
// Create Document Component
const MyDocument = ({
  selectedRowKey,
  orgName,
  cmmcVersion,
  cmmcLevel,
  score,
  baseURL1,
  baseURL2,
  baseURL3,
  gapResponseData,
}) => {
  var today = new Date()
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  var yyyy = today.getFullYear()
  const [isReady, setIsReady] = useState(false)

  today = mm + '/' + dd + '/' + yyyy
  useEffect(() => {
    if (
      selectedRowKey &&
      orgName &&
      cmmcVersion &&
      cmmcLevel &&
      score &&
      baseURL1 &&
      baseURL2 &&
      baseURL3 &&
      gapResponseData
    )
      setIsReady(true)
  }, [
    selectedRowKey,
    cmmcVersion,
    score,
    cmmcLevel,
    orgName,
    baseURL1,
    baseURL2,
    baseURL3,
    gapResponseData,
  ])
  return (
    <>
      {isReady ? (
        <Document>
          <Page style={[styles.body, { paddingTop: 140 }]} size="A4">
            <View>
              <Image
                style={styles.mainImage}
                src={Logo}
                alt="Security Compliance Logo"
              />
            </View>
            <Text style={[styles.title, { paddingTop: 60 }]}>
              Security Compliance Gap Analysis Report
            </Text>
            <Text
              style={[
                styles.text,
                { fontSize: 14, paddingTop: 60, textAlign: 'center' },
              ]}
            >
              Specially Created for
            </Text>
            <Text
              style={[
                styles.text,
                { fontSize: 16, paddingTop: 20, textAlign: 'center' },
              ]}
            >
              {orgName}
            </Text>
            <Text
              style={[
                styles.text,
                { fontSize: 14, paddingTop: 60, textAlign: 'center' },
              ]}
              render={() => `Date Generated: ${today}`}
            />
            <Text
              style={styles.pageFootTitle}
              render={() =>
                '© 2020, InterSec, Inc. or its affiliates. All rights reserved.'
              }
              fixed
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={[styles.body, { paddingTop: 140 }]} size="A4">
            <Text style={styles.title} break>
              Security Compliance Gap Analysis Report
            </Text>

            <Text style={styles.text}>
              Copyright © 2020 InterSec, Inc. and/or its affiliates. All rights
              reserved.
            </Text>
            <Text style={[styles.text, { paddingTop: 25 }]}>
              THIS GAP ANALYSIS REPORT IS BASED ON CMMC MODEL VERSION
              {' ' + cmmcVersion}. INTERSEC, INC. PROVIDES THIS GAP ANALYSIS FOR
              INFORMATIONAL PURPOSES ONLY AND DOES NOT IMPLY OR GUARANTEE CMMC
              AUDIT READINESS AND OR COMPLIANCE.
            </Text>
            <Text style={styles.text}>
              USE OF THIS GAP ANALYSIS IS ON A AS-IS-BASIS. ANY LOSS OR DAMAGE
              CAUSED BY THE USE OF SECURITY COMPLIANCE.IO OR ITS ARTIFACTS SUCH
              AS THIS GAP REPORT IS THE SOLE RESPONSIBILITY OF THE USER.
              INTERSEC, INC DOES NOT WARRANT ACCURACY OF THE REPORT EITHER
              EXPLICITLY OR IMPLICITLY.
            </Text>
            <Text style={styles.text}>
              THE GAP ANALYSIS REPORT IS INTERSEC, INC CONFIDENTIAL – NOT
              PERMITTED FOR DISTRIBUTION OUTSIDE {orgName} WITHOUT EXPLICIT
              WRITTEN PERMISSION.
            </Text>
            <Text
              style={styles.pageFootTitle}
              render={() =>
                '© 2020, InterSec, Inc. or its affiliates. All rights reserved.'
              }
              fixed
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={styles.body} size="A4">
            <Text style={styles.tableContent}>Table of Contents</Text>
            <Text style={styles.text}>
              1. EXECUTIVE
              SUMMARY..........................................................................................................4
            </Text>
            <Text style={styles.text}>
              2. GAP
              SUMMARY......................................................................................................................6
            </Text>
            <Text
              style={styles.pageFootTitle}
              render={() =>
                '© 2020, InterSec, Inc. or its affiliates. All rights reserved.'
              }
              fixed
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={styles.body} size="A4">
            <Text style={styles.summary}>1. EXECUTIVE SUMMARY</Text>
            <Text style={styles.text}>
              The loss of controlled unclassified information (CUI) from the
              Defense Industrial Base, or DIB (America’s defense contractors),
              increases the risk to national security. To reduce this risk, the
              Department of Defense (DoD) has finally created both rules and an
              auditing mechanism that will ensure the DIB practices good
              cybersecurity hygiene. Cybersecurity Maturity Model Certification
              (CMMC) will ensure that cybersecurity is not discounted anymore
              for future acquisitions and that cybersecurity controls are in
              place and are operational within DIB environments before DoD
              contracts are awarded. Certification of contractor cybersecurity
              maturity levels will ensure that future DoD acquisitions will be
              based upon cost, schedule, and performance considerations and
              awarded only to organizations that meet or exceed the appropriate
              levels of cybersecurity controls.
            </Text>
            <Text style={styles.text}>
              Based on CMMC model version {cmmcLevel}, there are five levels,
              and each has its own specific set of controls that will be in
              scope for a CMMC audit. Each level of CMMC maturity has an
              increasing expectation.
            </Text>
            <View style={{ paddingTop: 10 }}>
              <Image
                style={styles.mainImage}
                src={PracticesLogo}
                alt="Practices Logo"
              />
            </View>
            <Text style={styles.lowTitle}>
              Impact of CMMC Compliance on Federal Government Contractors
            </Text>
            <View style={{ paddingLeft: 15 }}>
              <View style={{ flexDirection: 'row', paddingTop: 15 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  All DoD contractors will need to be CMMC certified by passing
                  a CMMC Audit to verify that they have met the appropriate
                  level of cybersecurity maturity for their business.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  Beginning September 2020, DoD will have mandatory CMMC
                  compliance requirements in some of its acquisition of goods
                  and services.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  CMMC requirements are now appearing in GWACS such as GSA 8(a)
                  STARS III and NITAAC CIO-SP4.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  The CMMC Compliance and maturity level will be a requirement
                  in sections L and M of an RFP, making cybersecurity an
                  “allowable cost” in certain DoD contracts.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  Companies that lack a current CMMC certification will be
                  unable to bid on or participate in a DoD contract in the
                  future.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  This new model for certifying the state of cybersecurity
                  operations and maturity within an organization applies to all
                  DoD prime contractors and sub-contractors.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  Certification will last 3 years, provided no incidents or
                  other triggers are inducing a second look at an organization.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  In addition to the loss of potential business, non-compliance
                  with NIST 800-171 and CMMC can lead to serious legal
                  consequences to contractor organizations as well as
                  individuals through the False Claims Act (FCA) violations.
                </Text>
              </View>
              <View style={{ flexDirection: 'row', paddingTop: 3 }}>
                <Text style={styles.dot}>&bull;</Text>
                <Text style={styles.dotText}>
                  The results of the CMMC assessment conducted by C3PAOs will
                  not be publicized. Besides, information on specific
                  certification levels will not be revealed. However, interested
                  parties can access information about the organization's CMMC
                  certification publicly. Only the DOD will have access to all
                  DIB companies’ assessment results and certification levels.
                </Text>
              </View>
            </View>
            <Text style={styles.lowTitle}>Roadmap to CMMC Certification</Text>
            <View>
              <Image
                style={[styles.mainImage, { width: '106%' }]}
                src={RoadmapLogo}
                alt="Roadmap Logo"
              />
            </View>
            <Text style={styles.lowTitle}>Key Takeways</Text>
            <View style={{ padding: '0 0 0 15' }}>
              <Text style={[styles.text, { paddingTop: 7 }]}>
                1. Based on the self-assessment data provided, {orgName} meets
                {' ' + score + '%'} requirements for CMMC Level {cmmcLevel}{' '}
                compliance.
              </Text>
              <Text style={[styles.text, { paddingTop: 7 }]}>
                2. Passing the C3PAO audit will require documented evidence of
                meeting <Text style={{ fontWeight: 'bold' }}>ALL </Text>the CMMC
                Level {cmmcLevel + ' '}
                requirements in the System Security Plan (SSP)
              </Text>
              <Text style={[styles.text, { paddingTop: 7 }]}>
                3. The Plan of Action and Milestone (POA&M) is meant to document
                security and control deficiencies as it relates to CMMC and an
                action plan to remediate them.
              </Text>
              <Text style={[styles.text, { paddingTop: 7 }]}>
                4. A System Security Plan (SSP) and Plan of Action and Milestone
                (POA&M) is CMMC Level {cmmcLevel} audit requirement.
              </Text>
              <Text style={[styles.text, { paddingTop: 7 }]}>
                5. Companies cannot pass the C3PAO audit if there are due
                POA&Ms. C3PAO will allow up to 60 days to address any audit
                findings and POA&Ms.
              </Text>
              <Text style={[styles.text, { paddingTop: 7 }]}>
                6. Only FedRAMP certified cloud products and services may choose
                <Text style={{ fontWeight: 'bold' }}> Not Applicable</Text> or
                <Text style={{ fontWeight: 'bold' }}>
                  {' '}
                  Alternative Approach
                </Text>{' '}
                for CMMC Level 1 audit readiness documentation.
              </Text>
            </View>
            <Text
              style={styles.pageFootTitle}
              render={() =>
                '© 2020, InterSec, Inc. or its affiliates. All rights reserved.'
              }
              fixed
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>

          <Page style={styles.body} size="A4">
            <Text style={styles.summary}>2. GAP SUMMARY</Text>
            <Text style={styles.text}>
              InterSec provides this Gap Analysis based on CMMC Level 1 as
              defined in CMMC Rev {cmmcLevel}. This Gap report was generated
              based on the self-assessment data provided on our Security
              Compliance CMMC Platform. This report intends to provide a
              high-level overview of the gaps and decision-making information to
              be compliant with CMMC Level 1 requirement.
            </Text>
            <Text style={styles.text}>
              As part of the Free CMMC Level {cmmcLevel + ' '}
              Self-Assessment evaluation, {orgName} response falls into the
              following categories:
            </Text>
            <View style={styles.responseCount}>
              <Text style={{ fontSize: 14 }}>
                Response Distribution By CMMC Level 1 Domains
              </Text>
              <Image src={baseURL1} style={{ width: '100%', paddingTop: 15 }} />
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={styles.gaps}>
                <Text style={{ fontSize: 14 }}>
                  Response Type Distribution for CMMC Level 1
                </Text>
                <Image
                  src={baseURL3}
                  style={{ width: '100%', paddingTop: 15 }}
                />
              </View>
              <View style={[styles.gaps, { paddingLeft: 20 }]}>
                <Text style={{ fontSize: 14 }}>Practices Completed Per</Text>
                <Text style={{ fontSize: 14 }}>Domain</Text>
                <Image
                  src={baseURL2}
                  style={{ width: '100%', paddingTop: 15 }}
                />
              </View>
            </View>
            <Text style={styles.text}>
              InterSec assessment experts analyzed the data provided by{' '}
              {orgName} to identify the gaps for CMMC Level {cmmcLevel}{' '}
              requirements as published in CMMC model version {cmmcVersion}.
            </Text>
            <Text style={[styles.text, { paddingBottom: 20 }]}>
              Below are our custom recommendations that are prioritized for{' '}
              {orgName} to effectively and efficiently plan CMMC Level{' '}
              {cmmcLevel} audit readiness.
            </Text>
            <Text
              style={styles.pageFootTitle}
              render={() =>
                '© 2020, InterSec, Inc. or its affiliates. All rights reserved.'
              }
              fixed
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={styles.body} size="A4">
            {/* table */}
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <View style={[styles.tableCol, { width: '18%' }]}>
                  <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                    CMMC Domains
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: '50%' }]}>
                  <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                    CMMC Level {cmmcLevel} Practices
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                    Priority
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: '17%' }]}>
                  <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                    Action Plan
                  </Text>
                </View>
              </View>
              {gapResponseData
                ? gapResponseData.map((item, index) => {
                    let domainName
                    if (item.code === 'AC') {
                      count_AC++
                      domainName = 'Access Control (AC)'
                    } else if (item.code === 'IA') {
                      count_AC = 0
                      count_IA++
                      domainName = 'Identification & Authentication (IA)'
                    } else if (item.code === 'MP') {
                      count_IA = 0
                      count_MP++
                      domainName = 'Media Protection (MP)'
                    } else if (item.code === 'PE') {
                      count_MP = 0
                      count_PE++
                      domainName = 'Physical Protection (PE)'
                    } else if (item.code === 'SC') {
                      count_PE = 0
                      count_SC++
                      domainName = 'System & Communications Protection (SC)'
                    } else if (item.code === 'SI') {
                      count_SC = 0
                      count_SI++
                      domainName = 'System & Information Integrity (SI)'
                    }
                    return (
                      <View key={index} style={styles.tableRow}>
                        {count_IA === 1 ||
                        count_MP === 1 ||
                        count_PE === 1 ||
                        count_SC === 1 ||
                        count_SI === 1 ? (
                          <View
                            style={[
                              styles.tableCol,
                              {
                                width: '18%',
                                marginTop: -1,
                                borderTopWidth: 1,
                                borderBottomWidth: 0,
                              },
                            ]}
                          >
                            <Text
                              style={[
                                styles.tableCell,
                                {
                                  textAlign: 'left',
                                  fontWeight: 'bold',
                                },
                              ]}
                            >
                              {domainName}
                            </Text>
                          </View>
                        ) : count_AC === 1 ? (
                          <View
                            style={[
                              styles.tableCol,
                              {
                                width: '18%',
                                borderTopWidth: 0,
                                borderBottomWidth: 0,
                              },
                            ]}
                          >
                            <Text
                              style={[
                                styles.tableCell,
                                {
                                  textAlign: 'left',
                                  fontWeight: 'bold',
                                },
                              ]}
                            >
                              {domainName}
                            </Text>
                          </View>
                        ) : gapResponseData.length === index + 1 ? (
                          <View
                            style={[
                              styles.tableCol,
                              {
                                width: '18%',
                                borderBottomWidth: 1,
                              },
                            ]}
                          ></View>
                        ) : (
                          <View
                            style={[
                              styles.tableCol,
                              {
                                width: '18%',
                                borderBottomWidth: 0,
                              },
                            ]}
                          ></View>
                        )}

                        <View style={[styles.tableCol, { width: '50%' }]}>
                          <Text
                            style={[styles.tableCell, { textAlign: 'left' }]}
                          >
                            <Text style={{ fontWeight: 'bold' }}>
                              {item.practiceCode}:{' '}
                            </Text>
                            {item.practiceDescription}
                          </Text>
                        </View>
                        <View style={[styles.tableCol, { width: '15%' }]}>
                          {item.priority === 'HIGH' ? (
                            <Text
                              style={[
                                styles.tableCell,
                                { fontWeight: 'bold', color: '#ff0000' },
                              ]}
                            >
                              {item.priority}
                            </Text>
                          ) : item.priority === 'MODERATE' ? (
                            <Text
                              style={[
                                styles.tableCell,
                                {
                                  fontWeight: 'bold',
                                  color: '#ffbf00',
                                },
                              ]}
                            >
                              {item.priority}
                            </Text>
                          ) : (
                            <Text
                              style={[
                                styles.tableCell,
                                { fontWeight: 'bold', color: '#267187' },
                              ]}
                            >
                              {item.priority}
                            </Text>
                          )}
                        </View>
                        <View style={[styles.tableCol, { width: '17%' }]}>
                          <Text
                            style={[styles.tableCell, { fontWeight: 'bold' }]}
                          >
                            {item.actionPlan}
                          </Text>
                        </View>
                      </View>
                    )
                  })
                : ''}
            </View>
            <Text
              style={styles.pageFootTitle}
              render={() =>
                '© 2020, InterSec, Inc. or its affiliates. All rights reserved.'
              }
              fixed
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={styles.body} size="A4">
            <Text style={styles.lowTitle}>
              Security Compliance Powered by InterSec
            </Text>
            <Text style={styles.text}>
              Your company {orgName} has taken a very important step towards
              obtaining CMMC certification by leveraging our complementary
              Security Compliance platform for CMMC Level 1 self-assessment.
              CMMC Level 1 certification is a foundation for higher-level CMMC
              certifications. Our Guided CMMC Compliance solution provides a
              comprehensive platform for CMMC-AB C3PAO audit readiness.
            </Text>
            <Text style={styles.text}>
              We are aware of the need and mandated compliance for small to
              medium contractors, sub-contractors, and suppliers. InterSec is
              uniquely positioned to offer a cost-effective platform to
              accelerate CMMC compliance. Our SecurityCompliance.io platform
              significantly cuts down the specialized advisory and professional
              services cost providing a cost-effective and competitive advantage
              to our customers.
            </Text>
            <Text style={styles.text}>
              InterSec’s CMMC solution, advisory, and consulting services can
              help organizations ensure that they are effectively and
              efficiently ingesting the appropriate data sources, in an
              automated fashion, to ensure a robust and comprehensive
              cybersecurity maturity package is available for C3PAO auditors.
            </Text>
            <Text style={styles.text}>
              From the C3PAO perspective, auditors will benefit from the
              standardized user interface/presentation of the data across
              systems and sub-organizations (this also makes auditor training
              easier and more scalable). This standard presentation of the data
              will make the evaluation and audit of contractor packages more
              efficient and consistent across certifications.
            </Text>
            <Text style={styles.text}>
              Act now and sign up for our{' '}
              <Text style={{ fontWeight: 'bold' }}>Guided CMMC Compliance</Text>{' '}
              solution to accelerate your CMMC Level {cmmcLevel} or higher audit
              readiness.
            </Text>
            <View style={{ textAlign: 'center', paddingTop: 60 }}>
              <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                InterSec, Inc.
              </Text>
              <Text style={{ fontSize: 12 }}>
                Email:{' '}
                <Link href="mailto:inquiries@intersecinc.com">
                  inquiries@intersecinc.com
                </Link>
              </Text>
              <Text style={{ fontSize: 12 }}>Phone: +1 (571)-206-8006</Text>
            </View>
            <View style={{ paddingTop: 60, flexDirection: 'row' }}>
              <View style={[styles.footLayout, { paddingTop: 7 }]}>
                <Image
                  style={styles.footImage}
                  src={FootLogo1}
                  alt="Foot Logo1"
                />
              </View>
              <View style={styles.footLayout}>
                <Image
                  style={[styles.footImage, { width: 40 }]}
                  src={FootLogo2}
                  alt="Foot Logo2"
                />
              </View>
              <View style={styles.footLayout}>
                <Image
                  style={[styles.footImage, { width: 40 }]}
                  src={FootLogo3}
                  alt="Foot Logo3"
                />
              </View>
            </View>
            <Text
              style={styles.pageFootTitle}
              render={() =>
                '© 2020, InterSec, Inc. or its affiliates. All rights reserved.'
              }
              fixed
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      ) : (
        <Document></Document>
      )}
    </>
  )
}

export default MyDocument
