import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import { Radio, Input } from 'antd'
import { bcAssessmentEdit } from '../core/breadcrumbpaths'
import Breadcrumb from '../core/breadcrumb'
import { ApiAuth, HTTPMETHODS } from '../core/api'
import DatePicker from "react-datepicker"
import _ from 'lodash'


const { TextArea } = Input

const nameRegex = /^[ A-Za-z0-9]*$/
const descriptionRegex = /^[ A-Za-z0-9_`, ?:;@./#&+-]*$/
const plannedStartDateError = ' Planned Start date is Required!'
const plannedEndDateError = ' Planned End date is Required!'

const registerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name is Too Short!')
    .max(100, 'Name is Too Long!')
    .matches(
      nameRegex,
      'Assessment Name should not contain any Non-Alpha characters'
    )
    .required('Name is Required'),
  description: Yup.string()
    .min(3, 'Description is Too Short!')
    .max(100, 'Description is Too Long!')
    .matches(
      descriptionRegex,
      'Assessment description should not contain some Non-Alpha characters'
    )
    .required('Description is Required'),
  cmmcLevel: Yup.string()
    .required('CMMC Level is Required!')
    .oneOf(['1', '2', '3', '4', '5'], 'Invalid Selection'),
    plannedStartDate: Yup.date()
    .required(plannedStartDateError),
    plannedEndDate:  Yup.date()
    .required(plannedEndDateError)
    .min(
      Yup.ref('plannedStartDate'),
      "End date cannot be before start date, Please check the dates."
    ),
})

class EditAssessment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      name: '',
      description: '',
      cmmcLevel: 0,
      plannedStartDate:_.cloneDeep(null),
      plannedEndDate:_.cloneDeep(null),
    }
  }
  componentDidMount() {
    this.getAssessments()
    // this.getAssessmentsWithDate()
  }
  getAssessments = async () => {
    const location = this.props.location
    const pathValues = location.pathname.split('/')
    const level = pathValues[4]
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    try {
      const lstAssessments = await ApiAuth(
        HTTPMETHODS.GET,
        //  `/assessment?orgId=${encodeURIComponent(userObj.organizationId)}`,
        `/assessment?orgId=${encodeURIComponent( userObj.organizationId )}&level=${level}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const assessmentData = lstAssessments.data[0]
      const startDate = this.convertDateIntoString(assessmentData.plannedStartDate)
      const endDate = this.convertDateIntoString(assessmentData.plannedEndDate)
      this.setState({
        name: assessmentData.name,
        description: assessmentData.description,
        cmmcLevel: assessmentData.cmmcLevel,
        plannedStartDate:startDate,
        plannedEndDate:endDate,
        loading: true,
      })
    } catch (error) {
      console.log(error)
    }
  }
  convertDateIntoString = (date)=>{
    var oldDate = date.split('T')[0]
    var mm = oldDate.split('-')[1]
    var dd = oldDate.split('-')[2]
    var yy = oldDate.split('-')[0]
    var newDate = mm + '/' + dd + '/' + yy
    console.log(oldDate)
    return newDate.toString()
  }
  getAssessmentsWithDate = async () => {
    const location = this.props.location
    const pathValues = location.pathname.split('/')
    const level = pathValues[4]
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    try {
      const lstAssessments = await ApiAuth(
        HTTPMETHODS.GET,
         `/assessment?orgId=${encodeURIComponent(userObj.organizationId)}`,
        // `/assessment?orgId=${encodeURIComponent( userObj.organizationId )}&level=${level}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const assessmentData = lstAssessments.data[0]
      this.setState({
        name: assessmentData.name,
        description: assessmentData.description,
        cmmcLevel: assessmentData.cmmcLevel,
        plannedStartDate:_.cloneDeep(assessmentData.plannedStartDate),
        plannedEndDate:_.cloneDeep(assessmentData.plannedEndDate),
        loading: true,
      })
    } catch (error) {
      console.log(error)
    }
  }
  submitForm = async (values, history) => {
    const location = this.props.location
    const pathValues = location.pathname.split('/')
    const assessId = pathValues[3]
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    values.userId = userObj.id
    values.userName = userObj.firstName + ' ' + userObj.lastName
    values.organizationId = userObj.organizationId
    values.processStatus = 'created'
    try {
      const assessmentReturnObj = await ApiAuth(
        HTTPMETHODS.PUT,
        `/assessment?assessId=${assessId}&orgId=${encodeURIComponent(
          userObj.organizationId
        )}`,
        values,
        true,
        userObj.email,
        userObj.organizationId
      )
      if (assessmentReturnObj.status === 200) history.push('/assessment')
    } catch (e) {
      swal('Error!', 'Error in Editing the Assessment Object!', 'error')
    }
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal" id="edit_assessmentt_form">
	          <div className="row">
          <div className="col-sm-6">
        <div className="form-group has-feedback">
        <label className="col-form-label">Assessment Name</label>
            <input
              type="text"
              name="name"
              placeholder="Name of the Assessment"
              value={values.name}
              onChange={handleChange}
              className={
                errors.name && touched.name
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {errors.name && touched.name ? (
              <small className="text-danger">{errors.name}</small>
            ) : null}
          </div>
        </div>
          <div className="col-sm-6">
		  <div className="form-group has-feedback">   
		         <label className="col-form-label">CMMC Level</label>
            <Radio.Group
              defaultValue="1"
              buttonStyle="solid"
              name="cmmcLevel"
              onChange={(values.cmmcLevel = 1)}
              value="1"
              // value={(values.cmmcLevel = 1)}
            >
              <Radio.Button value="1">Level 1</Radio.Button>
              <Radio.Button value="2" disabled>
                Level 2
              </Radio.Button>
              <Radio.Button value="3" disabled>
                Level 3
              </Radio.Button>
              <Radio.Button value="4" disabled>
                Level 4
              </Radio.Button>
              <Radio.Button value="5" disabled>
                Level 5
              </Radio.Button>
            </Radio.Group>
            {errors.cmmcLevel && touched.cmmcLevel ? (
              <small className="text-danger">{errors.cmmcLevel}</small>
            ) : null}
          </div>
        </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group has-feedback" style={{display:'flex'}}>
            <div >
            <label className="col-form-label sm-8">Planned Start Date</label>
                      <DatePicker
                       autoComplete="off"
                       class="fa fa-calendar" aria-hidden="true"
                        id='plannedStartDate'
                        name='plannedStartDate'
                        error={errors.plannedStartDate} 
                        selected={values.plannedStartDate}
                        dateFormat="MM-dd-yyyy"
                        onChange={date => setFieldValue('plannedStartDate', date)}
                        className={
                          errors.plannedStartDate && touched.plannedStartDate
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                      {errors.plannedStartDate && touched.plannedStartDate ? (
               <> <br/><small className="text-danger">{errors.plannedStartDate}</small></>
              ) : null}
              </div>
    <div >
            <label className="col-form-label sm-8">Planned End Date</label>
                      <DatePicker
                       autoComplete="off"
                       class="fa fa-calendar" aria-hidden="true"
                        id='plannedEndDate'
                        name='plannedEndDate'
                        error={errors.plannedEndDate} 
                        selected={values.plannedEndDate}
                        dateFormat="MM-dd-yyyy"
                        onChange={date => setFieldValue('plannedEndDate', date)}
                        className={
                          errors.plannedEndDate && touched.plannedEndDate
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                       {errors.plannedEndDate && touched.plannedEndDate ? (
                <><br/><small className="text-danger">{errors.plannedEndDate}</small></>
              ) : null}
                    </div>
               
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group has-feedback">
           
            </div>
          </div>
        </div>
        <div className="form-group has-feedback row">
          <div className="col-sm-12">
          <label className="col-form-label">Description</label>
            <TextArea
              rows={4}
              name="description"
              placeholder="A brief description of the assessment to document its scope and intended purpose"
              className={
                errors.description && touched.description
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.description}
              onChange={handleChange}
            ></TextArea>

            {errors.description && touched.description ? (
              <small className="text-danger">{errors.description}</small>
            ) : null}
          </div>
        </div>
        
        <div className="row" id="assess_btn">
          <button
            onClick={(e) => {
              this.props.history.push('/assessment')
            }}
            type="button"
            className="col-2 btn btn-sm btn-secondary btn-rounded mr-2"
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            type="submit"
            className="col-2 btn btn-primary btn-rounded btn-block mr-2"
          >
            Save
          </button>
        </div>
      </form>
    )
  }

  render() {
    const { loading, name, description, cmmcLevel, plannedStartDate,plannedEndDate } = this.state
   
      // const startDate = plannedStartDate.toString()
      // const endDate =  plannedEndDate.toString()
      console.log(plannedStartDate)
   
    return (
      <div
        className="content-wrapper"
        style={{ width: '100%', height: '100%', marginLeft: '0px' }}
      >
        <Breadcrumb pathsObject={bcAssessmentEdit} />
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                {loading && (
                  <Formik
                    initialValues={{
                      name: name,
                      description: description,
                      cmmcLevel: cmmcLevel,
                      plannedStartDate:plannedStartDate?new Date(plannedStartDate):null,
                      plannedEndDate:plannedEndDate?new Date(plannedEndDate):null,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      this.submitForm(values, this.props.history)
                      setSubmitting(false)
                    }}
                    validationSchema={registerSchema}
                  >
                    {(props) => this.showForm(props)}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default EditAssessment
