import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { ApiAuth, HTTPMETHODS, ApiAuthInitialState } from './api'
import './register.css'

const email_common = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho))/

const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .matches(
      email_common,
      'Email Address cannot be in one of the common Email Domains'
    )
    .required('Email is Required'),
})

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  submitForm = async (values, history) => {
    try {
      const payload = {
        "username": values.email
      }
      const user = await ApiAuthInitialState(
        HTTPMETHODS.POST,
        `/forgotpassword`,
        payload,
        false,
        payload.username
      )
      if (user) {
        //Sign up with Cognito is successful.
        swal(
          'Password Reset Successful!',
          'Please complete your authentication to proceed!',
          'success'
        ).then((value) => {
          history.push('/password/confirm')
        })
      }
    } catch (error) {
      let messageError = error.message
      if (error.code === 'UserNotFoundException') {
        messageError = 'User not found! Please try with a valid user'
      }
      swal('Error!', messageError, 'error')
      console.log('error signing in', error)
    }
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <p style={{ textAlign: 'center' }} className="text_sub">
          Enter your account email address to receive a code to <br /> reset your
          password.
        </p>
        <div className="form-group has-feedback row">
          <div className="col-sm-12 form_outer"><i className="fas fa-envelope"></i>
            <input
              type="email"
              name="email"
              placeholder="Name@example.com"
              className={
                errors.email && touched.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : null}
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-12">
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary btn-rounded btn-block sign_btn"
            >
              Reset Password
          </button>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className="login-page">
        <div className="col-5">
          <div className="card">
            <div className="card-body">
              <h3 className="register-box-msg msz2">
                Get help signing in to your account
              </h3>
              <Formik
                initialValues={{
                  email: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history)
                  setSubmitting(false)
                }}
                validationSchema={registerSchema}
              >
                {(props) => this.showForm(props)}
              </Formik>
              <p className="mb-1">
                
             {/* <Link
                  to="/login"
                  to="/dashboard"
                  style={{ color: '#1d6fb8' }}
                  className="register_link">
                  Sign In
                </Link>  */}

                  <Link
                  to="/login"
                  style={{ color: '#1d6fb8' }}
                  className="register_link">
                  Sign In
                </Link>
              </p>
            </div>
            {/* /.register-card-body */}
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPassword


