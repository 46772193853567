import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'
import BoxContainer from './boxCointainer'
//import ResponseDistributionTable from './responseDistributionTable'
import ChartElement from './ChartElement'
import Table from 'react-bootstrap/Table'

const Yes = '#3eb7e9'
const No = '#899dcc'
const Partially = '#feac5e'
const DoesNotApply = '#e39397'
const AlternativeApproach = '#c779d0'
const tableLabels = ['AC', 'IA', 'MP', 'PP', 'SC', 'SI'];

let calculatedWidth, calculatedHeight

class ResponseDistributionChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      yesResponseForPractices: [0, 0, 0, 0, 0, 0],
      noResponseForPractices: [0, 0, 0, 0, 0, 0],
      partialResponseForPractices: [0, 0, 0, 0, 0, 0],
      doesNotApplyResponseForPractices: [0, 0, 0, 0, 0, 0],
      anotherApproachResponseForPractices: [0, 0, 0, 0, 0, 0],

      labels: ["Access Control (AC)", "Identification and Authentication (IA)", "Media Protection (MP)", "Physical Protection (PE)", "System and Communications Protection (SC)", "System and Information Integrity (SI)"],
      chartData: {},
      chartOptions: {},
    }
    this.getOrganizationDetails = this.getOrganizationDetails.bind(this)
    this.getDomainQuestionData = this.getDomainQuestionData.bind(this)
  }

  componentWillMount = async () => {
    this.getDomainQuestionData()
    this.getOrganizationDetails()
  }
  componentDidMount = async () => {
    const modifiedWidth  = document.body.clientWidth
    if(modifiedWidth >= 1518){
    //  90%
      calculatedWidth = 400
      calculatedHeight = 186
    }
    else if(modifiedWidth >= 1366 && modifiedWidth < 1518){
      // 100%
      calculatedWidth = 140
      calculatedHeight = 150
    }
    else if(modifiedWidth >= 1228 && modifiedWidth < 1518 && modifiedWidth < 1366 ){
      // 110%
      calculatedWidth = 140
      calculatedHeight = 150
    }
    else {
      calculatedWidth = 140
      calculatedHeight = 150
    }

    await this.getDomainQuestionData()
    const { yesResponseForPractices, noResponseForPractices,
      partialResponseForPractices, doesNotApplyResponseForPractices,
      anotherApproachResponseForPractices, labels } = this.state
    const chartData = {
      labels: ['AC', 'IA', 'MP', 'PE', 'SC', 'SI'],
      // labels: ['SC', 'AC', 'PE', 'MP', 'IA', 'SI'],

      datasets: [
        {
          label: 'Yes',
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: Yes,
          data: yesResponseForPractices,

        },
        {
          label: 'No',
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: No,
          data: noResponseForPractices,

        },
        {
          label: 'Partially',
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: Partially,
          data: partialResponseForPractices,

        },
        {
          label: 'Does Not Apply',
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: DoesNotApply,
          data: doesNotApplyResponseForPractices,

        },
        {
          label: 'Alternative Approach',
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: AlternativeApproach,
          data: anotherApproachResponseForPractices,
        },
      ],
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        onClick: (e) => e.stopPropagation(),

        labels: {
          fontColor: "#333",
          fontSize: 12
        }
      },

      animation: {
        animateScale: true,
        animateRotate: false,
      },
      scales: {
        xAxes: [
          {
            borderWidth: 50,
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              max: this.state.OrganizationStatus?4:10,
              min: 0,
            },
          },
        ],
      },
    }
    this.setState({ chartData, chartOptions })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }

 
  async getAssessmentIDResponseDistributionPerDomain() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const orgId = encodeURIComponent(userObj.organizationId)
      let assessmentId = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${orgId}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const assessmentIdValue = assessmentId;

      this.setState({ assessmentId: assessmentId })

      return assessmentId

    } catch (e) {
      console.log('error in fetching responsive type distribution score', e.message)
    }
  }

  async getOrganizationDetails() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const orgId = encodeURIComponent(userObj.organizationId)
      let OrgDetails = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/${orgId}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const OrganizationDetails = OrgDetails;

      this.setState({ OrganizationStatus: OrganizationDetails.data.isFreeOrg })

      return OrganizationDetails

    } catch (e) {
      console.log('error in fetching responsive type distribution score', e.message)
    }
  }


  async getDomainQuestionData() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    // const orgId = encodeURIComponent(userObj.organizationId)
    const assessmentId = await this.getAssessmentIDResponseDistributionPerDomain()

    try {
      let domainData = await ApiAuth(
        HTTPMETHODS.GET,
        `/response/count?assessId=${assessmentId.data[0].id}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )

      const SC = domainData.data.responsesCountAssementList[0]
      const AC = domainData.data.responsesCountAssementList[1]
      const PE = domainData.data.responsesCountAssementList[2]
      const MP = domainData.data.responsesCountAssementList[3]
      const IA = domainData.data.responsesCountAssementList[4]
      const SI = domainData.data.responsesCountAssementList[5]
      


      // domainData = domainData.data.sort(function (a, b) {
      //   if (a.domainName > b.domainName) {
      //     return 1
      //   }
      //   if (a.domainName < b.domainName) {
      //     return -1
      //   }
      //   return 0
      // })
      
      let yesResponseForPractices = [],
        noResponseForPractices = [],
        partialResponseForPractices = [],
        doesNotApplyResponseForPractices = [],
        anotherApproachResponseForPractices = [],
        labels = []

      domainData.data.responsesCountAssementList.forEach((item) => {
        labels.push(item.domainName + ' (' + item.domainCode + ')')
        yesResponseForPractices.push(item.yes?item.yes:0)
        noResponseForPractices.push(item.no?item.no:0)
        partialResponseForPractices.push(item.partially?item.partially:0)
        doesNotApplyResponseForPractices.push(item.doesNotApply?item.doesNotApply:0)
        anotherApproachResponseForPractices.push(item.alternativeApproach?item.alternativeApproach:0)
      })
            // labels: ['AC', 'IA', 'MP', 'PE', 'SC', 'SI'], to be 
            // labels: ['SC', 'AC', 'PE', 'MP', 'IA', 'SI'], order

            let yesDataResponse = [yesResponseForPractices[1],yesResponseForPractices[4],yesResponseForPractices[3],
                                   yesResponseForPractices[2],yesResponseForPractices[0],yesResponseForPractices[5]]
            let noDataResponse = [noResponseForPractices[1],noResponseForPractices[4],noResponseForPractices[3],
                                   noResponseForPractices[2],noResponseForPractices[0],noResponseForPractices[5]]
            let partialDataResponse = [partialResponseForPractices[1],partialResponseForPractices[4],partialResponseForPractices[3],
                                   partialResponseForPractices[2],partialResponseForPractices[0],partialResponseForPractices[5]]
            let doesNotApplyResponse = [doesNotApplyResponseForPractices[1],doesNotApplyResponseForPractices[4],doesNotApplyResponseForPractices[3],
                                   doesNotApplyResponseForPractices[2],doesNotApplyResponseForPractices[0],doesNotApplyResponseForPractices[5]]
            let anotherApproachResponse = [anotherApproachResponseForPractices[1],anotherApproachResponseForPractices[4],anotherApproachResponseForPractices[3],
                                   anotherApproachResponseForPractices[2],anotherApproachResponseForPractices[0],anotherApproachResponseForPractices[5]]

          
        yesResponseForPractices = yesDataResponse
        noResponseForPractices = noDataResponse
        partialResponseForPractices = partialDataResponse
        doesNotApplyResponseForPractices = doesNotApplyResponse 
        anotherApproachResponseForPractices = anotherApproachResponse


      this.setState({
        labels, yesResponseForPractices, noResponseForPractices,
        partialResponseForPractices, doesNotApplyResponseForPractices,
        anotherApproachResponseForPractices,domainData
      })
    } catch (e) {
      console.log('error in fetching compliance score', e.message)
    }
  }
  render() {
    const { chartData, chartOptions, yesResponseForPractices, noResponseForPractices,
      partialResponseForPractices, doesNotApplyResponseForPractices,
      anotherApproachResponseForPractices } = this.state
    const { width, height } = this.props
    return (
      <>
        <div className="white_box">
          <h3 class="graph_title">Response Distribution Per Domain</h3>
          <div
          
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left',
              padding: '3px',
            }}
          >
            <ChartElement
              chartId="questionareChart"
              type="bar1"
              data={chartData}
              options={chartOptions}
              width={width}
              height={height}
            />
          </div>
{/*
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left',
              padding: '10px',
             
            }}
          > */}
            {/* <ResponseDistributionTable data={this.state} yesResponseForPractices = {yesResponseForPractices}/> */}
            {/* <Table size="sm" style={{border: 'none',padding:'1px',whiteSpace: 'pre'}}>
              <thead>
                <tr>
                  <th></th>
                  {tableLabels.map((data) =>
                    <td>{data}</td>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <BoxContainer color={Yes} />
                    Yes</td>
                  {this.state.yesResponseForPractices.map((data) =>
                    <td>{data}</td>
                  )}
                </tr>
                <tr>
                  <td>
                    <BoxContainer color={No} />
                    No</td>
                  {noResponseForPractices.map((data) =>
                    <td>{data}</td>
                  )}
                </tr>
                <tr>
                  <td>
                    <BoxContainer color={Partially} />
                   Partially</td>
                  {partialResponseForPractices.map((data) =>
                    <td>{data}</td>
                  )}
                </tr>
                <tr>
                  <td>
                    <BoxContainer color={DoesNotApply} />
                   Does Not Apply</td>
                  {doesNotApplyResponseForPractices.map((data) =>
                    <td>{data}</td>
                  )}
                </tr>
                <tr>
                  <td>
                    <BoxContainer color={AlternativeApproach} />
                 Alternative Approach</td>
                  {anotherApproachResponseForPractices.map((data) =>
                    <td>{data}</td>
                  )}
                </tr>
              </tbody>
            </Table>

          </div>
         */}
            <div
              style={{
                justifyContent: 'center',
                position: 'relative',
                marginTop: '1px',
                marginLeft: '10%',
                // marginRight: '0px',
                fontSize: '13px',
                fontWeight: '400',
                color: '#4c4c4c',
                marginBottom:'5px',
                paddingRight: '10px'
              }}
            >
              <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                <li class="list-group-item " style={{border: 'none',padding:'1px',whiteSpace: 'pre'}}>
                <BoxContainer color={Yes}  />Yes  </li> 

                <li class="list-group-item ml-2 mr-2" style={{border: 'none',padding:'1px'}}>
                <BoxContainer color={No} />No </li>

                <li class="list-group-item " style={{border: 'none',padding:'1px'}}>
                <BoxContainer color={Partially} />Partially </li>
                </ul>
                <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                <li class="list-group-item mr-2" style={{border: 'none',padding:'1px'}}>
                <BoxContainer color={DoesNotApply} />Does Not Apply</li>

                <li class="list-group-item" style={{border: 'none',padding:'1px'}}>
                <BoxContainer color={AlternativeApproach} />Alternative Approach </li>

              </ul>
            </div>

        </div>
      </>
    )
  }
}

export default ResponseDistributionChart