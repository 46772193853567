import React, { Component } from 'react'
import Chart from 'chart.js'
import classes from './doughnut.module.css'
import BoxContainer from './boxCointainer'

const Yes = '#3eb7e9'
const No = '#899dcc'
const Partially = '#feac5e'
const DoesNotApply = '#e39397'
const AlternativeApproach = '#c779d0'
const NotAnswered = '#e6e6e6'

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.legend.display = false
//--Chart Style Options--//

let mypieChart

export default class Doughnut extends Component {
  doughnutRef = React.createRef()

  componentDidMount() {
    this.buildDoughnut()
  }

  componentDidUpdate() {
    this.buildDoughnut()
  }

  buildDoughnut = () => {
    const mypieChartRef = this.doughnutRef.current.getContext('2d')
    const { data } = this.props

    if (typeof mypieChart !== 'undefined') mypieChart.destroy()

    mypieChart = new Chart(mypieChartRef, {
      type: 'pie',
      data: data,
      options: {
        //Customize chart options
      },
    })
  }

  render() {
    return (
      <div className={classes.doughnutContainer}>
        <canvas id="mypieChart" ref={this.doughnutRef} />
        <br/>
        <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                 <li class="list-group-item " style={{border: 'none',padding:'1px',whiteSpace: 'pre'}}>
                 <BoxContainer color={Yes}  />Yes  </li> 
                 <li class="list-group-item ml-2 mr-2" style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={No} />No </li>

                 <li class="list-group-item " style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={Partially} />Partially </li>
                 </ul>
                 <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                 <li class="list-group-item mr-2" style={{border: 'none',padding:'1px',paddingBottom:'5px'}}>
                 <BoxContainer color={DoesNotApply} />Does Not Apply</li>

                 <li class="list-group-item" style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={AlternativeApproach} />Alternative Approach </li>
               
               </ul>
      </div>
    )
  }
}