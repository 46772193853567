import React from 'react'
import './landingPage.css'
// import 'font-awesome/css/font-awesome.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'

const LandingPage = () => {
  return (
    <div className="term-page">
      <header>
        <div className="container">
          <div className="row align_center">
            <div className="col-xl-3 col-md-6 col-xs-12">
              <div className="logo">
                <a href="/">
                  <img src={require('../../assets/logo.jpg')} alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-xl-9 col-md-6 col-xs-12">
              <div className="right-part">
                <ul>
                  <li>
                    <Link to="/login">
                      <i
                        className="fa fa-address-card-o"
                        aria-hidden="true"
                      ></i>{' '}
                      Sign In
                    </Link>
                  </li>
                  <li>
                    <a href="https://intersecinc.com/contact-us">
                      <i className="fa fa-user-o" aria-hidden="true"></i>{' '}
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="banner_sec">
        <div className="container text-center">
          <h1>
            <span>Welcome to</span> <br />
            Security Compliance Platform
          </h1>
          <p>Powered by InterSec, Inc.</p>
          <h4>
            Complete and Cost-Effective Solution to Obtain, Manage and Maintain
            Your
          </h4>
          <h4>
            <span className="blue_clr">
              Cybersecurity Maturity Model Certification (CMMC)
            </span>
          </h4>
        </div>
      </section>

      <section className="like_to_do">
        <div className="container">
          <h2 className="text-center">
            What would you <span>like to do ?</span>
          </h2>
          <div className="two_options">
            <Link to="/register">Free CMMC Self-Assessment</Link>
            <Link to="#" style={{ background: '#d9d9d9' }} disabled>
              Guided CMMC Compliance
            </Link>
          </div>

          <div className="certifications">
            <div className="row align_center">
              <div className="col-xl-4 col-md-4">
                <div className="certification_logo">
                  <img
                    src={require('../../assets/cmmi-svs3.png')}
                    alt="cmmi-svs"
                  />
                </div>
              </div>

              <div className="col-xl-4 col-md-4">
                <div className="certification_logo">
                  <img
                    src={require('../../assets/iso-9001.jpg')}
                    alt="iso-img"
                  />
                </div>
              </div>

              <div className="col-xl-4 col-md-4">
                <div className="certification_logo">
                  <img
                    src={require('../../assets/iso-27001.jpg')}
                    alt="iso-27001"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services">
        <div className="container">
          <h2>
            Our <span>Services</span>
          </h2>
          <p>We offer CMMC assessment, advisory and professional services.</p>
          <div className="row">
            <div className="col-xl-6 col-md-6">
              <div className="list-block">
                <ul>
                  <li>Ground up CMMC compliance</li>
                  <li>Gap analysis</li>
                  <li>Control implementation guidance</li>
                  <li>Control implementation services</li>
                  <li>Develop tailored Policies and Procedures</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6">
              <div className="list-block">
                <ul>
                  <li>Develop System Security Plan (SSP)</li>
                  <li>Develop a Plan of Action and Milestones (POA&Ms)</li>
                  <li>Audit readiness and support</li>
                  <li>Maturing from one CMMC level to the next</li>
                  <li>Managed services for ongoing CMMC compliance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="security_compliance">
        <div className="container">
          <h2 className="text-center">
            Why Security <span>Compliance</span>
          </h2>
          <img
            src={require('../../assets/CMMC Certification.png')}
            className="cmmc-logo"
            alt="cmmc-certification"
          />
          <p>
            We are an accredited <strong>CMMI Svc 3</strong>,
            <strong>ISO 27001:2013</strong> and <strong>ISO 9001:2015</strong>{' '}
            cyber security company. Our specialized security team have deep
            expertise in FedRAMP, FISMA, NIST SP 800-37 RMF, NIST SP 800-53,
            NIST 800-37A, NIST 800-171 and DFARS.
          </p>

          <p>
            We have performed multiple assessments and audits for Commercial,
            State and Federal sector clients. This experience has helped us
            mature our processes, develop and mature internal controls, and
            established client data protection practices. We go beyond
            disclosing weaknesses and findings. Our Security Advisory Services
            provide remediation advise that are cost effective and tailored to
            your unique organizational needs. We provide quality and affordable
            Security Professional Services to ensure our client meet CMMC
            compliance needs in a timely manner.
          </p>
          <p>
            We will be trusted partners in your journey to obtain and maintain
            CMMC compliance. Our key differentiators include:
          </p>
          <ul>
            <li>
              Strategic partnerships with auditors and product vendors to
              provide turnkey solution to meet CMMC compliance
            </li>

            <li>
              Multiple service models that can be easily customized to meet your
              organizations' unique needs
            </li>
            <li>
              We maintain Client Data Protection Policy, Procedures,
              Infrastructure, and annual Staff training to protect our customer
              data.
            </li>
            <li>
              Our solution provides advanced content management and data
              protection including FedRAMP compliant Storage, Document
              Watermarking and Data Loss Prevention.
            </li>
            <li>
              A dedicated team of security professionals that are available to
              you throughout the CMMC compliance process.
            </li>
            <li>
              Our assessors undergoing ongoing professional training and hold
              various industry certifications such as CISSP, CCSP, CISA, CEH,
              ECSA, CHFI, CTIA, CAP, CNDA and AWS Solutions Architect.
            </li>
            <li>
              We have successfully completed more than 100 compliance
              assessments and audits across Federal, State and Commercial
              clients
            </li>
            <li>Flexible pricing models and referral credits</li>
          </ul>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <p>Powered by InterSec, Inc.</p>
            </div>
            <div className="col-xl-4 col-md-4 text-center">
              <p>Copyright © 2021 InterSec, Inc.</p>
            </div>
            <div className="col-xl-4 col-md-4 text-right">
              <ul>
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <a href="https://intersecinc.com/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default LandingPage
