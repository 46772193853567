import { ApiAuth, HTTPMETHODS } from '../../components/core/api'
import React, { Component } from 'react'
import BoxContainer from './boxCointainer'
import ChartElement from './ChartElement'

const completedColor = '#f1b44c'
const practicesColor = '#00b6e9'
const Yes = '#3eb7e9'
const No = '#899dcc'
const Partially = '#feac5e'
const DoesNotApply = '#e39397'
const AlternativeApproach = '#c779d0'
const NotAnswered = '#dee2e6'

class ResponseTypeDistribution extends Component {
  constructor(props) {
    super(props)
    this.state = {
      practiceData: [4,2,1,4,2,4],
      completedData: [0,0,0,0,0,0],
      chartData: {},
      chartOptions: {},      
    }

  }

  componentDidMount = async () => {
    let initData =  {"datasets":[{"data":[0,0,0,0,0,100]}]}
    this.setState({
      initPieChartData:initData
    })
    const modifiedWidth  = document.body.clientWidth
    await this.getResponsiveTypeDistribution()
    const loadData = this.state.initPieChartData.datasets[0].data
    const chartData = {
      labels: ['','','','', '',''],
      datasets: [
        { 
          barPercentage: 5,
          barThickness: 10,
          maxBarThickness: 26,
          minBarLength: 5,
          backgroundColor: [Yes,No,Partially,DoesNotApply,AlternativeApproach,NotAnswered],
          data: loadData,
        }
      ],
    }

    const chartOptions = {
      responsive: true,
     maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        onClick: (e) => e.stopPropagation(),
   
      labels: {
      fontColor: "#333",
      fontSize: 12
      }
      },

      animation: {
        animateScale: false,
        animateRotate: false,
      },
      scales: {
        xAxes: [
          {
            borderWidth: 50,
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              stepSize: 10,
              max: 100,
              min: 0,
            },
          },
        ],
      },
    }
    this.setState({ chartData, chartOptions })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }
  async getResponsiveTypeDistribution() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const assessmentId = await this.getAssessmentIDResponsiveTypeDistribution()
      let responseData = await ApiAuth(
        HTTPMETHODS.GET,
        `/response/percentage?assessId=${assessmentId.data[0].id}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const updatedDataset = [{
        data:
          [responseData.data.yes, responseData.data.no,
          responseData.data.partial, responseData.data.donotApply,
          responseData.data.alternatives, responseData.data.notAnswered]
      }]
      let currentData = { ...this.state.initPieChartData }
      currentData.datasets = updatedDataset
      this.setState({
        initPieChartData: currentData
      });
    

    } catch (e) {
      console.log('error in fetching responsive type distribution score', e.message)
    }
  }

  async getAssessmentIDResponsiveTypeDistribution() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const orgId = encodeURIComponent(userObj.organizationId)
      let assessmentId = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${orgId}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const assessmentIdValue = assessmentId;

      this.setState({ assessmentId: assessmentId })


      return assessmentId

    } catch (e) {
      console.log('error in fetching responsive type distribution score', e.message)
    }
  }

  render() {
    const { chartData, chartOptions } = this.state
    const {width, height} = this.props
    const widthIndicator = '10px'

    return (
      <>
    <div className="white_box">
        <h3 class="graph_title">Response Type Distribution (%)</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 10px 10px 10px',
            margin:'0px auto'
          }}
        >
          <ChartElement
            chartId="questionareChart"
            type="bar1"
            data={chartData}
            options={chartOptions}
            width={width}
            height={height}
          />
        </div>
          
        <div style={{
              marginBottom:'33px !important',
              marginTop:'20px !important',
              fontSize:'13px'
            }}>

             <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                 <li class="list-group-item " style={{border: 'none',padding:'1px',whiteSpace: 'pre'}}>
                 <BoxContainer color={Yes}  />Yes  </li> 
                 <li class="list-group-item ml-2 mr-2" style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={No} />No </li>

                 <li class="list-group-item " style={{border: 'none',padding:'1px',paddingRight:'5px'}}>
                 <BoxContainer color={Partially} />Partially </li>

                 <li class="list-group-item " style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={NotAnswered} /> NotAnswered </li>
                 </ul>
                 <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                 <li class="list-group-item mr-2" style={{border: 'none',padding:'1px',paddingBottom:'5px'}}>
                 <BoxContainer color={DoesNotApply} />Does Not Apply</li>

                 <li class="list-group-item" style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={AlternativeApproach} />Alternative Approach </li>
               
               </ul>

          </div>
        </div>
      </>
    )
  }
}

export default ResponseTypeDistribution