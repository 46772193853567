import { ApiAuth, HTTPMETHODS, ApiAuthInitialState } from './api'

export const Logout = async (userObj,token) => {
  

  var userObj = JSON.parse(localStorage.getItem('userInfo'))
  var token = localStorage.getItem('token')
  const userReturnObj = await ApiAuth(
    HTTPMETHODS.POST,
    `/signout?accessToken=${token}`,
    null,
    true,
    userObj.email,
    userObj.orgId
  )
  localStorage.removeItem('isUserLoggedIn')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('reloadCount')
  localStorage.removeItem('token')
  localStorage.removeItem('accessToken')
  return true
}                                                                                           
