import './passwordChange.css'
import { ApiAuth, HTTPMETHODS } from '../core/api'
import * as Yup from 'yup'
import React, { Component } from 'react'
import { Formik } from 'formik'
import swal from 'sweetalert'
import { Card } from 'antd'
import { bcChangePassword, bcReports } from './breadcrumbpaths'
import Breadcrumb from './breadcrumb'
const nameRegex = /^[0-9a-zA-Z '.-]*$/
const password_lowercase_Regex = /^(?=.*[a-z]).*$/
const password_uppercase_Regex = /^(?=.*[A-Z]).*$/
const password_special_Regex = /^(?=.*[@#$%^&+=]).*$/
// const email_common = /^(?=.*[A-Z a-z +])(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho|@mail|@yopmail|@hubspot|@pepipost|@protonmail|@yandex|@tutanota|@facebook))/
let email_common = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho|@mail|@yopmail|@hubspot|@pepipost|@protonmail|@yandex|@tutanota|@facebook))/


const registerSchema = Yup.object().shape({

  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password has to be at least 8 characters in length!')
    .matches(
      password_lowercase_Regex,
      'Password should contain at least 1 LowerCase Character!'
    )
    .matches(
      password_uppercase_Regex,
      'Password should contain at least 1 UpperCase Character!'
    )
    .matches(
      password_special_Regex,
      'Password should contain at least 1 Special (@#$%^&+=) Character!'
    ),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),

  newpassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password has to be at least 8 characters in length!')
    .matches(
      password_lowercase_Regex,
      'Password should contain at least 1 LowerCase Character!'
    )
    .matches(
      password_uppercase_Regex,
      'Password should contain at least 1 UpperCase Character!'
    )
    .matches(
      password_special_Regex,
      'Password should contain at least 1 Special (@#$%^&+=) Character!'
    ),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),

  confirmnewpassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password has to be at least 8 characters in length!')
    .matches(
      password_lowercase_Regex,
      'Password should contain at least 1 LowerCase Character!'
    )
    .matches(
      password_uppercase_Regex,
      'Password should contain at least 1 UpperCase Character!'
    )
    .matches(
      password_special_Regex,
      'Password should contain at least 1 Special (@#$%^&+=) Character!'
    ),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),
})

class PasswordChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      newPassword: '',
      confirmnewpassword: ''
    }
  }

  submitForm = async (values, history) => {
    if ((values.newpassword === values.confirmnewpassword) && values.newpassword != values.password) {
      const accessToken = localStorage.getItem('accessToken')
      const payload = {
        "accessToken": accessToken,
        "previousPassword": values.password,
        "proposedPassword": values.newpassword
      }
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      try {
        const pwdChangeResponse = await ApiAuth(
          HTTPMETHODS.POST,
          `/user/updatePassword`,
          payload,
          false,
          userObj.email,
          userObj.organizationId
        )
        if (pwdChangeResponse) {
          swal("Success!", "Password Updated Successfully", "success")
          this.props.history.push('/dashboard')
        }
      }
      catch (e) {
        let displayMessage = ''
        const invalid_pwd = 'Incorrect Credentials'
        const limit_exceeded = 'Limit Exceeded. Try again later.'
        const err_msg = e.message
        if(err_msg.includes('500'))
        {
          displayMessage = invalid_pwd
        }
        else if(err_msg.includes('409'))
        {
          displayMessage = limit_exceeded
        }
        else 
        {
          displayMessage = 'Failed to update password'
        }
        swal('Error!', displayMessage , 'error')
        console.log('error in password change', e.message)
      }
    }
    else if ((values.newpassword === values.confirmnewpassword) && values.newpassword === values.password) {
      swal('Error!', 'New password and old password are the same! ', 'error')
    }

    else {
      swal('Error!', 'New password and confirm password does not match ', 'error')
    }

  }

  routeToAccountSettings = () => {
    this.props.history.push('/accountsettings');
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (

      <div
        className="content-wrapper"
        style={{
          width: '100%',
          height: '100%',
          marginLeft: '0px',
          overflow: 'hidden',
        }}
      >
        <Breadcrumb pathsObject={bcChangePassword} />
        <section style={{ width: '80%', margin: '0 auto' }} className="content">
          <div className="container-fluid ">
            <Card className="card" bordered={false}>
              <form onSubmit={handleSubmit} className="form-horizontal">

                <div className='align-center'>
                  <div className='alignFormCenter'>
                    <div className="form-group has-feedback row col_hlf">
                      <div className="col-sm-12 form_outer align-right">
                        <label className=" col-form-label align-right p-2 mb-3 "> <span style={{ color: 'red', paddingRight: '5px' }}>*</span>Current Password  </label>
                      </div>
                    </div>
                    <div className="form-group has-feedback row col_hlf">
                      <div className="col-sm-12 form_outer">
                        <input
                          name="password"
                          type="password"
                          placeholder=""
                          className={
                            errors.password && touched.password
                              ? 'form-control is-invalid  col-sm-4 col-form-input align-left'
                              : 'form-control  col-sm-4 col-form-input align-left'
                          }
                          value={values.password}
                          onChange={handleChange}
                        />
                        {errors.password && touched.password ? (
                          <small className="text-danger">{errors.password}</small>
                        ) : null}
                      </div>
                    </div>


                    <div className="form-group has-feedback row col_hlf">
                      <div className="col-sm-12 form_outer align-right">
                        <label className=" col-form-label align-right p-2 mb-3 "> <span style={{ color: 'red', paddingRight: '5px' }}>*</span> New Password </label>
                      </div>
                    </div>
                    <div className="form-group has-feedback row col_hlf">
                      <div className="col-sm-12 form_outer">
                        <input
                          name="newpassword"
                          type="password"
                          placeholder=""
                          className={

                            errors.newpassword && touched.newpassword
                              ? 'form-control is-invalid  col-sm-4 col-form-input align-left'
                              : 'form-control  col-sm-4 col-form-input align-left'
                          }
                          value={values.newpassword}
                          onChange={handleChange}
                        />
                        {errors.newpassword && touched.newpassword ? (
                          <small className="text-danger">{errors.newpassword}</small>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group has-feedback row col_hlf">
                      <div className="col-sm-12 form_outer align-right">
                        <label className=" col-form-label align-right p-2 mb-3 "> <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
           Confirm New Password </label>
                      </div>
                    </div>

                    <div className="form-group has-feedback row col_hlf">
                      <div className="col-sm-12 form_outer " style={{ height: '25px !important' }}>
                        <input
                          name="confirmnewpassword"
                          type="password"
                          placeholder=" "
                          className={
                            errors.confirmnewpassword && touched.confirmnewpassword
                              ? 'form-control is-invalid  col-sm-4 col-form-input align-left'
                              : 'form-control  col-sm-4 col-form-input align-left'
                          }
                          value={values.confirmnewpassword}
                          onChange={handleChange}
                        />
                        {errors.confirmnewpassword && touched.confirmnewpassword ? (
                          <small className="text-danger">{errors.confirmnewpassword}</small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <button
                      className="col-2 btn btn-primary  mr-2"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Update
          </button>
                    <button
                      type="Button"
                      className="col-2 btn btn-primary "
                      onClick={this.routeToAccountSettings.bind(this)}

                    >
                      Cancel
          </button>
                  </div>
                </div>
              </form>

            </Card>
          </div>
        </section>
      </div>

    )
  }


  render() {
    return (
      <>
        <Formik
          initialValues={{
            password: '',
            newpassword: '',
            confirmnewpassword: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.submitForm(values, this.props.history)
            setSubmitting(false)
          }}
          validationSchema={registerSchema}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </>
    )
  }

}

export default PasswordChange