import './helpbar.css'

import { ApiAuth, HTTPMETHODS } from './api'
import React, { Component } from 'react'

function renderHelpItem(practices, capability) {
  return (
    <>
      {capability.name && (
        <p>
          <strong>Capability: </strong>
          {capability.code} - {capability.name}
        </p>
      )}
      {practices.description && (
        <p>
          <strong>DISCUSSION FROM SOURCE: </strong>
          {practices.description}
        </p>
      )}
      {practices.background && <p>{practices.background}</p>}

      {practices.clarification && (
        <>
          <p>
            <strong>CMMC CLARIFICATION: </strong>
            <br></br>
            {practices.clarification}
          </p>
        </>
      )}

      {practices.examples && (
        <>
          <p>{practices.examples}</p>
        </>
      )}

      {practices.references && (
        <>
          <p>
            <strong>REFERENCES</strong>
          </p>
          <ul>
            {practices.references.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

class Helpbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
      capability: {},
      practices: {},
    }
    this.handleExpand = this.handleExpand.bind(this)
    this.fetchCapability = this.fetchCapability.bind(this)
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isShowInfo !== this.props.isShowInfo)
      this.setState({ isExpand: this.props.isShowInfo })
    if (
      prevProps.practiceCode !== this.props.practiceCode ||
      prevProps.capabilityCode !== this.props.capabilityCode
    )
      this.fetchCapability(this.props.practiceCode, this.props.capabilityCode)
  }
  async fetchCapability(practiceCode, capabilityCode) {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    const practiceLevel = practiceCode.split('.')[1]
    try {
      const newCapability = await ApiAuth(
        HTTPMETHODS.GET,
        `/capability/${capabilityCode}/${practiceLevel}/${practiceCode}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      this.setState({
        practices: newCapability.data.practices[0],
        capability: newCapability.data,
      })
    } catch (e) {
      this.setState({
        practices: {},
        capability: {},
      })
      console.log('error in fetching capability', e.message)
    }
  }
  handleExpand() {
    const oldFlag = this.state.isExpand
    this.setState({ isExpand: !oldFlag })
    this.props.handleExpand()
  }
  render() {
    const { practices, capability } = this.state
    return (
      <div className="main-help-bar" style={{}}>
        <div className="help-bar">
          {this.state.isExpand ? (
            <nav className="">
              <ul
                className="nav nav-pills nav-help-bar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li className="nav-item help-bar-title">
                  <span className="nav-link" onClick={this.handleExpand}>
                    <p>{this.props.practiceCode}</p>
                    <i className="close fas fa-times" />
                  </span>
                </li>
                <div className="nav-item">
                  {renderHelpItem(practices, capability)}
                </div>
              </ul>
            </nav>
          ) : (
            <span
              className="nav-link"
              style={{
                color: 'black',
              }}
              onClick={this.handleExpand}
            >
              <i className="bar fas fa-info-circle" />
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default Helpbar
