import Login from './core/login'
import Register from './core/register'
import LandingPage from './core/landingPage'
import PolicyPage from './core/policyPage.js'
import TermPage from './core/termPage.js'
import Dashboard from '../views/Dashboard/Dashboard'
import ConfirmRegister from './core/registerConfirm'
import ConfirmRegisterInviteUser from './core/registerConfirmInviteUser'
import AssessmentSummary from './assessment/summary'
import PasswordReset from './core/forgotPassword'
import ForgotPasswordConfirm from './core/forgotPasswordConfirm'
import CreateAssessment from './assessment/createAssessment'
import EditAssessment from './assessment/editAssessment'
import RegisterOrgUser from './core/registerOrgUser'
import AccountSettings from "./core/accountSettings"
import Survey from './assessment/survey'
import { Reports } from './reports'
import Organization from '../views/Organization/Organization'
import PasswordChange from './core/passwordChange'
import DocumentRepository from '../views/DocumentRepository/documentRepository'
import TemplateRepository from '../views/TemplateRepository/templateRepository'
import InviteUserRegistration from '../views/Registration/inviteUserRegister'
import ManageUser from './manageuser/manageUser'

export const paths = [
  {
    key: 'd',
    title: 'Dashboard',
    icon: 'fa-tachometer-alt',
    path: 'dashboard',
  },
  {
    key: 'a',
    title: 'Assessment',
    icon: 'fas fa-check',
    path: 'assessment',
  },
  {
    key: 'r',
    title: 'Reports',
    icon: 'fa fa-file',
    path: 'reports',
  },
  {
    key: 'y',
    title: 'Document Repository',
    icon: 'fa fa-book fa-fw',
    path: 'documentRepository',
  },
  {
    key: 't',
    title: 'Template Repository',
    icon: 'fa fa-paste fa-fw',
    path: 'templateRepository',
  }
  // {
  //   key: 'o',
  //   title: 'Organization',
  //   icon: 'fa-building',
  //   path: 'organization',
  // },
  // {
  //   key: 'r',
  //   title: 'Generate Report',
  //   icon: 'fas fa-file-excel',
  //   path: 'report',
  // },
]

export const componentPaths = [
  { id: 0, component: LandingPage, path: '/', secured: false },
  { id: 12, component: PolicyPage, path: '/policy', secured: false },
  { id: 13, component: TermPage, path: '/terms', secured: false },
  { id: 1, component: Login, path: '/login', secured: false },
  { id: 2, component: Register, path: '/register', secured: false },
  {
    id: 3,
    component: ConfirmRegister,
    path: '/register/confirm',
    secured: false,
  },
  { id: 9, component: RegisterOrgUser, path: '/register/user', secured: false },
  { id: 6, component: PasswordReset, path: '/password/reset', secured: false },
  {
    id: 7,
    component: ForgotPasswordConfirm,
    path: '/password/confirm',
    secured: false,
  },
  { id: 4, component: Dashboard, path: '/dashboard', secured: true },
  { id: 5, component: AssessmentSummary, path: '/assessment', secured: true },
  { id: 15, component: Reports, path: '/reports', secured: true },
  { id: 16, component: Organization, path: '/organization', secured: true },
  {
    id: 8,
    component: CreateAssessment,
    path: '/assessment/create',
    secured: true,
  },
  {
    id: 10,
    component: EditAssessment,
    path: '/assessment/edit/:assessId/:level',
    secured: true,
  },
  {
    id: 11,
    component: Survey,
    path: '/assessment/:id',
    secured: true,
  },
  {
    id: 14,
    component: Survey,
    path: '/assessment/:id/:level',
    secured: true,
  },
  {
    id: 17,
    component: AccountSettings,
    path: '/accountsettings',
    secured: true,
  },
  {
    id: 19,
    component: PasswordChange,
    path: '/passwordchange',
    secured: true,
  },
  // DocumentRepository,
  {
    id: 20,
    component: DocumentRepository,
    path: '/documentRepository',
    secured: true,
  },
  // ManageUsers,
  {
    id: 21,
    component: ManageUser,
    path: '/manageUsers',
    secured: true,
  },
  {
    id: 22,
    component: TemplateRepository,
    path: '/templateRepository',
    secured: true,
  },
  {
    id: 23,
    component: InviteUserRegistration,
    path: '/inviteUserRegistration',
    secured: false,
  },
  {
    id: 24,
    component: ConfirmRegisterInviteUser,
    path: '/register/confirminviteuser',
    secured: false,
  },
  // {
  //   id: 12,
  //   component: Survey,
  //   path: '/assessment/:id/:level/:domainCode',
  //   secured: true,
  // },
  // {
  //   id: 13,
  //   component: Survey,
  //   path: '/assessment/:id/:level/:domainCode/:capacity',
  //   secured: true,
  // },
]