// import { surveyData } from '../../mockData'
import './survey.css'

import { ApiAuth, HTTPMETHODS } from '../core/api'
import { Container, Spinner } from 'react-bootstrap'
import React, { Component } from 'react'

import SurveyQuestion from './surveyQuestion'
import Surveybar from './Surveybar'

const treatPromiseGroup = async function (fns) {
  // console.log('=========   treatPromiseGroup process  ===========')
  try {
    await fns.reduce(async (previousPromise, nextAsyncFunction) => {
      await previousPromise
      await nextAsyncFunction()
    }, Promise.resolve())

    return { status: 'ok', data: 'finished treatPromiseGroup process' }
  } catch (e) {
    return { status: 'error', data: e.message }
  }
}

class Survey extends Component {
  constructor(props) {
    super(props)

    const {
      domainCode,
      assessmentId,
      assessmentLevel,
      capacityCode,
      domainName,
      total,
      practiceNo,
    } = this.getPathValues()

    this.state = {
      isLoaded: false,
      assessmentId: assessmentId,
      assessmentLevel: assessmentLevel,
      domainCode: domainCode,
      capacityCode: capacityCode,
      domainName: domainName,
      practiceNo: practiceNo,
      total: total,
      surveyData: [],
      questionData: {},
      needUpdate: true,
      isFreeOrg: true,
      lastPracticeCode: '',
      showInfo: false,
    }

    this.getPathValues = this.getPathValues.bind(this)
    this.fetchQuestionData = this.fetchQuestionData.bind(this)
    this.fetchTotalAssessmentData = this.fetchTotalAssessmentData.bind(this)
    this.handleNextCapacityQuestionData = this.handleNextCapacityQuestionData.bind(
      this
    )
  }

  componentDidMount = async () => {
    if (!this.state.isLoaded) {
      await this.fetchTotalAssessmentData()
      if (
        !this.state.domainName &&
        this.state.surveyData[0] &&
        this.state.surveyData[0].domainName
      )
        this.setState({ domainName: this.state.surveyData[0].domainName })
      if (
        !this.state.total &&
        this.state.surveyData[0] &&
        this.state.surveyData[0].totalCapacities
      )
        this.setState({ total: this.state.surveyData[0].totalCapacities })
      if (!this.practiceNo) this.setState({ practiceNo: 1 })
    }
  }

  componentDidUpdate() {
    const {
      domainCode,
      capacityCode,
      domainName,
      total,
      practiceNo,
    } = this.getPathValues()
    const {
      domainCode: oldDomainCode,
      capacityCode: oldCapacityCode,
      domainName: oldDomainName,
      total: oldTotal,
      practiceNo: oldPracticeNo,
    } = this.state
    const flag =
      (domainCode && domainCode !== oldDomainCode) ||
      (capacityCode && capacityCode !== oldCapacityCode) ||
      (domainName && domainName !== oldDomainName) ||
      (total && total !== oldTotal) ||
      (practiceNo && practiceNo !== oldPracticeNo)

    if (flag) {
      this.setState({
        needUpdate: true,
        domainCode: domainCode,
        capacityCode: capacityCode,
        domainName: domainName,
        total: total,
        practiceNo: practiceNo,
      })
    }

    if (!this.state.isLoaded) {
      this.fetchTotalAssessmentData()
    }

    if (this.state.needUpdate) {
      this.fetchQuestionData()
    }
  }

  getPathValues() {
    const location = this.props.location
    const values = location.pathname.split('/')

    let domainCode, capacityCode, domainName, total, practiceNo
    if (location && location.state) {
      domainCode = location.state.domainCode
      capacityCode = location.state.capacityCode
      domainName = location.state.domainName
      total = location.state.total
      practiceNo = location.state.practiceNo
    }
    return {
      assessmentId: values[2],
      assessmentLevel: values[3],
      domainCode: domainCode,
      capacityCode: capacityCode,
      domainName: domainName,
      total: total,
      practiceNo: practiceNo,
    }
  }
  handleExpand = (value) => {
    this.setState({ showInfo: value })
  }
  handleNextCapacityQuestionData = () => {
    const { domainCode, capacityCode, surveyData } = this.state
    // checking if next capacity code is exist
    const curDomainData = surveyData.find((e) => e.domain === domainCode)
    // console.log(
    //   'handleNextCapacityQuestionData',
    //   domainCode,
    //   curDomainData.data
    // )
    const curCapcityCodes = curDomainData.data.map((e) => e.capacityCode)
    const curDomainName = curDomainData.domainName
    const curTotal = curDomainData.totalCapacities
    const curCapacityCodeIndex = curCapcityCodes.indexOf(capacityCode)
    const hasNextCapacityCode =
      curCapacityCodeIndex < curCapcityCodes.length - 1
    // console.log('hasNextCapacityCode', hasNextCapacityCode)
    if (hasNextCapacityCode) {
      const newCapacityCode = curCapcityCodes[curCapacityCodeIndex + 1]
      this.props.history.push({
        state: {
          domainCode: domainCode,
          capacityCode: newCapacityCode,
          practiceNo: curCapacityCodeIndex + 2,
          domainName: curDomainName,
          total: curTotal,
        },
      })
      this.setState({
        needUpdate: true,
        capacityCode: newCapacityCode,
        practiceNo: curCapacityCodeIndex + 2,
        domainName: curDomainName,
        total: curTotal,
      })

      return
    }

    // go to next domain data
    const curAssessmentDomains = surveyData.map((e) => e.domain)
    const curAssessmentDomainName = surveyData.map((e) => e.domainName)
    const curAssessmentTotal = surveyData.map((e) => e.totalCapacities)
    const curDomainIndex = curAssessmentDomains.indexOf(domainCode)
    const hasNextDomain = curDomainIndex < curAssessmentDomains.length - 1
    // console.log('hasNextDomain', hasNextDomain)
    if (hasNextDomain) {
      const newDomainCode = curAssessmentDomains[curDomainIndex + 1]
      const temp = surveyData[curDomainIndex + 1].data
      const newCapacityCode = temp[0].capacityCode
      const newDomainName = curAssessmentDomainName[curDomainIndex + 1]
      const newTotal = curAssessmentTotal[curDomainIndex + 1]
      this.props.history.push({
        state: {
          domainCode: newDomainCode,
          capacityCode: newCapacityCode,
          domainName: newDomainName,
          total: newTotal,
          practiceNo: 1,
        },
      })
      this.setState({
        needUpdate: true,
        domainCode: newDomainCode,
        capacityCode: newCapacityCode,
        domainName: newDomainName,
        total: newTotal,
        practiceNo: 1,
      })

      return
    }

    // user has already finished
    this.props.history('/assessment')
  }

  async fetchTotalAssessmentData() {
    const {
      assessmentId,
      assessmentLevel,
      capacityCode,
      domainCode,
    } = this.state

    // replace this part with api call
    if (!assessmentId || !assessmentLevel) {
      console.log('params is not defined')
      return
      // return { status: 'error', data:" params is not defined"}
    }

    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const formattedOrgId = encodeURIComponent(userObj.organizationId)

      const resDomains = await ApiAuth(
        HTTPMETHODS.GET,
        `/domain/list?orgId=${formattedOrgId}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )

      if (resDomains.status !== 200) {
        console.log('error in fetching domain data')
        return
      }

      const domains = resDomains.data
      // console.log('domains', domains)

      if (!domains) {
        console.log('domains is empty')
        return
      }

      const resOrg = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/${formattedOrgId}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const isFreeOrg = resOrg.data.isFreeOrg
      let surveyData = []

      const fetchSurveyDataCallbacks = domains.map((domainItem) => async () => {
        try {
          const resData = await ApiAuth(
            HTTPMETHODS.GET,
            `/questions?level=${assessmentLevel}&domainCode=${domainItem.code}&assessId=${assessmentId}`,
            null,
            false,
            userObj.email,
            userObj.organizationId
          )
          // console.log('resData', resData)

          if (resData.status !== 200) {
            console.log(
              'There is an error in fetching assessments data',
              resData
            )
            return null
            // return { status: 'ok', data: resData.data}
          }

          const {
            domainName,
            domainCode,
            totalPractices,
            practices,
          } = resData.data

          let totalSolved = 0

          const data = practices.map((item) => {
            const {
              practiceCode,
              questionCount,
              answerCount,
              done,
              questions,
            } = item

            if (done) totalSolved++

            const capacityCode = practiceCode.split('.')[2]

            return {
              capacityCode: capacityCode,
              questionCount: questionCount,
              answerCount: answerCount,
              done: done,
              data: questions,
              practiceCode: practiceCode,
            }
          })

          surveyData.push({
            domainName: domainName,
            domain: domainCode,
            assessmentLevel: assessmentLevel,
            totalCapacities: totalPractices,
            totalSolved: totalSolved,
            data: data,
          })
        } catch (e) {
          console.log(
            `there is an error in fetching assessments data with ${domainItem.code}`,
            e.message
          )
          return null
        }
      })

      await treatPromiseGroup(fetchSurveyDataCallbacks)
      // console.log('finalsurveyData', surveyData)
      //sort survey data according capacity code and domain data
      surveyData.sort(function (a, b) {
        if (a.domain > b.domain) {
          return 1
        }
        if (a.domain < b.domain) {
          return -1
        }
        return 0
      })
      surveyData = surveyData.map((item) => {
        item.data.sort(
          (a, b) => Number(a.capacityCode) - Number(b.capacityCode)
        )
        return item
      })
      let lastQuestion = surveyData[surveyData.length - 1].data
      let lastPracticeCode = lastQuestion[lastQuestion.length - 1].practiceCode
      this.setState({ lastPracticeCode })
      // console.log('surveyDataex', surveyData)
      // console.log('lastQUestion', lastPracticeCode)
      const validDomains = surveyData.map((e) => e.domain)
      const curDomainCode = domainCode || validDomains[0]
      const domainData = surveyData.find((e) => e.domain === curDomainCode)

      // console.log('curDomainCode', curDomainCode, validDomains)
      let curCapacityCode =
        capacityCode || (domainData && domainData.data[0].capacityCode)

      // console.log('firstcapacityCode', domainData, capacityCode)
      this.setState(
        {
          isLoaded: true,
          assessmentId: assessmentId,
          assessmentLevel: assessmentLevel,
          surveyData: surveyData,
          domainCode: curDomainCode,
          capacityCode: curCapacityCode,
          isFreeOrg: isFreeOrg,
        },
        () => {
          this.fetchQuestionData()
        }
      )
    } catch (e) {
      console.log(
        'there is an error in fetching total assessments data',
        e.message
      )
      // return { status: 'error', data: e.message}
    }
  }

  fetchQuestionData = () => {
    let { domainCode, capacityCode, questionData, surveyData } = this.state

    if (!surveyData) {
      console.log('surveyData is not defined')
      return
    }

    let domainIndex = -1
    surveyData.forEach((domainData, index) => {
      if (domainData.domain === domainCode) {
        domainIndex = index
      }
    })

    if (domainIndex !== -1) {
      let capacityIndex = -1
      surveyData[domainIndex].data.forEach((e, i) => {
        if (e.capacityCode === capacityCode) {
          capacityIndex = i
        }
      })
      // console.log('capacityIndex', capacityIndex, capacityCode)

      if (capacityIndex !== -1) {
        questionData = surveyData[domainIndex].data[capacityIndex]
      }
    }
    // console.log('questionData', questionData)

    this.setState({
      needUpdate: false,
      // domainCode,
      // capacityCode,
      questionData,
    })
  }

  render() {
    const {
      isLoaded,
      domainCode,
      assessmentId,
      assessmentLevel,
      capacityCode,
      surveyData,
      questionData,
      isFreeOrg,
      domainName,
      total,
      practiceNo,
      lastPracticeCode,
      showInfo,
    } = this.state
    return (
      <Container
        className="survey-wrapper"
        // style={{ height: `${containerHeight}px` }}
      >
        {isLoaded && questionData && domainName && total && practiceNo ? (
          <>
            <Surveybar
              assessmentId={assessmentId}
              curCode={domainCode}
              curLevel={assessmentLevel}
              curCapacityCode={capacityCode}
              surveyData={surveyData}
            />
            <div className="question-wrapper">
              <SurveyQuestion
                key={questionData.practiceCode}
                history={this.props.history}
                assessmentId={assessmentId}
                isFreeOrg={isFreeOrg}
                questionData={questionData}
                surveyData={surveyData}
                practiceNo={practiceNo}
                domainName={domainName}
                total={total}
                lastPracticeCode={lastPracticeCode}
                isShowInfo={showInfo}
                handleExpand={this.props.handleExpand}
                handlePracticeCode={this.props.handlePracticeCode}
                fetchQuestionData={this.fetchQuestionData}
                fetchTotalAssessmentData={this.fetchTotalAssessmentData}
                handleNextCapacityQuestionData={
                  this.handleNextCapacityQuestionData
                }
              />
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <Spinner
              style={{
                margin: 'auto',
                display: 'block',
                width: '50px',
                height: '50px',
              }}
              as="div"
              animation="border"
              size="lg"
              variant="primary"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}
      </Container>
    )
  }
}

export default Survey
