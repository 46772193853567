import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const maturityLevel = {
  1 :  'Performed',
  2 :  'Documented',
  3 :   'Managed',
  4 : 'Reviewed',
  5 : 'Optimizing'
} 

const practices = {
  1 :  'Basic Cyber Hygiene',
  2 :  'Intermediate Cyber Hygiene',
  3 :   'Good Cyber Hygiene',
  4 : 'Proactive',
  5 : 'Advanced/Proactive'
}

const BreadcrumbAssessment = ({ pathsObject, statesAsProps, domainName ,actualProps }) => {
  return (
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-lg-3">
            <div className="col-lg-12">
              <h1 className="m-0 text-dark title_assess">{pathsObject.topic}</h1>
            </div>
            <div className="col-lg-12">
              <ol className="breadcrumb float-sm-left">
                {pathsObject.paths.map((pathItem, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        'breadcrumb-item ' +
                        (pathItem.path != null ? 'active' : '')
                      }
                    >
                      <a href={pathItem.path}>{pathItem.title}</a>
                    </li>
                  )
                })}
              </ol>
            </div>
          </div>

          <div className="col-lg-9" >
            <div className="col-lg-12" style={{display: 'inline-block', width: '91.4%', verticalAlign: 'top'}}>
              <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '0px',
                      background: '#f4f5f7',
                      textAlign: 'center',
                      borderWidth: '1px',
                      color: '#4c4c4c',
                      marginTop:'-3%',
                      
                    }}
                  // height: 'calc(100vh - 250px)',
                  >
                    
                    <Container>
                      <Row>
                       
                        <Col style={{
                          //borderRight: '1px solid black',
                          //  margin: '3% 0% 3% 0%'
                        }}>

                          <div style={{ fontWeight: 'bold', padding: '5px', margin: '3% 0% 3% 0%' ,whiteSpace: 'nowrap'}}>
                            Capability Code
                  </div>
                          <div style={{ fontWeight: 'none', padding: '3px', fontSize: '15px' }}>
                         {statesAsProps.initialValues[0]?statesAsProps.initialValues[0].capabilityCode:''}

                  </div>
                        </Col>
                        <Col style={{
                          //borderRight: '1px solid black',
                          //  margin: '3% 0% 3% 0%'
                          }}>

                          <div style={{ fontWeight: 'bold', padding: '5px', margin: '3% 0% 3% 0%' ,whiteSpace: 'nowrap' }}>
                            Maturity Level
                  </div>
                          <div style={{ fontWeight: 'none', padding: '3px', fontSize: '15px' }}>
                          {maturityLevel[1]}
                  </div>
                        </Col>

                        <Col style={{
                          //  borderRight: '1px solid black',
                          //  margin: '3% 0% 3% 0%'
                          }}>

                          <div style={{ fontWeight: 'bold', padding: '5px', margin: '3% 0% 3% 0%' }}>
                            Practices
                  </div>
                          <div style={{ fontWeight: 'none', padding: '3px', fontSize: '15px' }}>
                          {practices[1]}
                  </div>
                        </Col>
                      </Row>
                    </Container> 
                  </div>
              </>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default BreadcrumbAssessment
