import './register.css'
import * as Yup from 'yup'
import React, { Component } from 'react'
import { Formik } from 'formik'
import swal from 'sweetalert'
import { ApiAuth, HTTPMETHODS, ApiAuthInitialState } from './api'

const nameRegex = /^[0-9a-zA-Z '.-]*$/
const password_lowercase_Regex = /^(?=.*[a-z]).*$/
const password_uppercase_Regex = /^(?=.*[A-Z]).*$/
const password_special_Regex = /^(?=.*[@#$%^&+=]).*$/
// const email_common = /^(?=.*[A-Z a-z +])(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho|@mail|@yopmail|@hubspot|@pepipost|@protonmail|@yandex|@tutanota|@facebook))/
let email_common = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho|@mail|@yopmail|@hubspot|@pepipost|@protonmail|@yandex|@tutanota|@facebook))/
const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      nameRegex,
      'First Name should not contain any Non-Alpha characters'
    )
    .min(2, 'First Name is Too Short!')
    .max(25, 'First Name is Too Long!')
    .required('First Name is Required'),
  lastName: Yup.string()
    .matches(nameRegex, 'Last Name should not contain any Non-Alpha characters')
    .min(2, 'Last Name is Too Short!')
    .max(25, 'Last Name is Too Long!')
    .required('Last Name is Required'),
  email:
    process.env.REACT_APP_API_AUTH_URL === 'https://api.securitycompliance.io'
      ? Yup.string()
        .email()
        .matches(
          email_common,
          'Email Address cannot be in one of the common Email Domains'
        )
        .matches(/^[^+]*$/, 'Email should not contain +')
        .min(7, 'Email is Too Short!')
        .max(100, 'Email is Too Long!')
        .required('Email is Required')
      : Yup.string()
        .email()
        .matches(
          email_common,
          'Email Address cannot be in one of the common Email Domains'
        )
        .min(7, 'Email is Too Short!')
        .max(100, 'Email is Too Long!')
        .required('Email is Required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password has to be at least 8 characters in length!')
    .matches(
      password_lowercase_Regex,
      'Password should contain at least 1 LowerCase Character!'
    )
    .matches(
      password_uppercase_Regex,
      'Password should contain at least 1 UpperCase Character!'
    )
    .matches(
      password_special_Regex,
      'Password should contain at least 1 Special (@#$%^&+=) Character!'
    ),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),
})

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    }
    console.log(process.env.REACT_APP_API_AUTH_URL);
  }

  submitForm = async (values, history) => {
    try {
      let userName = values.email
      let userNameInLowerCase = userName.toLocaleLowerCase()

      const payload = {
        username: userNameInLowerCase,
        password: values.password,
        UserAttributes: [{
          'Name': 'firstName',
          'Value': values.firstName,
        },
        {
          'Name': 'lastName',
          'Value': values.lastName
        }],
        "ValidationData": null
      }


      const userReturnObj = await ApiAuthInitialState(
        HTTPMETHODS.POST,
        `/register`,
        payload,
        false,
        userNameInLowerCase
      )
      if (userReturnObj) {
        let registrationData = payload
        delete registrationData['password']
        //Sign up with Cognito is successful.
        localStorage.setItem('registerEmail', JSON.stringify(userNameInLowerCase));
        localStorage.setItem('registrationData', JSON.stringify(registrationData));
        history.push('/register/confirm')
      }
    } catch (error) {
      swal('Error!', 'Error in registration', 'error')
      console.log('error in registration', error)
    }
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <div className="form-group has-feedback row col_hlf">
          <div className="col-sm-12 form_outer"><i className="fas fa-user"></i>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={values.firstName}
              onChange={handleChange}
              className={
                errors.firstName && touched.firstName
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            {errors.firstName && touched.firstName ? (
              <small className="text-danger">{errors.firstName}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row col_hlf">
          <div className="col-sm-12 form_outer"><i className="fas fa-user"></i>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className={
                errors.lastName && touched.lastName
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.lastName}
              onChange={handleChange}
            />
            {errors.lastName && touched.lastName ? (
              <small className="text-danger">{errors.lastName}</small>
            ) : null}
          </div>
        </div>

        <div className="form-group has-feedback row col_hlf">
          <div className="col-sm-12 form_outer"><i className="fas fa-envelope"></i>
            <input
              type="email"
              name="email"
              placeholder="Name@example.com"
              className={
                errors.email && touched.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row col_hlf">
          <div className="col-sm-12 form_outer"><i className="fas fa-lock"></i>
            <input
              name="password"
              type="password"
              placeholder="Password"
              className={
                errors.password && touched.password
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.password}
              onChange={handleChange}
            />
            {errors.password && touched.password ? (
              <small className="text-danger">{errors.password}</small>
            ) : null}
          </div>
        </div>

        <div className="form-group has-feedback row col_full mb_0">
          <div className="col-sm-12 term-label checkbox_terms">
            <input
              type="checkbox"
              name="acceptTerms"
              className={
                errors.acceptTerms && touched.acceptTerms ? 'is-invalid' : ''
              }
              value={values.acceptTerms}
              onChange={handleChange}
            />
            &nbsp;
            <b>
              By registering, You agree to the{' '}
              <a href="/terms" target="_blank" className="terms_sec">
                Security Compliance Terms and Conditions
              </a>
            </b>
            <br />
            {errors.acceptTerms && touched.acceptTerms ? (
              <small className="text-danger">{errors.acceptTerms}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row col_full">
          <div className="col-sm-12" />
          <div className="col-sm-12 checkbox_terms_para">
            <p>
              By completing this form, I agree that i'd like to receive
              information from Security Compliance and its affiliates related to
              their services, events, and any special offers by email, post, or
              other forms of communication.
            </p>
            <p>
              You may unsubscribe at any time by following the instructions in
              the communications received. Your information will be handled in
              accordance with the Security Compliance Privacy Notice.
            </p>
          </div>
        </div>

        <div className="row col_full_btn">
          <button
            disabled={isSubmitting}
            type="submit"
            className="col-6 btn btn-primary btn-rounded btn-block sign_btn"
          >
            Register
          </button>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className="login-page">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h3 className="register-box-msg">Register Now</h3>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  password: '',
                  acceptTerms: false,
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history)
                  setSubmitting(false)
                }}
                validationSchema={registerSchema}
              >
                {(props) => this.showForm(props)}
              </Formik>

            </div>
            {/* /.register-card-body */}
          </div>
        </div>
      </div>
    )
  }
}

export default Register
