import React, { Component } from 'react'
import AssessmentLevel from '../../components/Dashboard/AssessmentLevel'
import ComplianceScore from '../../components/Dashboard/ComplianceScore'
import QuestionDomain from '../../components/Dashboard/QuestionDomain'
import PieChart from '../../components/Dashboard/piechart'
import SummaryTable from '../../components/Dashboard/SummaryTable'
import classes from './Dashboard.module.css'
import ResponseTypeDistribution from '../../components/Dashboard/ResponseTypeDistribution'
import ResponseTypeDistributionBarchart from '../../components/Dashboard/ResponseTypeDistributionBarchart'
import ResponseDistributionPerDomain from '../../components/Dashboard/responseDistributionPerDomain'
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    // const reloadCount = window.localStorage.getItem('reloadCount')
    // if (reloadCount == 0 || reloadCount == null) {
    //   window.localStorage.setItem('reloadCount', 1)
    //   window.location.reload()
    // }
  }

  render() {
    const clientWidth = document.body.clientWidth

    var step = 10
    if (clientWidth > 1500) {
      step = 10
    } else if (clientWidth > 1366) {
      step = 8
    } else {
      step = 5
    }

    return (
      <div class="chart_sec">
        <div className={classes.chartContainer}>
          <div className='chart_outer col-lg-4'>
            <div className={classes.colStyle}>
              <div className={classes.rowStyle}>
                <SummaryTable height={15 * step} width={70 * step} />
              </div>
            </div>
          </div>
          <div className='chart_outer col-lg-4'>
            <div className={classes.rowStyle}>
              <AssessmentLevel width={40 * step} height={30 * step} />
            </div>
          </div>
          <div className='chart_outer col-lg-4'>
            <div className={classes.rowStyle}>
              <ComplianceScore width={40 * step} height='150px' />
            </div>
          </div>

        </div>
        <div className='chart_outer_secondary col-lg-4'>
          <div className={classes.colStyle}>
            <QuestionDomain width={40 * step} height={30 * step} />
          </div>
        </div>


        <div className='chart_outer_secondary col-lg-4'>
          <div className={classes.colStyle}>
            {/* <ResponseTypeDistribution width={40 * step} height={30 * step} /> */}
            <ResponseTypeDistributionBarchart width={40 * step} height={30 * step} />
          </div>
        </div>
        <div className='chart_outer_secondary col-lg-4'>
          <div className={classes.rowStyle}>
            <ResponseDistributionPerDomain width={40 * step} height={30 * step} />
          </div>
        </div>
        <br />
        <br />
      </div>
    )
  }
}

export default Dashboard