import React, { Component } from 'react'
import { ApiAuth, HTTPMETHODS } from '../../components/core/api'
import OrganizationComponent from '../../components/Organization/organization';




class Organization extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: null
    }
  }

  componentDidMount() {	
    this.getOrgInfo();	
  }	
  getOrgInfo = async () => {	
    try {	
      const userObj = JSON.parse(localStorage.getItem('userInfo'))	
      const resOrg = await ApiAuth(	
        HTTPMETHODS.GET,	
        `/organization/${encodeURIComponent(userObj.organizationId)}`,	
        null,	
        false,	
        userObj.email,	
        userObj.organizationId	
      )	
      await this.setState({ data: resOrg.data  });	
        return resOrg.data;
    } catch (error) {	
      console.log(error.message)	
    }	
  }	



    render() {
     
        return (
            <div
            className="content-wrapper"
            style={{
              width: '100%',
              height: '100%',
              marginLeft: '0px',
              overflow: 'hidden',
            }}
          >
          {/*   <Breadcrumb pathsObject={bcReports} /> */}
            <section className="content">
              <div className="container-fluid">
                {this.state.data ? <OrganizationComponent data={this.state.data}/> : null}
              
              </div>
            </section>
          </div>
        )
      }
}

export default Organization;