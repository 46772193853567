import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'
import ManageUserTable from './manageUserTable'
import Breadcrumb from '../core/breadcrumb'
import { Card } from 'antd'
import { bcManageUsers } from '../core/breadcrumbpaths'
import { Dropdown, DropdownButton, Modal, Row, Col, Form } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'


const email_common = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho))/
const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;


const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .matches(
      email_common,
      'Email Address cannot be in one of the common Email Domains'
    )
    .required('Email is Required'),
})

class ManageUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usersList: null,
      selectedRowKey: null,
      orgName: null,
      isFreeOrg: null,
      cmmcVersion: null,
      cmmcLevel: null,
      score: 0,
      baseURL1: null,
      baseURL2: null,
      baseURL3: null,
      gapResponseData: [],
      isAnswered: null,
      selectedUser: null,
      isInviteUserModalOpen: false
    }

  }
  componentDidMount = async () => {

    this.userObj = JSON.parse(localStorage.getItem('userInfo'))
    this.getOrgInfo(this.userObj)
  }

  toggleInviteUserModal = () => {
    this.setState({
      isInviteUserModalOpen: !this.state.isInviteUserModalOpen
    })
  }

  getOrgInfo = async (userObj) => {
    try {
      const resOrg = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const isFreeOrg = resOrg.data.isFreeOrg
      const orgName = resOrg.data.name
      this.setState({ orgName })
      this.setState({ isFreeOrg })
      if (isFreeOrg) {
        this.props.history.push('/dashboard')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  submitForm = async (value) => {
    try {
      const inviteFrom = this.userObj.email
      const payload = {
      "email": value.email, 
      "inviteFrom": inviteFrom, 
      "orgId": this.userObj.organizationId,
      "companyName": this.state.orgName }
      const formResponse = await ApiAuth(
        HTTPMETHODS.POST,
        `/inviteuser`,
        payload,
        false,
        this.userObj.email,
        this.userObj.organizationId
      )
        if(formResponse.status==200)
        {
          this.toggleInviteUserModal()
          swal("Success!", "The invitation has been sent by an email.", "success")
        }
    } catch (error) {
      console.log(error.message)
    }
  }

  handleBaseURL = (index, val) => {
    if (index === 1 && val) this.setState({ baseURL1: val })
    else if (index === 2 && val) this.setState({ baseURL2: val })
    else if (index === 3 && val) this.setState({ baseURL3: val })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <div className="justify-content-md-center row pb-4">
          <i class="fa fa-envelope fa-3x" disabled aria-hidden="true"></i>
        </div>
        <div className="form-group has-feedback row">
          <div className="col-sm-10">
            <input
              type="email"
              name="email"
              placeholder="Enter email address"
              className={
                errors.email && touched.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <small className="text-danger">{
                errors.email.charAt(0).toUpperCase()+
                errors.email.slice(1)
                }</small>
            ) : null}
          </div>
          <div className="justify-content-md-center">
          <button
            disabled={isSubmitting}
            type="submit"
            className="btn btn-primary pt-2 mt-1 "
          >
            Invite
          </button>
        </div>
        </div>
      </form>
    )
  }

  render() {

    return (
      <div
        className="content-wrapper"
        style={{
          width: '100%',
          height: '100%',
          marginLeft: '0px',
          overflow: 'hidden',
        }}
      >
        <Breadcrumb pathsObject={bcManageUsers} />
        <section className="content">
          <div className="container-fluid">
        
            <Card className="card" bordered={false}>
              <div
                className="btn_sec mb-2"
                style={{
                  textAlign: 'right',
                }}
              >

          <button
            type="button"
            className=" col-2 pull-right btn btn-primary mr-0 mb-2 "
            onClick={this.toggleInviteUserModal.bind(this)}
          >
            Invite User
          </button>
              </div>
              <div className="mb-2">
                <Modal centered show={this.state.isInviteUserModalOpen} onHide={this.toggleInviteUserModal.bind(this)}>
                  <Modal.Header closeButton>
                    <Modal.Title style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: '20px'
                    }}>
                      Invite a member to SecurityCompliance
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Formik
                      initialValues={{
                        email: '',
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        this.submitForm(values, this.props.history)
                        setSubmitting(false)
                      }}
                      validationSchema={registerSchema}
                    >
                      {(props) => this.showForm(props)}
                    </Formik>
                  </Modal.Body>
                </Modal>

                <div style={{ width: '100%' }}>
                  <ManageUserTable
                    usersList={this.state.usersList}
                  />
                </div>
              </div>
            </Card>
          </div>
        </section>
      </div>
    )
  }
}

export default ManageUser
