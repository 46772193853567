import './survey.css'

import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'

import AccordianGroup from './AccordianGroup'
import BreadcrumbAssessment from '../core/breadcrumbassessment'
import { Container } from 'react-bootstrap'
import { bcPracticeSummary } from '../core/breadcrumbpaths'

const treatPromiseGroup = async function (fns) {
  // console.log('=========   treatPromiseGroup process  ===========')
  try {
    await fns.reduce(async (previousPromise, nextAsyncFunction) => {
      await previousPromise
      await nextAsyncFunction()
    }, Promise.resolve())

    return { status: 'ok', data: 'finished treatPromiseGroup process' }
  } catch (e) {
    return { status: 'error', data: e.message }
  }
}

class SurveyQuestion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
      assessmentId: this.props.assessmentId,
      initialValues: [],
      questionLen: 0,
      practiceCode: null,
      practiceDescription: null,
      freeQuestion: null,
      capabilityCode: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.handleFinish = this.handleFinish.bind(this)
    this.fetchResponse = this.fetchResponse.bind(this)
  }

  componentDidMount() {
    this.fetchResponse(this.props.questionData)
  }

  componentDidUpdate() {
    const newQuestionData = this.props.questionData
    const oldQuestionData = this.state.initialValues
    if (newQuestionData.questionNo !== oldQuestionData.questionNo) {
      this.fetchResponse(newQuestionData)
    }
  }

  handleChange = (e, index) => {
    e.preventDefault()
  }

  handleSubmit(e) {
    e.preventDefault()

    // alert('Successfully submit assessment data')
    // window.setTimeout(() => {
    //   this.props.history.push('/assessment')
    // }, 500)
  }

  async fetchResponse(newQuestionData) {
    if (
      newQuestionData &&
      newQuestionData.data &&
      newQuestionData.data[0].capabilityCode
    ) {
      this.setState({
        capabilityCode: newQuestionData.data[0].capabilityCode,
      })
    }

    if (!newQuestionData.data || Object.keys(newQuestionData).length === 0) {
      console.log('newQuestionData is not defined', newQuestionData)
      return
    }
    this.setState({ questionLen: newQuestionData.data.length })
    const newQuestions = newQuestionData.data
    const resObjArray = []
    const fetchEachQuestion = newQuestions.map((eachQuestion) => async () => {
      const {
        practiceCode,
        questionNo,
        practiceDescription,
        isFree,
        question,
      } = eachQuestion
      const [domainCode, assessmentLevel] = practiceCode.split('.')

      if (isFree) this.setState({ freeQuestion: question })

      if (practiceDescription) this.setState({ practiceDescription })
      this.setState({ practiceCode })

      bcPracticeSummary.topic = this.props.domainName
      const userObj = JSON.parse(localStorage.getItem('userInfo'))

      try {
        const resData = await ApiAuth(
          HTTPMETHODS.GET,
          `/response?level=${assessmentLevel}&code=${domainCode}&assessId=${this.props.assessmentId}`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        )
        // console.log('eachQuestion', resData.data)
        if (resData.status !== 200) {
          console.log('There is an error in fetching response data', resData)
          return null
        }
        // console.log('quetioncode', ` ${practiceCode}.${questionNo}`, resData)

        const responseObj = resData.data.find(
          (item) => item.questionCode === `${practiceCode}.${questionNo}`
        )
        // console.log('responseObj', responseObj)
        if (responseObj) {
          resObjArray.push({
            ...eachQuestion,
            response: responseObj.response,
            explanation: responseObj.explanation ? responseObj.explanation : '',
          })
        } else {
          resObjArray.push({
            ...eachQuestion,
            response: null,
            explanation: '',
          })
        }
        return
      } catch (e) {
        if (e.message !== 'Request failed with status code 404') return null
      }
      resObjArray.push({
        ...eachQuestion,
        response: null,
        explanation: '',
      })
    })
    await treatPromiseGroup(fetchEachQuestion)

    await this.setState({
      initialValues: resObjArray,
    })

    this.props.handlePracticeCode(
      this.state.practiceCode,
      this.state.capabilityCode
    )
  }

  render() {
    return (
      <div
        className="content-wrapper"
        style={{
          width: '100%',
          height: 'auto',
          margin: '0px',
          minHeight: 'auto',
          background: 'transparent',
        }}
      >
        <Container>
          <div className="main-content"> 
            <BreadcrumbAssessment pathsObject={bcPracticeSummary} domainName={this.props.domainName} actualProps={this.props} statesAsProps={this.state}/>
            <div className="container-fluid">     <div className="row">  <div className="col-sm-12"> <h3 className="ques_heading"> <b>{this.state.practiceCode}</b> :{' '}
            {this.props.isFreeOrg
              ? this.state.freeQuestion
              : this.state.practiceDescription}
            <a
              data-widget="control-sidebar"
              data-slide="true"
              href="/#"
              className="ml-2 acc_info"
              onClick={(e) => {
                e.preventDefault()
                this.props.handleExpand()
              }}
            >
              <i className="fa fa-info-circle"></i> info
            </a></h3>
            <AccordianGroup
              initialValues={this.state.initialValues}
              fetchTotalAssessmentData={this.props.fetchTotalAssessmentData}
              handleNextCapacityQuestionData={
                this.props.handleNextCapacityQuestionData
              }
              assessmentId={this.state.assessmentId}
              isFreeOrg={this.props.isFreeOrg}
              isLastQuestion={
                this.props.lastPracticeCode === this.state.practiceCode
              }
              total={this.props.total}
              practiceNo={this.props.practiceNo}
              history={this.props.history}
            /></div></div></div>
          </div>
        </Container>
      </div>
    )
  }
}

export default SurveyQuestion
