import React from 'react'

const Breadcrumb = ({ pathsObject }) => {
  return (
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
		   <div className="col-sm-12">
            <h1 className="m-0 text-dark title_assess">{pathsObject.topic}</h1>
          </div>
          <div className="col-sm-12">
            <ol className="breadcrumb float-sm-left">
              {pathsObject.paths.map((pathItem, i) => {
                return (
                  <li
                    key={i}
                    className={
                      'breadcrumb-item ' +
                      (pathItem.path != null ? 'active' : '')
                    }
                  >
                    <a href={pathItem.path}>{pathItem.title}</a>
                  </li>
                )
              })}
            </ol>
          </div>
       
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
