import React, { Component } from 'react'	
import './layout.css'	
import { paths } from '../sidebarpaths'	
import './sidebar.css'	
import { ApiAuth, HTTPMETHODS } from '../core/api'	
function renderPaths(paths) {	
  if (!Array.isArray(paths)) {	
    // This is a topic	
    return constructTopicPath(paths)	
  } else {	
    return paths.map((pathObj) => {	
      if (pathObj.path) {	
        // This is actually a path	
        return constructPath(pathObj)	
      } else if (pathObj.paths) {	
        // This is a topic	
        return constructTopicPath(pathObj)	
      } else {	
        // This is a section Divider. Return a Divider	
        return <li className="sidebar-divider" key={pathObj.key}></li>	
      }	
    })	
  }	
}	
const constructTopicPath = (pathObj) => {	
  return (	
    <li className="nav-item has-treeview" key={pathObj.key}>	
      <a href={pathObj.path} className="nav-link">	
        {pathObj.icon && <i className={'nav-icon fas ' + pathObj.icon} />}	
        <p>	
          {pathObj.title}	
          <i className="right fas fa-angle-left" />	
        </p>	
      </a>	
      <ul className="nav nav-treeview">	
        {pathObj.paths.map((childObj) => {	
          return childObj.path ? constructPath(childObj) : renderPaths(childObj)	
        })}	
      </ul>	
    </li>	
  )	
}	
const constructPath = (pathObj) => {	
  	
  return (	
    <li className="nav-item" key={pathObj.key}>	
      <a href={pathObj.path} className="nav-link">	
        {pathObj.icon && <i className={'nav-icon fas ' + pathObj.icon} />}	
        <p>{pathObj.title}</p>	
      </a>	
    </li>	
  )	
}	
class Sidebar extends Component {	
  constructor(props) {	
    super(props)	
    this.state = {	
      isExpand: true,	
      isFreeOrg: true,	
    }	
    this.handleExpand = this.handleExpand.bind(this)	
  }	
  componentDidMount() {	
    this.getOrgInfo();	
  }	
  getOrgInfo = async () => {	
    try {	
      const userObj = JSON.parse(localStorage.getItem('userInfo'))	
      const resOrg = await ApiAuth(	
        HTTPMETHODS.GET,	
        `/organization/${encodeURIComponent(userObj.organizationId)}`,	
        null,	
        false,	
        userObj.email,	
        userObj.organizationId	
      )	
      await this.setState({ isFreeOrg: resOrg.data.isFreeOrg });	
    } catch (error) {	
      console.log(error.message)	
    }	
  }	
  handleExpand() {	
    const oldFlag = this.state.isExpand	
    this.setState({ isExpand: !oldFlag })	
  }	
  render() {	
    const { isExpand, isFreeOrg } = this.state;	
    let freeOrgPath = paths;	
    if (isFreeOrg) {	
      freeOrgPath = freeOrgPath.filter(val => val.title !== 'Reports' && val.title !== 'Document Repository' && val.title !=='Template Repository')	
    }	
    return (	
      <div className={`main-sidebar${isExpand ? ' active' : ''}`}>	
        <div className="sidebar">	
          {isExpand ? (	
            <nav className="mt-2">	
              <ul	
                className="nav nav-pills nav-sidebar flex-column"	
                data-widget="treeview"	
                role="menu"	
                data-accordion="false"	
              >
                <i className="close fas fa-angle-double-left" onClick={this.handleExpand} />

                {renderPaths(freeOrgPath)}
              </ul>
            </nav>
          ) : (
            <span
              className="nav-link"
              style={{
                color: 'black',
                marginTop: '10px',
              }}
              onClick={this.handleExpand}
            >
              <i className="bar fas fa-bars" />
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default Sidebar
