import React from 'react'
import './landingPage.css'
import 'font-awesome/css/font-awesome.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'

const PolicyPage = () => {
  return (
    <div className="term-page">
      <header>
        <div className="container">
          <div className="row align_center">
            <div className="col-xl-3 col-md-6 col-xs-12">
              <div className="logo">
                <a href="/">
                  <img src={require('../../assets/logo.jpg')} alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-xl-9 col-md-6 col-xs-12">
              <div className="right-part">
                <ul>
                  <li>
                    <Link to="/login">
                      <i
                        className="fa fa-address-card-o"
                        aria-hidden="true"
                      ></i>{' '}
                      Sign In
                    </Link>
                  </li>
                  <li>
                    <a href="https://intersecinc.com/contact-us">
                      <i className="fa fa-user-o" aria-hidden="true"></i>{' '}
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="security_compliance">
        <div className="container">
          <h2 className="text-center">Privacy Policy</h2>
          <p>
            <b>Last Updated</b>: April 2, 2020
          </p>
          <p>
            InterSec Inc website privacy policy. We collect your Personal
            Information through this website, social media, email, and apps.
          </p>
          <ul>
            <li>
              We use your Personal Information for legitimate business
              interests;
            </li>
            <li>We only disclose it when necessary;</li>
            <li>
              We only collect what we need, we delete it when we no longer need
              it, and we use reasonable measures to keep it secure;
            </li>
            <li>
              To request to access, correct, or delete your Personal
              Information, or to withdraw consent (where applicable) please
              contact us at webmaster@bah.com.
            </li>
          </ul>
          <p>For more detailed information please see below.</p>
          <p>
            InterSec Inc. values your privacy. This Privacy Policy describes our
            practices in connection with information that we collect:
          </p>
          <ul>
            <li>
              Through websites operated by us from which you are accessing this
              Privacy Policy (the “<b>Sites</b>”);
            </li>
            <li>
              through the software applications made available by us for use on
              or through computers and mobile devices (the “<b>Apps</b>”);
            </li>
            <li>
              through our social media pages and apps (collectively, our “
              <b>Social Media Pages</b>”); and
            </li>
            <li>
              through HTML-formatted email messages that we send to you that
              link to this Privacy Policy.
            </li>
          </ul>
          <p>
            Collectively, we refer to the Sites, the Apps, and our Social Media
            Pages, as the “Services”).
          </p>
          <p>
            <strong>Personal and Other Information We May Collect</strong>
          </p>
          <p>
            "<b>Personal Information</b>" is information that can be used to
            distinguish or trace an individual's identity, either alone or when
            combined with other information that is linked or linkable to a
            specific individual. Categories of Personal Information we collect
            include:
          </p>
          <ol>
            <li>Identifiers, such as name and online identifiers;</li>
            <li>
              Personal information, such as name, contact information, payment
              card number, preferences and interests, and demographic
              information;
            </li>
            <li>
              Internet or network activity information, such as browsing history
              and interactions with our website; and
            </li>
            <li>Geolocation data, such as IP location.</li>
          </ol>
          <p>
            If you submit any Personal Information relating to other people to
            us or to our service providers in connection with the Sites, you
            represent that you have the authority to do so and to permit us to
            use the information in accordance with this Privacy Policy.{' '}
          </p>
          <p>
            <b>Collection of Personal Information</b>
          </p>
          <p>
            We and our service providers collect Personal Information in a
            variety of ways, including:
          </p>
          <ul>
            <li>
              <b>Through the Sites</b>: We collect Personal Information through
              the Sites, for example, when you sign up for a newsletter,
              download a publication, register for an event, or request
              information.
            </li>
            <li>
              <b>Offline</b>: We collect Personal Information from you offline,
              e.g., when you attend an event.
            </li>
            <li>
              <b>From Other Sources</b>: We receive your Personal Information
              from other sources, for example:
              <ul>
                <li>publicly available databases;</li>
                <li>
                  joint marketing partners, when they share the information with
                  us;
                </li>
                <li>sales channel partners;</li>
                <li>demand generation companies.</li>
              </ul>
            </li>
          </ul>
          <p>
            We need to collect Personal Information in order to provide the
            requested services to you. If you do not provide the information
            requested, we may not be able to provide the services. If you
            disclose any Personal Information relating to other people to us or
            to our service providers in connection with the Sites, you represent
            that you have the authority to do so and to permit us to use the
            information in accordance with this Privacy Policy.
          </p>
          <p>
            “<b>Other Information</b>” is any information that does not reveal
            your specific identity or does not directly relate to an
            identifiable individual. Other information we collect include:
          </p>
          <ul>
            <li>Browser and device information;</li>
            <li>Application usage data;</li>
            <li>
              Information collected through cookies, pixel tags, and other
              technologies;
            </li>
            <li>
              Demographic information and other information provided by you;
            </li>
            <li>Aggregated information.</li>
          </ul>
          <p>
            If we are required to treat Other Information as Personal
            Information under applicable law, then we may use it for the
            purposes for which we use and disclose Personal Information as
            detailed in this Privacy Policy.
          </p>
          <p>
            <b>How We May Collect Other Information</b>
          </p>
          <p>
            We and our third-party service providers may collect Other
            Information in a variety of ways, including:
          </p>
          <ul>
            <li>
              <b>Through your browser or device</b>: Certain information is
              collected by most browsers or automatically through your device,
              such as your Media Access Control address, computer type, screen
              resolution, operating system name and version, device manufacturer
              and model, language, Internet browser type and version, referring
              URL, and the name and version of the Sites (such as the mobile
              application) you are using. We use this information to ensure that
              the Sites function properly.
            </li>
            <li>
              <b>Through your use of an App</b>: When you download and use the
              App, we and our service providers may track and collect App usage
              data, such as the date and time the App on your device accesses
              our servers and what information and files have been downloaded to
              the App based on your device number.
            </li>
            <li>
              <b>IP Address</b>: Your IP address is a number that is
              automatically assigned to the computer that you are using by your
              Internet Service Provider. An IP address may be identified and
              logged automatically in our server log files whenever a user
              accesses the Sites, along with the time of the visit and the pages
              visited. We use IP addresses for purposes such as calculating
              usage levels, diagnosing server problems, and administering the
              Sites. We may also derive your approximate location from your IP
              address.
            </li>
            <li>
              <b>From you</b>: Information such as your preferred means of
              communication collected when you voluntarily provide it.
            </li>
          </ul>
          <p>
            <strong>How We May Use Personal and Other Information</strong>
          </p>
          <p>
            We may use Personal Information for legitimate business purposes
            including:
          </p>
          <p>
            <b>
              Providing the functionality of the Sites and fulfilling your
              requests.
            </b>
          </p>
          <ul>
            <li>
              To provide the Sites’ functionality to you, such as arranging
              access to your registered account when applying for a position and
              providing you with related customer service.
            </li>
            <li>
              To respond to your inquiries and fulfill your requests, when you
              contact us via one of our online contact forms or otherwise, for
              example, when you send us questions, suggestions, compliments, or
              complaints, or when you request a quote for or other information
              about our Sites.
            </li>
            <li>
              To complete your transactions and provide you with related
              customer service.
            </li>
            <li>
              To send administrative information to you, such as changes to our
              terms, conditions, and policies.
            </li>
            <li>
              To allow you to send messages to another person if you choose to
              do so.
            </li>
          </ul>
          <p>
            We will engage in these activities to manage our contractual
            relationship with you and/or to comply with a legal obligation.
          </p>
          <b>
            Providing you with our newsletter and/or other marketing materials
            and facilitating social sharing.
          </b>
          <ul>
            <li>
              To send you marketing-related emails, with information about our
              Sites, new products, and other news about our company.
            </li>
            <li>
              To facilitate social sharing functionality that you choose to use.
            </li>
          </ul>
          <p>
            We will engage in this activity because we have a legitimate
            interest or with your consent (where required).
          </p>
          <p>
            <b>
              Analysis of Personal Information for business reporting and
              providing personalized services.
            </b>
          </p>
          <ul>
            <li>
              To analyze or predict our users’ preferences in order to prepare
              aggregated trend reports on how our digital content is used, so we
              can improve our Sites.
            </li>
            <li>
              To better understand you, so that we can personalize our
              interactions with you and provide you with information and/or
              offers tailored to your interests.
            </li>
            <li>
              To better understand your preferences so that we can deliver
              content via our Sites that we believe will be relevant and
              interesting to you.
            </li>
          </ul>
          <p>
            We will provide personalized services either with your consent or
            because we have a legitimate interest.
          </p>
          <p>
            <b>Aggregating and/or anonymizing Personal Information.</b>
          </p>
          <p>
            We may aggregate and/or anonymize Personal Information so that it
            will no longer be considered Personal Information. We do so to
            generate other data for our use, which we may use and disclose for
            any business purpose.
          </p>
          <p>
            <b>Accomplishing our business purposes.</b>
          </p>
          <ul>
            <li>
              For data analysis, for example, to improve the efficiency of our
              Sites;
            </li>
            <li>
              For audits, to verify that our internal processes function as
              intended and are compliant with legal, regulatory, or contractual
              requirements;
            </li>
            <li>
              For fraud and security monitoring purposes, for example, to detect
              and prevent cyberattacks or attempts to commit identity theft;
            </li>
            <li>For developing new products and services;</li>
            <li>
              For enhancing, improving, or modifying our current products and
              services;
            </li>
            <li>
              For identifying usage trends, for example, understanding which
              parts of our Sites are of most interest to users;
            </li>
            <li>
              For determining the effectiveness of our promotional campaigns, so
              that we can adapt our campaigns to the needs and interests of our
              users; and
            </li>
            <li>
              For operating and expanding our business activities, for example,
              understanding which parts of our Sites are of most interest to our
              users so we can focus our energies on meeting our users’
              interests;
            </li>
          </ul>
          <p>
            <strong>How Personal Information May Be Disclosed</strong>
          </p>
          <p>Your Personal Information may be disclosed:</p>
          <ul>
            <li>
              To our affiliates for the purposes described in this Privacy
              Policy.
              <ul>
                <li>
                  You can consult the list and location of our affiliates here;
                </li>
              </ul>
            </li>
            <li>
              To our third-party service providers, to facilitate services they
              provide to us, including website hosting, data analysis,
              information technology, payment processing, order fulfillment,
              customer service, email delivery, auditing, and other services;
            </li>
            <li>
              To identify you to anyone to whom you send messages through the
              Sites.
            </li>
          </ul>
          <p>
            <b>Other Uses and Disclosures</b>
          </p>
          <p>
            We also use and disclose your Personal Information as we believe to
            be necessary or appropriate, especially when we have a legal
            obligation or legitimate interest to do so:
          </p>
          <ul>
            <li>
              To comply with applicable law, which may include laws outside your
              country of residence;
            </li>
            <li>
              To respond to requests from public and government authorities,
              which may include such authorities outside your country of
              residence;
            </li>
            <li>
              To cooperate with law enforcement to enforce our terms and
              conditions; and to protect our rights, privacy, safety, or
              property, and/or that of our affiliates, you, or others.
            </li>
            <li>
              In connection with a sale or business transaction. We have a
              legitimate business interest in disclosing or transferring your
              Personal Information to a third party in the event of any
              reorganization, merger, sale, joint venture, assignment, transfer,
              or other disposition of all or any portion of our business assets
              or stock (including in connection with any bankruptcy or similar
              proceedings.)
            </li>
          </ul>
          <p>
            <b>How We May Use and Disclose Other Information</b>
          </p>
          <p>
            We may use and disclose Other Information for any business purpose,
            except where we are required to do otherwise under applicable law.
            If we combine Other Information with Personal Information, we will
            treat the combined information as Personal Information as long as it
            is combined.
          </p>
          <p>
            <strong>Third Party Sites</strong>
          </p>
          <p>
            This Privacy Policy does not address, and we are not responsible
            for, the privacy or other practices of any third parties, including
            any third party operating any site or service to which the Sites
            link. The inclusion of a link on the Sites does not imply
            endorsement of the linked site or service by us or by our
            affiliates. In addition, we are not responsible for the information
            practices of other organizations, social media sites, web browsers,
            or software.
          </p>
          <p>
            <strong>Security</strong>
          </p>
          <p>
            We seek to use reasonable organizational, technical, and
            administrative measures to protect Personal Information within our
            organization. Unfortunately, no data transmission or storage system
            can be guaranteed to be 100% secure. If you have reason to believe
            that your interaction with us is no longer secure, please
            immediately notify us in accordance with the “Contacting Us” section
            below.
          </p>
          <p>
            <strong>Choices and Access</strong>
          </p>
          <p>
            <b>Your choices regarding our use of your Personal Information</b>
          </p>
          <p>
            If you no longer want to receive marketing-related emails from us on
            a going-forward basis, you may opt out by following the instructions
            contained in each email. We will try to comply with your request as
            soon as reasonably practicable. Please note that, if you opt out, we
            may still send you important administrative messages, from which you
            cannot opt out.
          </p>
          <p>
            <b>
              How you can access, change or suppress your Personal Information
            </b>
          </p>
          <p>
            If you would like to review, correct, update, suppress, restrict, or
            delete Personal Information that you have previously provided to us,
            or if you would like to request to receive an electronic copy of
            your Personal Information for purposes of transmitting it to another
            company (to the extent this right to data portability is provided to
            you by applicable law), you may contact us at
            inquiries@intersecinc.com. We will respond to your request
            consistent with applicable law.
          </p>
          <p>
            In your request, please make clear what Personal Information you
            would like to have changed, whether you would like to have your
            Personal Information suppressed from our database or otherwise let
            us know what limitations you would like to put on our use of your
            Personal Information. For your protection, we may only implement
            requests with respect to the Personal Information associated with
            the email address that you use to send us your request, and we may
            need to verify your identity before implementing your request. We
            will try to comply with your request as soon as reasonably
            practicable.
          </p>
          <p>
            Please note that we may need to retain certain information for
            recordkeeping purposes and/or to complete any transactions that you
            began prior to requesting a change or deletion. There may also be
            residual information that will remain within our databases and other
            records, which will not be removed.
          </p>
          <p>
            <strong>Retention Period</strong>
          </p>
          <p>
            We will retain your Personal Information for the period necessary to
            fulfill the purposes outlined in this Privacy Policy unless a longer
            retention period is required or permitted by law.
          </p>
          <p>The criteria used to determine our retention periods include:</p>
          <ul>
            <li>
              The length of time we have an ongoing relationship with you and
              provide the Sites to you (for example, for as long as you have an
              account with us or keep using the Sites);
            </li>
            <li>
              Whether there is a legal obligation to which we are subject (for
              example, certain laws require us to keep records of your
              transactions for a certain period of time before we can delete
              them); or
            </li>
            <li>
              Whether retention is advisable in light of our legal position
              (such as in regard to applicable statutes of limitations,
              litigation, or regulatory investigations).
            </li>
          </ul>
          <p>
            <strong>Third Party Advertising</strong>
          </p>
          <p>
            {' '}
            We use third-party advertising companies to serve advertisements
            regarding goods and services that may be of interest to you when you
            access and use the Services and other websites or online services.
          </p>
          <p>
            You may receive advertisements based on information relating to your
            access to and use of the Sites and other websites or online services
            on any of your devices, as well as on information received from
            third parties. These companies place or recognize a unique cookie on
            your browser (including through the use of pixel tags). They also
            use these technologies, along with information they collect about
            your online use, to recognize you across the devices you use, such
            as a mobile phone and a laptop. If you would like more information
            about this practice, and to learn how to opt out of it in desktop
            and mobile browsers on the particular device on which you are
            accessing this Privacy Policy, please visit{' '}
            <a href="http://optout.aboutads.info/#/">
              http://optout.aboutads.info/#/
            </a>{' '}
            and{' '}
            <a href="http://optout.networkadvertising.org/#/">
              http://optout.networkadvertising.org/#/
            </a>
            . You may download the AppChoices app at{' '}
            <a href="http://www.aboutads.info/appchoices">
              http://www.aboutads.info/appchoices
            </a>{' '}
            to opt out in mobile apps.
          </p>
          <p>
            <strong>Use of the Site by Minors</strong>
          </p>
          <p>
            The Sites are not directed to individuals under the age of eighteen
            (18), and we do not knowingly collect Personal Information from such
            children.
          </p>
          <p>
            <strong>Sensitive Information</strong>
          </p>
          <p>
            We ask that you not send us, and you not disclose, any sensitive
            Personal Information (e.g., social security numbers, information
            related to racial or ethnic origin, political opinions, religion or
            other beliefs, health, biometrics or genetic characteristics, trade
            union membership, or criminal background) on or through the Sites or
            otherwise to us.
          </p>
          <p>
            <strong>Jurisdiction and Cross-Border Transfer</strong>
          </p>
          <p>
            The Sites are controlled and operated by us from the United States
            and are not intended to subject us to the laws or jurisdiction of
            any state, country, or territory other than that of the United
            States. Your Personal Information may be stored and processed in any
            country where we have facilities or in which we engage service
            providers, and by using the Sites you understand that your
            information will be transferred to countries outside of your country
            of residence, including the United States, which may have data
            protection rules that are different from those of your country. In
            certain circumstances, courts, law enforcement agencies, regulatory
            agencies or security authorities in those other countries may be
            entitled to access your Personal Information.
          </p>
          <p>
            <strong>Third-Party Payment Processing</strong>
          </p>
          <p>
            We may use a third-party payment service to process payments made
            through the Sites. If you wish to make a payment through the Sites,
            your Personal Information may be collected by such third party and
            not by us, and will be subject to the third party’s privacy policy,
            rather than this Privacy Policy. We have no control over, and are
            not responsible for, this third party’s collection, use and
            disclosure of your Personal Information.
          </p>
          <p>
            <strong>Updates to this Privacy Policy</strong>
          </p>
          <p>
            We may change this Privacy Policy. The “Last Updated” legend at the
            top of this page indicates when this Privacy Policy was last
            revised. Any changes will become effective when we post the revised
            Privacy Policy on the Sites. Your use of the Sites following these
            changes means that you accept the revised Privacy Policy.
          </p>
          <p>
            <strong>Contacting Us</strong>
          </p>
          <p>
            InterSec Inc., located at 202 Church St SE, #213B, Leesburg, VA
            20175, is the company responsible for the collection, use, and
            disclosure of your Personal Information under this Privacy Policy.
          </p>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at inquiries@intersecinc.com. Because email communications are
            not always secure, please do not include sensitive information in
            your emails to us.
          </p>
          <p>
            <strong>Additional Information for Individuals in the EEA</strong>
          </p>
          <p>If you are located in the EEA, you also may:</p>
          <ul>
            <li>
              Lodge a complaint with a data protection authority for your
              country or region, or where an alleged infringement of applicable
              data protection law occurs.
            </li>
          </ul>
          <p>
            <strong>California Privacy Notice</strong>
          </p>
          <p>
            Pursuant to the California Consumer Privacy Act (CCPA), we are
            providing the following additional details regarding the categories
            of Personal Information about California residents that we have
            collected or disclosed within the preceding 12 months: We collected
            the following categories of Personal Information:
          </p>
          <ol>
            <li>Identifiers, such as name and online identifiers;</li>
            <li>
              Personal information, such as name, contact information, payment
              card number, preferences and interests, and demographic
              information;
            </li>
            <li>
              Internet or network activity information, such as browsing history
              and interactions with our website;
            </li>
            <li>Credit or debit card numbers; and</li>
            <li>Geolocation data, such as IP location.</li>
          </ol>
          <p>
            As described above, we collect this Personal Information from you
            and from other categories of sources: publicly available databases,
            sales channel partners, demand generation companies and joint
            marketing partners, when they share the information with us. Also as
            described above, we may use this Personal Information to operate,
            manage, and maintain our business, to provide our products and
            services, and to accomplish our business purposes and objectives,
            including, for example, using Personal Information to: develop,
            improve, repair, and maintain our products and services;
            personalize, advertise, and market our products and services;
            conduct research, analytics, and data analysis; maintain our
            facilities and infrastructure; undertake quality and safety
            assurance measures; conduct risk and security control and
            monitoring; detect and prevent fraud; perform identity verification;
            perform accounting, audit, and other internal functions, such as
            internal investigations; comply with law, legal process, and
            internal policies; maintain records; and exercise and defend legal
            claims.
          </p>

          <ol>
            <p>
              (1) We may disclose the following Personal Information to third
              parties for our operational business purposes:
            </p>
            <ol>
              <p>
                <li>Identifiers, such as name and online identifiers;</li>
                <li>
                  Personal information, such as name, contact information,
                  payment card number, preferences and interests, and
                  demographic information;
                </li>
                <li>
                  Internet or network activity information, such as browsing
                  history and interactions with our website;
                </li>
                <li>Credit or debit numbers; and</li>
                <li>Geolocation data, such as IP location.</li>
              </p>
              <p style={{ marginLeft: '-20px' }}>
                We share Personal Information with our affiliates, service
                providers, and our business partners].
              </p>
            </ol>

            <p>
              (2) We have not “sold” Personal Information for purposes of the
              CCPA.
            </p>
          </ol>
          <p>
            For purposes of this Privacy Policy, “sold” or “sale” means the
            disclosure of Personal Information for monetary or other valuable
            consideration but does not include, for example, the transfer of
            Personal Information as an asset that is part of a merger,
            bankruptcy, or other disposition of all or any portion of our
            business.
          </p>
          <p>If you are a California resident, you may request that we:</p>
          <p>
            <ul>
              <p>
                (1) Disclose to you the following information covering the 12
                months preceding your request:
              </p>
              <p>
                <ul>
                  <li>
                    The categories of Personal Information we collected about
                    you and the categories of sources from which we collected
                    such Personal Information;
                  </li>
                  <li>
                    The specific pieces of Personal Information we collected
                    about you; and
                  </li>
                  <li>
                    The categories of Personal Information about you that we
                    otherwise shared or disclosed, and the categories of third
                    parties with whom we shared or to whom we disclosed such
                    Personal Information (if applicable).
                  </li>
                </ul>
              </p>
              <p>(2) Delete Personal Information we collected from you.</p>
            </ul>
          </p>
          <p>
            To make a request for the disclosures or deletion described above,
            please contact us in accordance with the “Choices and Access”
            section above. We will respond to your request consistent with
            applicable law.
          </p>
          <p>
            You have the right to be free from unlawful discrimination for
            exercising your rights under the CCPA.
          </p>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <p>Powered by InterSec, Inc.</p>
            </div>
            <div className="col-xl-4 col-md-4 text-center">
              <p>Copyright © 2021 InterSec, Inc.</p>
            </div>
            <div className="col-xl-4 col-md-4 text-right">
              <ul>
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <a href="https://intersecinc.com/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PolicyPage
