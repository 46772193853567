import React, { Component, createRef } from 'react'

import Chart from 'chart.js'

class ChartElement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      baseUrl: null,
    }
    this.chartRef = createRef()
    this.chartContexts = {}
    this.createChart = this.createChart.bind(this)
  }

  componentDidUpdate() {
    this.createChart()
  }

  createChart() {
    const { chartId, type, data, options } = this.props
    var temp_type = type
    if (type === 'bar1' || type === 'bar2') temp_type = 'bar'
    if (this.chartContexts[chartId]) {
      this.chartContexts[chartId].destroy()
    }
    const chartRefGrib = this.chartRef.current.getContext('2d')
    const chartContext = new Chart(chartRefGrib, {
      type: temp_type,
      data: data,
      options: options,
    })
    chartContext.update(0)
    this.chartContexts[chartId] = chartContext
    var url_base64jp = this.chartRef.current.toDataURL('image/jpg')
    if (type === 'bar1' && url_base64jp.length > 6) {
      // this.props.handleBaseURL(1, url_base64jp)
    } else if (type === 'bar2' && url_base64jp.length > 6) {
      this.props.handleBaseURL(2, url_base64jp)
    } else if (type === 'pie' && url_base64jp.length > 6) {
      this.props.handleBaseURL(3, url_base64jp)
    }
  }

  render() {
    const { chartId, height, width } = this.props
    return (
      <>
        <canvas
          id={chartId}
          ref={this.chartRef}
          height={height}
          width={width}
        />
      </>
    )
  }
}

export default ChartElement