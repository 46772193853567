import { ApiAuth, HTTPMETHODS } from '../../components/core/api'
import React, { Component } from 'react'
import DocumentRepositoryTable from '../../components/DocumentRepository/documentRepositoryTable'
import Breadcrumb from '../../components/core/breadcrumb'
import { Card } from 'antd'
import { bcDocumentRepository } from '../../components/core/breadcrumbpaths'
import swal from 'sweetalert'
class DocumentRepository extends Component {
  constructor(props) {
    super(props)
    this.state = {
      assessments: null,
      selectedRowKey: null,
      orgName: null,
      isFreeOrg: true,
      cmmcVersion: null,
      cmmcLevel: null,
      score: 0,
      baseURL1: null,
      baseURL2: null,
      baseURL3: null,
      gapResponseData: [],
      isAnswered: null,
    }
  
  }
  componentDidMount = async () => {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'))
    console.log(this.userObj)
    this.getAssessments(this.userObj)
    this.getOrgInfo(this.userObj)

  }

  getAssessments = async (userObj) => {
    try {
      const lstAssessments = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (lstAssessments.data !== null) {
        await Promise.all(
          lstAssessments.data.map(async (item) => {
           /*  if (item.name.length > 20) {
              item.name = item.name.substring(0, 20)
            } */
            var oldDate = item.updatedDate.split(' ')[0]
            var mm = oldDate.split('-')[1]
            var dd = oldDate.split('-')[2]
            var yy = oldDate.split('-')[0]
            var newDate = mm + '/' + dd + '/' + yy
            var newTime = item.updatedDate.split(' ')[1].split('.')[0]
            item.updatedDate = newDate + ' ' + newTime
            let newItem = item
            try {
              let responseCountData = await ApiAuth(
                HTTPMETHODS.GET,
                `/response/count?assessId=${item.id}&level=${item.cmmcLevel}`,
                null,
                false,
                userObj.email,
                userObj.organizationId
              )
              let totalQuestions = responseCountData.data.totalQuestions
              let answeredQuestion = totalQuestions - responseCountData.data.notAnsweredTotal
              let notAnsweredTotal = responseCountData.data.notAnsweredTotal
                if(totalQuestions === notAnsweredTotal){
                  newItem.processStatus = 'Created';
                }
                else if(notAnsweredTotal === 0){
                  newItem.processStatus = 'Completed';
                }
                else if(notAnsweredTotal > 0){
                  newItem.processStatus = 'In Progress';
                }
               
              newItem.questionCount = answeredQuestion + ' / ' + totalQuestions
              newItem.isAnswered = answeredQuestion === totalQuestions
            } catch (error) {
              console.log(error.message)
            }
            return newItem
          })
        )
        this.setState({ assessments: lstAssessments.data })
      }
    } catch (error) {
      console.log(error)
      this.setState({ assessments: null })
      this.setState({ error_message: error.message })
    }
  }
  getOrgInfo = async (userObj) => {
    try {
      const resOrg = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const isFreeOrg = resOrg.data.isFreeOrg
      const orgName = resOrg.data.name
      this.setState({ orgName })
      this.setState({ isFreeOrg })
    } catch (error) {
      console.log(error.message)
    }
  }
 
  handleBaseURL = (index, val) => {
    if (index === 1 && val) this.setState({ baseURL1: val })
    else if (index === 2 && val) this.setState({ baseURL2: val })
    else if (index === 3 && val) this.setState({ baseURL3: val })
  }
  render() {
   
    return (
      <div
        className="content-wrapper"
        style={{
          width: '100%',
          height: '100%',
          marginLeft: '0px',
          overflow: 'hidden',
        }}
      >
        <Breadcrumb pathsObject={bcDocumentRepository} />
        <section className="content">
          <div className="container-fluid">
            <Card className="card" bordered={false}>
                <div className="mb-2">
                  <div style={{ width: '100%' }}>
                    <DocumentRepositoryTable
                      onSelectChange={this.onSelectChange}
                      assessments={this.state.assessments}
                    />
                  </div>
                </div>
            
            </Card>
          </div>
        </section>
      </div>
    )
  }
}

export default DocumentRepository
