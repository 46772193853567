import React, { Component } from 'react'
import PropTypes from 'prop-types'

class BoxContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      styles: {
        rectangle: {
          display: 'inline-block',
          width: this.props.widthIndicator ? this.props.widthIndicator : '10px',
          height: '10px',
          background: this.props.color,
          marginRight: '10px',
        }
      }
    }
  }

  componentWillMount() {
    this.setState({
      width: this.props.widthIndicator ? this.props.widthIndicator : '20px',
      background: this.props.color,
    })
  }

  // componentDidMount() {

  // }

  // componentWillReceiveProps(nextProps) {

  // }

  // shouldComponentUpdate(nextProps, nextState) {

  // }

  // componentWillUpdate(nextProps, nextState) {

  // }

  // componentDidUpdate(prevProps, prevState) {

  // }

  // componentWillUnmount() {

  // }

  render() {
    return (
      <div style={this.state.styles.rectangle}>

      </div>
    )
  }
}

BoxContainer.propTypes = {

}

export default BoxContainer