import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import './register.css'
import Axios from 'axios'
import { ApiAuth, HTTPMETHODS, ApiAuthInitialState } from './api'
// import { Auth } from 'aws-amplify'

const password_lowercase_Regex = /^(?=.*[a-z]).*$/
const password_uppercase_Regex = /^(?=.*[A-Z]).*$/
const password_special_Regex = /^(?=.*[@#$%^&+=]).*$/
const email_common = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho))/

const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .matches(
      email_common,
      'Email Address cannot be in one of the common Email Domains'
    )
    .required('Email is Required'),
  code: Yup.number().required('Code is Required!'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password has to be at least 8 characters in length!')
    .matches(
      password_lowercase_Regex,
      'Password should contain at least 1 LowerCase Character!'
    )
    .matches(
      password_uppercase_Regex,
      'Password should contain at least 1 UpperCase Character!'
    )
    .matches(
      password_special_Regex,
      'Password should contain at least 1 Special (@#$%^&+=) Character!'
    ),
})

class ForgotPasswordConfirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      code: '',
    }
  }

  submitForm = async (values, history) => {
    try {
      const passwordResetPayload =
      {
        "confirmationCode": values.code,
        "password": values.password,
        "username": values.email
      }
      const resetpasswordResponse = await ApiAuthInitialState(
        HTTPMETHODS.POST,
        `/changepassword`,
        passwordResetPayload,
        false,
        values.email,
        // userObj.organizationId
      )
      if (resetpasswordResponse)
        swal(
          'Password Reset Successful!',
          'You will now be redirected to the login page!',
          'success'
        ).then(async (value) => {
          // const user = await Auth.signIn(values.email, values.password)
          const payload = {
            "email": values.email,
            "password": values.password
          }
          const user = await ApiAuthInitialState(
            HTTPMETHODS.POST,
            `/signin`,
            payload,
            false,
            payload.email,
            // userObj.organizationId
          )

          if (user) {
            let userName = values.email
            let userNameInLowerCase = userName.toLocaleLowerCase()
            //User is authenticated here. So Call the redirect to the dashboard page!
            // swal('Success!', 'Logged in Successfully!', 'success') 
            const isUserAvailable = await this.getUserInfo(userNameInLowerCase)
            if (isUserAvailable === true) {
              localStorage.setItem('isUserLoggedIn', 'true')
              history.push('/dashboard')
            } else {
              history.push('/dashboard')
            }
          }
        })
    } catch (error) {
      let messageError = error.message
      if (error.code === 'UserNotFoundException') {
        messageError = 'User not found! Please try with a valid user'
      }
      swal('Error!', messageError, 'error')
      console.log('error signing in', error)
    }
  }

  getUserInfo = async (email) => {
    let userFound = false
    try {
      const userReturnObj = await ApiAuth(
        HTTPMETHODS.GET,
        `/user?email=${email}`,
        null,
        false,
        email
      )
      if (userReturnObj.status === 200) {
        localStorage.setItem('userInfo', JSON.stringify(userReturnObj.data))
        userFound = true
      }
    } catch (error) {
      console.log('User could not be traced...')
    }
    return userFound
  }
  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <p style={{ textAlign: 'center' }}>
          Enter your confirmation code and credentials to complete your reset.
        </p>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">Email Id</label>
          <div className="col-sm-6">
            <input
              type="email"
              name="email"
              placeholder="name@example.com"
              className={
                errors.email && touched.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">Code</label>
          <div className="col-sm-6">
            <input
              name="code"
              type="text"
              placeholder="Confirmation Code"
              className={
                errors.code && touched.code
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.code}
              onChange={handleChange}
            />
            {errors.code && touched.code ? (
              <small className="text-danger">{errors.code}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label">Password</label>
          <div className="col-sm-6">
            <input
              name="password"
              type="password"
              placeholder="password"
              className={
                errors.password && touched.password
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.password}
              onChange={handleChange}
            />
            {errors.password && touched.password ? (
              <small className="text-danger">{errors.password}</small>
            ) : null}
          </div>
        </div>
        <div className="row justify-content-md-center">
          <button
            disabled={isSubmitting}
            type="submit"
            className="col-4 btn btn-primary btn-rounded btn-block"
          >
            Change Password
          </button>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className="login-page">
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <h3 className="register-box-msg">Password Change Confirmation</h3>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  code: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history)
                  setSubmitting(false)
                }}
                validationSchema={registerSchema}
              >
                {(props) => this.showForm(props)}
              </Formik>
              <p className="mb-1">
                <Link
                  to="/login"
                  style={{ position: 'absolute', right: '20px' }}
                >
                  Sign In
                </Link>
              </p>
            </div>
            {/* /.register-card-body */}
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPasswordConfirm
