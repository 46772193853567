import React from 'react';
import PropTypes from 'prop-types';
import InviteUserRegister from '../../components/Registration/inviteUserRegister'

class inviteUserRegistation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <InviteUserRegister/>
            </div>
        );
    }
}

// inviteUserRegistation.propTypes = {};

export default inviteUserRegistation;
