import React, { Component } from 'react'
import { Button, Dropdown, DropdownButton, Modal ,Form} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { ApiAuth, HTTPMETHODS } from '../core/api'

let templateContentArrayWithoutRepoName=[]

export default class TemplateRepositoryTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: null,
      currentAssesment: null,
      templatename:null
    }
  }

  componentDidMount() {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'))

    this.setState({
      tableData: null,
      currentAssesment: this.props.assessmentId ? this.props.assessmentId[0].id : null,
    })
    this.initFileDownloadCount(this.userObj)
  }

  initFileDownloadCount = () => {
    setTimeout(() => {
      this.getTemplateContent(this.userObj)
    }, 3000);
  }

  getFileDownloadByOrgnizationId = async (userObj, domainAbbreviation) => {
    try {
      const assessmentId = this.props.templates[0].id
      if (assessmentId) {
        const responseDownloadURL = await ApiAuth(
          HTTPMETHODS.POST,
          `/repository/download/${assessmentId}/${domainAbbreviation.actions}`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        )
        window.open(responseDownloadURL.data, "_blank")
      }

    } catch (error) {
      console.log(error.message)
    }
  }

  getTemplateContent = async (userObj,value) => {
    let templateContentArray=[]
    var intialTemplateOption
    if(this.props.templates[0])
     intialTemplateOption = this.props.templates[0]
    const templateName = value?value:intialTemplateOption
    try {
      const getTemplateContent = await ApiAuth(
        HTTPMETHODS.GET,
        `/repository/templates/${templateName}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
    getTemplateContent.data.map((data,index)=>{
      let templateName = data
      var trimmedTempName = templateName.split("/");
      templateContentArray.push({ "templatename1":trimmedTempName[1],"templatename":templateName})
      // templateContentArray.push({ "templatename":templateName})
    })
    this.setState({
      tableData: templateContentArray
    })

    } catch (error) {
      console.log(error.message)
    }
  }

  downloadTemplateContent = async (userObj,value) => {
    try {
      console.log(value)
      if (value) {
        const responseDownloadURL = await ApiAuth(
          HTTPMETHODS.GET,
          `/repository/downloadtemplate/${value.templatename}`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        )
        window.open(responseDownloadURL.data, "_blank")
      }

    } catch (error) {
      console.log(error.message)
    }
  }

  handleChange = async (e) => {
     const value = e.target.value
     if(value)
      this.getTemplateContent(this.userObj,value) 
  }

  removeRepoName = async(value) => {
    var trimmedTempName = value.split("/");
  }

  render() {
    const colsAssessment = [
      {
        title: '#',
        dataIndex: 'templatename',
        fixed: 'center',
        width: 20,
        render: (value, item, index) => index + 1
      }, {
        title: 'Template Name',
        dataIndex: 'templatename1',
        fixed: 'center',
        width: 300,
        render: (value, item, index) =>
            <p>
              {value}
            </p>
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        fixed: 'center',
        width: 100,
        render: (_, actions) =>
          <Button className="btn-sm btn btn_table mb-2 mr-2"
            disabled={actions.totalfiles<1}
            onClick={() => {
              const userObj = JSON.parse(localStorage.getItem('userInfo'))
              this.downloadTemplateContent(userObj, actions)
            }}
          >
            Download 
            </Button>
      },
    ]
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.onSelectChange(
          selectedRowKeys,
          selectedRows[0].cmmcLevel,
          selectedRows[0].isAnswered
        )
      },
      type: 'button',
    }
    let locale = {
      emptyText: (
        <span>
          <p>
            <b>No Templates Available </b>
          </p>
        </span>
      )
    };
    return (
      <>
        <div
          className="btn_sec mb-2"
          style={{
            width: '100%',
            textAlign: 'left',
            display: 'inline-block',
          }}
        >

          <label
            className="btn-sm btn btn_table mb-2 mr-2"
          >
            Select Template
          </label>

          <div className="btn-group">
          <Form.Control
                as="select"
                className="mr-sm-2"
                id="inlineFormCustomSelect"
                onChange={this.handleChange.bind(this)} 
                custom
                style={{fontWeight:'normal', fontFamily: 'Roboto'}}
                >
              {this.props.templates ? this.props.templates.map((data, index) => (
                 <option
                  style={{paddingLeft:'5px'}}
                  value={data} 
                  // onClick={() => {  
                  // this.getTemplateContent.bind(this,this.userObj,data) }}
                   >
                  {data}
                 </option>
              )) :
                <option
                className="btn-sm btn btn_table pl-1"
                value='templates'>No Templates
                </option>}
                </Form.Control>
          </div>

          <Table
            size="small"
            // bordered
            rowKey="id"
            locale={locale}
            // rowSelection={rowSelection}
            columns={colsAssessment}
            dataSource={this.state.tableData}
            pagination={false}
          />
        </div>
      </>
    )
  }
}

