import React, { Component } from 'react'
import Accordion from './Accordian'
import isEqual from 'lodash/isEqual'
import { Formik } from 'formik'
import * as Yup from 'yup'

const surveySchema = Yup.object().shape({
  explanation: Yup.string()
    .min(3, 'Explanation is Too Short!')
    .max(250, 'Explanation is Too Long!'),
  response: Yup.string()
    .required('Response is Required!')
    .oneOf(
      ['Yes', 'No', 'Partially', 'Does Not Apply', 'Alternative Approach'],
      'Invalid Selection'
    ),
})
class AccordianGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      curIndex: 0,
      accordianLen: 0,
      flags: [],
      questionObj: [],
      isSubmitting: false,
    }
    this.setCurIndex = this.setCurIndex.bind(this)
    this.makeAccordianActive = this.makeAccordianActive.bind(this)
    this.updateFlags = this.updateFlags.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate() {
    const newQuestionObj = []
    const newFlags = []
    this.props.initialValues.forEach((item, i) => {
      newQuestionObj.push(item)
      if (i === 0) newFlags.push(true)
      else newFlags.push(false)
    })
    if (
      newQuestionObj.length > 0 &&
      !isEqual(this.state.questionObj, newQuestionObj)
    )
      this.setState({ questionObj: newQuestionObj })
    if (this.state.flags.length !== newFlags.length)
      this.setState({ flags: newFlags })
    if (!isEqual(this.props.initialValues.length, this.state.accordianLen))
      this.setState({ accordianLen: this.props.initialValues.length })
  }
  handleChange = async (e) => {
    e.preventDefault()
  }

  setCurIndex(i) {
    this.setState({ curIndex: i })
  }

  updateFlags(flag, direction) {
    const updatedFlags = this.state.flags.map((item, i) => {
      if (i === this.state.curIndex) {
        return flag
      }

      if (direction) {
        if (direction === 'next' && i === this.state.curIndex + 1) {
          return true
        }

        if (direction === 'prev' && i === this.state.curIndex - 1) {
          return true
        }
      }
      return item
    })

    this.setState({ flags: updatedFlags })
  }
  showForm = (props, item, i, oldProps) => {
    const { values, handleChange } = props

    const questionObj = values
    return (
      <Accordion
        key={i}
        index={i}
        isActive={item}
        isSubmitting={this.state.isSubmitting}
        questionObj={questionObj}
        accordianLen={this.state.accordianLen}
        onClick={this.makeAccordianActive}
        handleChange={handleChange}
        oldProps={oldProps}
        assessmentId={this.props.assessmentId}
        handleNextCapacityQuestionData={
          this.props.handleNextCapacityQuestionData
        }
        fetchTotalAssessmentData={this.props.fetchTotalAssessmentData}
        isFreeOrg={this.props.isFreeOrg}
        total={this.props.total}
        practiceNo={this.props.practiceNo}
        isLastQuestion={this.props.isLastQuestion}
        history={this.props.history}
      />
    )
  }
  makeAccordianActive(accordianIndex, flag, direction) {
    this.setState({ curIndex: accordianIndex }, () => {
      this.updateFlags(flag, direction)
    })
  }
  render() {
    return this.state.flags.map((item, i) => (
      <section key={i} className="content">
        <Formik
          enableReinitialize
          initialValues={this.state.questionObj[i]}
          validateOnChange={false}
          validateOnBlur={false}
          // onSubmit={(values, { setSubmitting }) => {
          //   this.submitForm(values, this.props.history)
          //   setSubmitting(false)
          // }}
          validationSchema={surveySchema}
        >
          {(props) => this.showForm(props, item, i, this.state.questionObj[i])}
        </Formik>
      </section>
    ))
  }
}

export default AccordianGroup
