import React, { Component } from 'react'
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Logout } from '../core/logout'
import { Table } from 'antd'
import { ApiAuth, HTTPMETHODS } from '../core/api'
import _, { valuesIn } from 'lodash'
import swal from 'sweetalert'
import { withRouter } from 'react-router-dom'


var userData

class ManageUserTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: null,
      currentAssesment: null,
      userListCount: 0,
      selectedUser: null,
      selectedRole: null,
      isRoleChangeModelOpen: false,
      isUserRemoveModelOpen: false,
      adminCount: 0,
      deleteUserPopupVisible:true,
      usersListData:null
    }
  }

  componentDidMount() {
    this.setState({
      adminCount:0
    })
    this.userObj = JSON.parse(localStorage.getItem('userInfo'))
    this.getUserGroupList(this.userObj)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state && nextProps === this.props) return false
    return true
  }

  openRoleChangeModel = () => this.setState({ isRoleChangeModelOpen: true });
  closeRoleChangeModel = () => this.setState({ isRoleChangeModelOpen: false });
  openRemoveUserModel = () => this.setState({ isUserRemoveModelOpen: true });
  closeRemoveUserModel = () => this.setState({ isUserRemoveModelOpen: false });

  async Logout() {
    // e.preventDefault()
    try {
      const logoutStatus = await Logout()
      if (logoutStatus) {
        return this.props.history.push('/')
      }
    } catch (ex) {
      // swal('Signed Out Error', 'error')
    }
  }

  setAssesment = (Assessment) => {
    this.setState({
      currentAssesment: Assessment
    })
  }

  setCurrentRole = async (value) => {
    this.setState({
      selectedRole: value
    })
  }

  async changeRole() {
    try {
      var payload = this.state.selectedUser
      payload.role = this.state.selectedRole
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      let changeRoleResult = await ApiAuth(
        HTTPMETHODS.PUT,
        `/user/list/group`,
        payload,
        false,
        userObj.email,
        userObj.organizationId
      )
      this.setState({
        changeRoleResult: changeRoleResult,
        isRoleChangeModelOpen: false
      })
      this.componentDidMount()
      if((changeRoleResult.data.role == 'Member' )&&(this.state.selectedUser.email == userObj.email) )
      {
        this.props.history.push('/dashboard')
      }

    } catch (e) {
      console.log('error in change role update', e.message)
    }
  }
  async removeMemberConfirm(value) {
    try {
      const removeUserEmail = value.email
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const deleteFileInfo = await ApiAuth(
        HTTPMETHODS.DELETE,
        `/user/${removeUserEmail}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      this.setState({
        isUserRemoveModelOpen: false
      })
      if(removeUserEmail == userObj.email && deleteFileInfo) 
      {
        this.Logout()
      }
      this.getUserGroupList(userObj)
    } catch (e) {
      console.log('error in change role update', e.message)
    }
  }

  removeMember = async (userValue) => {
    this.setState({
      deleteUserPopupVisible:false
    })
    
    const userValues = userValue
    
    swal({
      title: 'Are you sure?',
      buttons: ['No', 'Yes'],
      icon: 'warning',
      text: 'Once deleted, you will not be able to recover!',
    }).then(async (value) => {
      if (value) {
        this.removeMemberConfirm(userValues)
      } 
      this.setState({
        deleteUserPopupVisible:true
      })
    })
  }

  getUserGroupList = async (userObj) => {
    try {
      const listUsers = await ApiAuth(
        HTTPMETHODS.GET,
        `/user/list/group`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (listUsers.data !== null) {
        await Promise.all(
          listUsers.data.map(async (item, index) => {
            if (item.role == 'admin' || item.role == 'Admin') {
              if(item.recStatus == 'active'||item.recStatus == 'Active')
                {
                  this.setState({
                    adminCount: this.state.adminCount + 1
                  })
                 item.role = 'Admin'
                }
            }
            if (item.role == 'member' || item.role == 'Member') {
              item.role = 'Member'
            }
            var oldDate = item.createdDate.split(' ')[0]
            var mm = oldDate.split('-')[1]
            var dd = oldDate.split('-')[2]
            var yy = oldDate.split('-')[0]
            var newDate = mm + '/' + dd + '/' + yy
            var newTime = item.createdDate.split(' ')[1].split('.')[0]
            item.createdDate = newDate //+ ' ' + newTime
            let newItem = item
            return newItem
          })
        )
        const filteredlistUsers = listUsers.data.filter((item) => item.recStatus == 'active');
        userData = filteredlistUsers
        this.setState({
          usersListData:filteredlistUsers
        })

      }
    } catch (error) {
      console.log("error", error)

    }
  }


  render() {

    const colsAssessment = [
      {
        title: '#',
        dataIndex: 'id',
        render: (value, item, index) => index + 1
      }, {
        title: 'User Name',
        dataIndex: 'firstName',
      },
      {
        title: 'Role',
        dataIndex: 'role',
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, actions, value, item, index) =>
          <>
            <div class="dropdown">
              <Button
                className="btn-sm btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                // disabled={this.state.adminCount <= 1 ||
                //   this.userObj.email == actions.email ||
                //   (this.state.adminCount <= 1 && actions.role != 'Member')}
              >
                <b>&nbsp;&nbsp;...</b>
              </Button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  type="button"
                  onClick={this.openRoleChangeModel.bind(this)}
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#inviteModalCenter1"
                  disabled={this.state.adminCount <= 1 &&
                    this.userObj.email == actions.email 
                   }>
                  Change Role
                  </button>

                <button
                  type="button"
                  onClick={this.openRemoveUserModel.bind(this)}
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#inviteModalCenter2"
                  disabled={this.state.adminCount <= 1 ||
                    this.userObj.email == actions.email ||
                    (this.state.adminCount <= 1 && actions.role != 'Member')}
                  >
                  Remove From Organization
                  </button>
              </div>
            </div>
          </>
      },
    ]

    return (
      <>
        <Modal centered show={this.state.isRoleChangeModelOpen} onHide={this.closeRoleChangeModel.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>
              Change the role of {this.state.selectedUser ? this.state.selectedUser.firstName : null}?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div class="form-check">
                <input className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="Admin"
                  checked={"Admin" || "admin" === this.state.selectedRole}
                  onClick={(e) => this.setCurrentRole(e.target.value)} />
                <label className="form-check-label" for="flexRadioDefault1">
                  <b>Admin</b>
                </label><br />
                <label className="form-check-label" for="flexRadioDefault1">
                  Has full administrative access to the entire organization.
                    </label>
              </div> <br />
              <div class="form-check">
                <input className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="Member"
                  checked={"Member" === this.state.selectedRole}
                  onClick={(e) => this.setCurrentRole(e.target.value)}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  <b>Member</b>
                </label><br />
                <label className="form-check-label" for="flexRadioDefault1">
                  Can create new assessments and generate reports.
                    </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{
            display: "flex",
            justifyContent: "center",
          }} >
            <Button variant="primary" centered onClick={this.changeRole.bind(this)} >
              Change Role
              </Button>
          </Modal.Footer>
        </Modal>


        {this.state.deleteUserPopupVisible?<Modal centered show={this.state.isUserRemoveModelOpen} onHide={this.closeRemoveUserModel.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>
              Removing 1 Member From Organization
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div class="modal-body">
                The Following Member will be removed.
                </div>
              <div class="modal-body">
                {this.state.selectedUser ? this.state.selectedUser.firstName : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{
            display: "flex",
            justifyContent: "center",
          }} >
            <Button variant="primary" centered onClick={this.removeMember.bind(this, this.state.selectedUser)}>
              Remove Members
            </Button>
          </Modal.Footer>
        </Modal>:null}
        <div
          className="btn_sec mb-2"
          style={{
            width: '100%',
            textAlign: 'left',
            display: 'inline-block',
          }}
        >
          <Table
            size="small"
            bordered
            rowKey="index"
            key="id"
            onRow={(record, rowIndex) => {
              return {
                onMouseOver: event => {
                  if (record != this.state.selectedUser) {
                    let currentState = this.state.selectedUser
                    let newValue = record
                    let isEqualCheck = _.isEqual(currentState, newValue)
                    if (!isEqualCheck) {
                      this.setState({
                        selectedUser: newValue,
                        selectedRole: newValue.role
                      })
                    }
                  }

                },
                onDoubleClick: event => { }, // double click row
                onContextMenu: event => { }, // right button click row
                onMouseEnter: event => { }, // mouse enter row
                onMouseLeave: event => { }, // mouse leave row
              };
            }}
            // rowSelection={onRow}
            columns={colsAssessment}
            dataSource={this.state.usersListData}
            //{this.props.usersList}
            pagination={false}
          />
        </div>
      </>
    )
  }
}

export default withRouter(ManageUserTable)
