import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'
import AssessmentTable from './summaryTable'
import Breadcrumb from '../core/breadcrumb'
import { Button, Dropdown, InputGroup, Modal,FormControl } from 'react-bootstrap'
import { Card } from 'antd'
import GapReport from '../Dashboard/gapReport'
import MyDocument from './myDocument'
import { PDFDownloadLink } from '@react-pdf/renderer'
import ResponseCountReport from '../Dashboard/responseCountReport'
import ResponsePercentageReport from '../Dashboard/responsePercentageReport'
import { bcAssessmentSummary } from '../core/breadcrumbpaths'
import swal from 'sweetalert'
import _ from 'lodash'

class Summary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      assessments: null,
      selectedRowKey: null,
      orgName: null,
      isFreeOrg: true,
      cmmcVersion: null,
      cmmcLevel: null,
      score: 0,
      baseURL1: null,
      baseURL2: null,
      baseURL3: null,
      gapResponseData: [],
      isAnswered: null,
      showOnDeleteConfirmation: _.cloneDeep(false),
      disableDeleteConfirmation: _.cloneDeep(false)
    }
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onAddAssessment = this.onAddAssessment.bind(this)
    this.onEditAssessment = this.onEditAssessment.bind(this)
    this.onDeleteAssessment = this.onDeleteAssessment.bind(this)
    this.onViewAssessment = this.onViewAssessment.bind(this)
    this.onGenerateReport = this.onGenerateReport.bind(this)
    this.handleBaseURL = this.handleBaseURL.bind(this)
    this.closeRemoveUserModel = this.closeRemoveUserModel.bind(this) 

  }
  componentDidMount = async () => {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'))
    this.getAssessments(this.userObj)
    this.getOrgInfo(this.userObj)
    this.getCmmcVersion(this.userObj)
    this.getComplianceScore(this.userObj)    
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(this.state.assessments) !==
      JSON.stringify(nextState.assessments) ||
      this.state.selectedRowKey !== nextState.selectedRowKey ||
      this.state.baseURL1 !== nextState.baseURL1 ||
      this.state.baseURL2 !== nextState.baseURL2 ||
      this.state.baseURL3 !== nextState.baseURL3 ||
      this.state.showOnDeleteConfirmation !== nextState.showOnDeleteConfirmation||
      this.state.disableDeleteConfirmation !== nextState.disableDeleteConfirmation
    )
      return true
    return false
  }
  getAssessments = async (userObj) => {
    try {
      const lstAssessments = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (lstAssessments.data !== null) {
        await Promise.all(
          lstAssessments.data.map(async (item) => {
            /*  if (item.name.length > 20) {
               item.name = item.name.substring(0, 20)
             } */
            var oldDate = item.updatedDate.split(' ')[0]
            var mm = oldDate.split('-')[1]
            var dd = oldDate.split('-')[2]
            var yy = oldDate.split('-')[0]
            var newDate = mm + '/' + dd + '/' + yy
            var newTime = item.updatedDate.split(' ')[1].split('.')[0]
            item.updatedDate = newDate + ' ' + newTime
            let newItem = item
            try {
              let responseCountData = await ApiAuth(
                HTTPMETHODS.GET,
                `/response/count?assessId=${item.id}&level=${item.cmmcLevel}`,
                null,
                false,
                userObj.email,
                userObj.organizationId
              )
              let totalQuestions = responseCountData.data.totalQuestions
              let answeredQuestion = totalQuestions - responseCountData.data.notAnsweredTotal
              let notAnsweredTotal = responseCountData.data.notAnsweredTotal
              if (totalQuestions === notAnsweredTotal) {
                newItem.processStatus = 'Created';
              }
              else if (notAnsweredTotal === 0) {
                newItem.processStatus = 'Completed';
              }
              else if (notAnsweredTotal > 0) {
                newItem.processStatus = 'In Progress';
              }

              newItem.questionCount = answeredQuestion + ' / ' + totalQuestions
              newItem.isAnswered = answeredQuestion === totalQuestions
            } catch (error) {
              console.log(error.message)
            }
            return newItem
          })
        )
        this.setState({ assessments: lstAssessments.data })
      }
    } catch (error) {
      console.log(error)
      this.setState({ assessments: null })
      this.setState({ error_message: error.message })
    }
  }
  getOrgInfo = async (userObj) => {
    try {
      const resOrg = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const isFreeOrg = resOrg.data.isFreeOrg
      const orgName = resOrg.data.name
      this.setState({ orgName })
      this.setState({ isFreeOrg })
    } catch (error) {
      console.log(error.message)
    }
  }
  getCmmcVersion = async (userObj) => {
    try {
      const cmmcVersion = await ApiAuth(
        HTTPMETHODS.GET,
        '/cmmc/version',
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      this.setState({ cmmcVersion: cmmcVersion.data.toFixed(1) })
    } catch (error) {
      console.log(error.message)
    }
  }
  getComplianceScore = async (userObj) => {
    try {
      const score = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment/score?orgId=${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      this.setState({ score: parseInt(score.data) })
    } catch (error) {
      console.log(error.message)
    }
  }
  onSelectChange = async (key, row, level, isAnswered) => {
    if (this.state.isFreeOrg && this.state.isAnswered)
      document.getElementById('generateReport1').disabled = true
    this.setState({
      selectedRowKey: key,
      cmmcLevel: level,
      isAnswered: isAnswered,
      selectedRow: row,
    })
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    try {
      let gapResponseData = await ApiAuth(
        HTTPMETHODS.GET,
        `/response/actionPlanPriority?assessId=${key[0]}&level=${level}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      gapResponseData = gapResponseData.data.sort(function (a, b) {
        if (a.practiceCode > b.practiceCode) {
          return 1
        }
        if (a.practiceCode < b.practiceCode) {
          return -1
        }
        return 0
      })
      gapResponseData = gapResponseData.filter((item) => item.isFree === true)
      this.setState({ gapResponseData })
    } catch (error) {
      console.log(error.message)
    }
  }
  onAddAssessment = () => {
    this.props.history.push('/assessment/create')
  }
  onEditAssessment = async () => {
    const { selectedRowKey, cmmcLevel } = this.state
    if (!selectedRowKey) {
      swal('You need to select one of the assessments')
      return
    }
    this.props.history.push(
      '/assessment/edit/' + selectedRowKey[0] + '/' + cmmcLevel
    )
  }
  onDeleteAssessment = () => {
    const { selectedRowKey } = this.state
    if (!selectedRowKey) {
      swal('You need to select one of the assessments')
      return
    }
    else {
      if(this.userObj.role === 'Member'|| this.userObj.role === 'member')
      {
        this.setState ({
          showOnDeleteConfirmation: _.cloneDeep(false)
        })
        swal('Members can not delete the assessments')
        return
      }
      else {
        this.setState ({
        showOnDeleteConfirmation: _.cloneDeep(true)
      })
      }
    }
  }
  closeRemoveUserModel = async () => {
    this.setState({
      showOnDeleteConfirmation: _.cloneDeep(false)
    })
  }
  onDeleteConfirmation = async () => {
    const { selectedRowKey } = this.state
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    try {
      const lstAssessment = await ApiAuth(
        HTTPMETHODS.DELETE,
        `/assessment?assessId=${selectedRowKey}&orgId=${encodeURIComponent(
          userObj.organizationId
        )}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (lstAssessment.data !== null) {
        this.setState({ 
          assessments: null,
          showOnDeleteConfirmation: _.cloneDeep(false)
        })
      }
    } catch (error) {
      console.log(error)
      this.setState({ error_message: error.message })
    }
  }
  onViewAssessment = () => {
    this.props.history.push('/assessment/create')
  }
  pushMessageToSQS = async (userObj, assessmentId, cmmcLevel) => {
    try {
      const resOrg = await ApiAuth(
        HTTPMETHODS.POST,
        `/assessment/report?assessmentId=${assessmentId}&assessmentLevel=${cmmcLevel}&reportType=SSP`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      // console.log('pushMessageToSQS', resOrg);
      // const isFreeOrg = resOrg.data.isFreeOrg
      // const orgName = resOrg.data.name
      // this.setState({ orgName })
      // this.setState({ isFreeOrg })
    } catch (error) {
      console.log(error.message)
    }
  }
  onGenerateReport = async () => {
    const { selectedRowKey, isFreeOrg, isAnswered } = this.state
    if (!selectedRowKey) {
      swal('You need to select one of the assessments')
      return
    }
    if (!isAnswered && isFreeOrg) {
      swal('Respond to all the questions before generating Gap Analysis report')
      return
    }
    if (!isAnswered && !isFreeOrg) {
      swal(
        'Respond to all the questions before generating System Security Plan'
      )
      return
    }
    if (isFreeOrg) {
      //  swal(
      //     'The Gap Analysis Report will be emailed to your business email account'
      //   )
    } else {
      swal(
        'Your report generation is in progress... Please check Reports tab after few minutes'
      )
      this.pushMessageToSQS(this.userObj, this.state.assessments[0].id, this.state.cmmcLevel);
    }
  }
  handleInputValuePermenentlyDelete = async (e) => {
    let str = e.target.value
    let compare = 'permanently delete'
      if(str === compare )
        {  
          this.setState({
            disableDeleteConfirmation: _.cloneDeep(true)
          })
        }
      else {
        this.setState({
          disableDeleteConfirmation: _.cloneDeep(false)
        })
      }
  } 

  handleBaseURL = (index, val) => {
    if (index === 1 && val) this.setState({ baseURL1: val })
    else if (index === 2 && val) this.setState({ baseURL2: val })
    else if (index === 3 && val) this.setState({ baseURL3: val })
  }
  render() {
    const {
      assessments,
      selectedRowKey,
      isAnswered,
      cmmcLevel,
      orgName,
      isFreeOrg,
      cmmcVersion,
      score,
      baseURL1,
      baseURL2,
      baseURL3,
      gapResponseData,
    } = this.state
    if (
      isFreeOrg &&
      selectedRowKey &&
      isAnswered &&
      baseURL1 &&
      baseURL2 &&
      baseURL3 &&
      gapResponseData
    ) {
      setTimeout(function () {
        document.getElementById('generateReport').disabled = false
      }, 1200)
    }
    return (
      <div
        className="content-wrapper"
        style={{
          width: '100%',
          height: '100%',
          marginLeft: '0px',
          overflow: 'hidden',
        }}
      >
        {this.state.showOnDeleteConfirmation == 1 ? 
        <Modal centered 
        show={this.state.showOnDeleteConfirmation} 
        onHide={this.closeRemoveUserModel.bind(this)}>
          <Modal.Body>
            <div>
              <div class="modal-body">
                <div>
                  <h5>
                    Do you want to permanently delete {this.state.selectedRow[0].name}?
                  </h5>
                  <span>(Please note that deleting assessment will delete all the uploaded content and files.)</span>
                </div>
              </div>
              <div class="modal-body">
                <div>
                  To confirm deletion, type <i>permanently delete</i> in the input field
                    </div>
                <div>
                  <div>
                     <InputGroup className="mt-2">
                      <FormControl
                         type="text"
                         name="text"
                         className="pl-3 pb-1"
                         placeholder="permanently delete"
                         aria-label="permanently delete"
                         value={this.state.inputValuePermenentlyDelete}
                         onChange={this.handleInputValuePermenentlyDelete.bind(this)}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{
            display: "flex",
            justifyContent: "center",
            marginTop:"-25px",
            border: "none"
          }} >
            <Button variant="primary" centered 
             onClick={this.closeRemoveUserModel.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" centered 
            disabled={!this.state.disableDeleteConfirmation==true} 
            onClick={this.onDeleteConfirmation.bind(this)}> 
              Delete
            </Button>
          </Modal.Footer>
        </Modal> : null}
        <Breadcrumb pathsObject={bcAssessmentSummary} />
        <section className="content">
          <div className="container-fluid">
            <Card className="card" bordered={false}>
              <div
                className="btn_sec mb-2"
                style={{
                  width: '100%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                {isFreeOrg &&
                  selectedRowKey &&
                  isAnswered &&
                  baseURL1 &&
                  baseURL2 &&
                  baseURL3 &&
                  gapResponseData ? (
                  <>
                    <PDFDownloadLink
                      document={
                        <MyDocument
                          selectedRowKey={selectedRowKey[0]}
                          orgName={orgName}
                          cmmcVersion={cmmcVersion}
                          cmmcLevel={cmmcLevel}
                          score={score}
                          baseURL1={baseURL1}
                          baseURL2={baseURL2}
                          baseURL3={baseURL3}
                          gapResponseData={gapResponseData}
                        />
                      }
                      fileName="CMMC Gap Analysis Report.pdf"
                    >
                      <button
                        id="generateReport"
                        type="submit"
                        className="btn btn-sm btn-primary mr-2 btn_table"
                        disabled
                        onClick={this.onGenerateReport}
                      ><i className="far fa-file-alt"></i>
                        Generate Report
                      </button>
                    </PDFDownloadLink>
                  </>
                ) : (
                  <button
                    id="generateReport1"
                    type="submit"
                    className="btn btn-sm btn-primary mr-2 btn_table"
                    disabled={this.state.assessments == null}
                    onClick={this.onGenerateReport}
                  ><i className="far fa-file-alt"></i>
                    Generate Report
                  </button>
                )}
                {assessments && (
                  <>
                    <button
                      id="view_btn"
                      type="submit"
                      className="mr-2 btn-sm btn btn-secondary btn_table"
                      onClick={this.onViewAssessment}
                      style={{ display: 'none' }}
                    ><i className="fas fa-eye"></i>
                      View Details
                    </button>
                    <button
                      id="edit_btn"
                      type="submit"
                      className="btn btn-sm btn-success mr-2 btn_table"
                      onClick={this.onEditAssessment}
                    ><i className="fas fa-pencil-alt"></i>
                      Edit
                    </button>
                    <button
                      id="delete_btn"
                      type="submit"
                      className="btn btn-sm btn-danger mr-2 btn_table"
                      onClick={this.onDeleteAssessment}
                    ><i className="far fa-trash-alt"></i>
                      Delete
                    </button>
                  </>
                )}
                <button
                  type="submit"
                  className="btn-sm btn btn-secondary btn_table"
                  onClick={this.onAddAssessment}
                ><i className="far fa-edit"></i>
                  Create Assessment
                </button>
              </div>
              {assessments && (
                <div className="mb-2">
                  <div style={{ width: '100%' }}>
                    <AssessmentTable
                      onSelectChange={this.onSelectChange}
                      assessments={assessments}
                    />
                  </div>
                </div>
              )}
            </Card>
            {selectedRowKey && isAnswered && (
              <>
                <div style={{ marginLeft: '5000px' }}>
                  <ResponseCountReport
                    handleBaseURL={this.handleBaseURL}
                    assessId={selectedRowKey[0]}
                    level={cmmcLevel}
                    width={800}
                    height={320}
                  />
                </div>
                <div style={{ display: 'none' }}>
                  <ResponsePercentageReport
                    handleBaseURL={this.handleBaseURL}
                    assessId={selectedRowKey[0]}
                    level={cmmcLevel}
                  // width={500}
                  // height={200}
                  />
                  <GapReport
                    handleBaseURL={this.handleBaseURL}
                  // width={600}
                  // height={250}
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    )
  }
}

// export issue rectified
export default Summary