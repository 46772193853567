import React, { Component } from 'react'
import './footer.css'

var siteURL = "https://securitycompliance.io"
var siteQAURL = "https://qa.securitycompliance.io"
var siteDEVURL = "https://dev.securitycompliance.io"

var sitePolicy = "https://www.securitycompliance.io/policy"
var siteQAPolicy = "https://qa.securitycompliance.io/policy"
var siteDEVPolicy = "https://dev.securitycompliance.io/policy"

var siteTerms = "https://www.securitycompliance.io/terms"
var siteQATerms = "https://qa.securitycompliance.io/terms"
var siteDEVTerms = "https://dev.securitycompliance.io/terms"


class Footer extends Component {
  render() {
    return (
      <div className="main-footer footer-bar" style={{ marginLeft: '0' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 text-left">	<p> Powered by InterSec, Inc.</p>
            </div>
            <div className="col-4 text-center">	<p>  <strong className="footer-text">
              {process.env.REACT_APP_API_AUTH_URL === 'https://api.securitycompliance.io' ?
                <a href={siteURL}>Security Compliance © 2021</a> : null}
              {process.env.REACT_APP_API_AUTH_URL === 'https://api.dev.securitycompliance.io' ?
                <a href={siteDEVURL}>Security Compliance © 2021</a> : null}
              {process.env.REACT_APP_API_AUTH_URL === 'https://api.qa.securitycompliance.io' ?
                <a href={siteQAURL}>Security Compliance © 2021</a> : null}
          .
        </strong>{' '}
        All rights reserved.</p>
            </div>
            <div className="col-4 text-right"><ul>
              <li>
                {process.env.REACT_APP_API_AUTH_URL === 'https://api.securitycompliance.io' ?
                  <a href={sitePolicy}>Privacy Policy</a> : null}
                {process.env.REACT_APP_API_AUTH_URL === 'https://api.dev.securitycompliance.io' ?
                  <a href={siteDEVPolicy}>Privacy Policy</a> : null}
                {process.env.REACT_APP_API_AUTH_URL === 'https://api.qa.securitycompliance.io' ?
                  <a href={siteQAPolicy}>Privacy Policy</a> : null}
              </li>
              <li>
                {process.env.REACT_APP_API_AUTH_URL === 'https://api.securitycompliance.io' ?
                  <a href={siteTerms}>Terms &amp; Conditions</a> : null}
                {process.env.REACT_APP_API_AUTH_URL === 'https://api.dev.securitycompliance.io' ?
                  <a href={siteDEVTerms}>Terms &amp; Conditions</a> : null}
                {process.env.REACT_APP_API_AUTH_URL === 'https://api.qa.securitycompliance.io' ?
                  <a href={siteQATerms}>Terms &amp; Conditions</a> : null}
              </li>
              <li><a href="https://intersecinc.com/contact-us">Contact Us</a></li></ul></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
