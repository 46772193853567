import './inviteuserregister.css'

import * as Yup from 'yup'
import { ApiAuth, HTTPMETHODS, ApiAuthInitialState } from '../core/api'
import React, { Component } from 'react'
import { Formik } from 'formik'
import swal from 'sweetalert'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { withRouter } from 'react-router-dom';

const nameRegex = /^[0-9a-zA-Z ,.]*$/i
const phoneRegExp = /^(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/
const password_lowercase_Regex = /^(?=.*[a-z]).*$/
const password_uppercase_Regex = /^(?=.*[A-Z]).*$/
const password_special_Regex = /^(?=.*[@#$%^&+=]).*$/
// const email_common = /^(?=.*[A-Z a-z +])(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho|@mail|@yopmail|@hubspot|@pepipost|@protonmail|@yandex|@tutanota|@facebook))/
let email_common = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@iCloud|@Zoho|@mail|@yopmail|@hubspot|@pepipost|@protonmail|@yandex|@tutanota|@facebook))/
const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      nameRegex,
      'First Name should not contain any Non-Alpha characters'
    )
    .min(2, 'First Name is Too Short!')
    .max(25, 'First Name is Too Long!')
    .required('First Name is Required'),
  lastName: Yup.string()
    .matches(nameRegex, 'Last Name should not contain any Non-Alpha characters')
    .min(2, 'Last Name is Too Short!')
    .max(25, 'Last Name is Too Long!')
    .required('Last Name is Required'),
  email:
    process.env.REACT_APP_API_AUTH_URL === 'https://api.securitycompliance.io'
      ? Yup.string()
        .email()
        .matches(
          email_common,
          'Email Address cannot be in one of the common Email Domains'
        )
        .matches(/^[^+]*$/, 'Email should not contain +')
        .min(7, 'Email is Too Short!')
        .max(100, 'Email is Too Long!')
        .required('Email is Required')
      : Yup.string()
        .email()
        .matches(
          email_common,
          'Email Address cannot be in one of the common Email Domains'
        )
        .min(7, 'Email is Too Short!')
        .max(100, 'Email is Too Long!')
        .required('Email is Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone Number is Required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password has to be at least 8 characters in length!')
    .matches(
      password_lowercase_Regex,
      'Password should contain at least 1 LowerCase Character!'
    )
    .matches(
      password_uppercase_Regex,
      'Password should contain at least 1 UpperCase Character!'
    )
    .matches(
      password_special_Regex,
      'Password should contain at least 1 Special (@#$%^&+=) Character!'
    ),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Accept Terms & Conditions is required'
  ),
})

class InviteUserRegister extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      name: '',
      companyName: '',
      orgId: '',
      cmmcLevel: '',
      timeframe: '',
      invitationFrom: ''
    }
  }

  componentDidMount = async () => {
    let url = window.location.href
    let parsedUrl = url.split('?')
    // let encoded = base64_encode('YOUR_DECODED_STRING');
    let decoded = base64_decode(parsedUrl[1]);
    const urlParams = new URLSearchParams(decoded);
    const orgId = urlParams.get('orgId')
    const companyName = urlParams.get('companyName')
    const invitationFrom = urlParams.get('invitationFrom')
    this.setState({
      companyName: companyName,
      orgId: orgId,
      invitationFrom: invitationFrom
    })
  }

  submitForm = async (values, history) => {
    let value = values
    value.companyName = this.state.companyName
    //Let's Create an Organization first. IF it succeeds, we will create an user.
    let userCreationResponse = await this.createUser(value)
    if (userCreationResponse && userCreationResponse !== null) {
      // swal('Success', 'User Created Successfully!', 'success')
      let encoded = base64_encode(values.password);
      localStorage.setItem('registereduserDetailKey', JSON.stringify(encoded))

      // let decoded = base64_decode(parsedUrl[1]);
      let registereduserDetail = values
      delete registereduserDetail['password']
      registereduserDetail.companyName = this.state.companyName
      registereduserDetail.orgId = this.state.orgId
      localStorage.setItem('registereduserDetail', JSON.stringify(registereduserDetail))
      history.push('/register/confirminviteuser')
    } else {
      swal('Error!', 'Error in Creating the User', 'error')
    }
  }

  createUser = async (values) => {
    try {
    const payload = {
      username: values.email,
      password: values.password,
      UserAttributes: [{
        'Name': 'firstName',
        'Value': values.firstName,
      },
      {
        'Name': 'lastName',
        'Value': values.lastName
      },
      {
        'Name': 'email',
        'Value': values.email
      },
      {
        'Name': 'invitationFrom',
        'Value': this.state.invitationFrom
      },
      {
        'Name': 'organisationId',
        'Value': this.state.orgId
      }
      ],
      "ValidationData": null
    }

    const userReturnObj = await ApiAuthInitialState(
      HTTPMETHODS.POST,
      `/register`,
      payload,
      false,
      values.email
    )
    if (userReturnObj.status === 200) {
      return userReturnObj
    }
   }
   catch (error) {
        swal('Error!', 'Error in register ')
        console.log('Error in register', error)
      }
  }

  showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label align-right">
            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
            First Name </label>
          <div className="col-sm-6">
            <input
              type="text"
              name="firstName"
              className={
                errors.firstName && touched.firstName
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.firstName}
              onChange={handleChange}
            />
            {errors.firstName && touched.firstName ? (
              <small className="text-danger">{errors.firstName}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label align-right">
            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
            Last Name </label>
          <div className="col-sm-6">
            <input
              type="text"
              name="lastName"
              className={
                errors.lastName && touched.lastName
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.lastName}
              onChange={handleChange}
            />
            {errors.lastName && touched.lastName ? (
              <small className="text-danger">{errors.lastName}</small>
            ) : null}
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label align-right">
            <span style={{ color: 'red', paddingRight: '5px' }}>&nbsp;</span>

            Company Name
          </label>
          <div className="col-sm-6">
            <label className="col-sm-4 col-form-label ml2">
              {this.state.companyName}
            </label>
          </div>
        </div>
        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label align-right">
            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
           Business Phone Number
          </label>
          <div className="col-sm-6">
            <input
              type="phone"
              name="phone"
              className={
                errors.phone && touched.phone
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              maxLength="14"
              value={values.phone}
              onChange={handleChange}
              onKeyUp={(e) => {
                values.phone = e.target.value.replace(
                  /(\d{3})-?(\d{3})-?(\d{4})/,
                  '($1)-$2-$3'
                )
              }}
            />
            {errors.phone && touched.phone ? (
              <small className="text-danger">{errors.phone}</small>
            ) : null}
          </div>
        </div>

        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label align-right">
            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
            Login Email ID
          </label>
          <div className="col-sm-6">
            <input
              type="email"
              name="email"
              placeholder="name@example.com"
              className={
                errors.email && touched.email
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && touched.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : null}
          </div>
        </div>

        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label align-right">
            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
            Password
          </label>
          <div className="col-sm-6">
            <input
              type="password"
              name="password"
              className={
                errors.phone && touched.phone
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              value={values.password}
              onChange={handleChange}
            />
            {errors.password && touched.password ? (
              <small className="text-danger">{errors.password}</small>
            ) : null}
          </div>
        </div>

        <div className="form-group has-feedback row">
          <label className="col-sm-4 col-form-label align-right">
            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
            Terms and Conditions
          </label>
          <div className="col-sm-6 term-label checkbox_terms">
            <input
              type="checkbox"
              name="acceptTerms"
              className={
                errors.acceptTerms && touched.acceptTerms ? 'is-invalid' : ''
              }
              value={values.acceptTerms}
              onChange={handleChange}
            />

            &nbsp;
            <b className='pt-2 ml-2'>
              By registering, You agree to the{' '}
              <a href="/terms" target="_blank" className="terms_sec">
                Security Compliance Terms and Conditions
              </a>
              <br />
              <div style={{ marginLeft: '-5%' }}>
                {errors.acceptTerms && touched.acceptTerms ? (
                  <small className="text-danger">{errors.acceptTerms}</small>
                ) : null}
              </div>
            </b>
            <br />

          </div>
        </div>

        <div className="form-group has-feedback row col_full">
          <div className="col-sm-12" />
          <div className="col-sm-12 checkbox_terms_para mr-2">
            <p>
              By completing this form, I agree that i'd like to receive
              information from Security Compliance and its affiliates related to
              their services, events, and any special offers by email, post, or
              other forms of communication.
            </p>
            <p>
              You may unsubscribe at any time by following the instructions in
              the communications received. Your information will be handled in
              accordance with the Security Compliance Privacy Notice.
            </p>
          </div>
        </div>


        <div className="row">
          <div className="col-sm-4" />
          <div className="col-sm-3 noBorder">
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary btn-rounded btn-block"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <div className="login-page">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h3 className="register-box-msg">Register Now</h3>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  companyName: this.state.companyName,
                  phone: '',
                  email: '',
                  password: '',
                  acceptTerms: false
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.submitForm(values, this.props.history)
                  setSubmitting(false)
                }}
                validationSchema={registerSchema}
              >
                {(props) => this.showForm(props)}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(InviteUserRegister)
