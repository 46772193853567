import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { Table } from 'antd'

const colsAssessment = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name, record) => renderName(name, record),
  },
  {
    title: 'User',
    dataIndex: 'userName',
  },
  {
    title: 'Level',
    dataIndex: 'cmmcLevel',
  },
  {
    title: 'Planned Start Date',
    dataIndex: 'plannedStartDate',
    render: (plannedStartDate) => renderplannedStartAndEndDate(plannedStartDate),
  },
  {
    title: 'Planned End Date',
    dataIndex: 'plannedEndDate',
    render: (plannedEndDate) => renderplannedStartAndEndDate(plannedEndDate),
  },
  {
    title: 'Status',
    dataIndex: 'processStatus',
  },
  {
    title: 'Questions answered',
    dataIndex: 'questionCount',
  },
  {
    title: 'Last updated',
    dataIndex: 'updatedDate',
  },
]

const renderplannedStartAndEndDate = (renderplannedStartDate) => {
  var newDate = ''
  if(renderplannedStartDate){
    var oldDate = renderplannedStartDate.split('T')[0]
    var mm = oldDate.split('-')[1]
    var dd = oldDate.split('-')[2]
    var yy = oldDate.split('-')[0]
    newDate = mm + '/' + dd + '/' + yy
  }
  return(
    <>{newDate?
    <div>
      {newDate?newDate:'-'}
    </div>:
     <div style={{marginLeft:'50%',fontWeight:'bold'}}>
      {newDate?newDate:'-'}
    </div>}
    </>
  )
}

const renderName = (name, record) => {
  const { id: assessmentId, cmmcLevel: assessmentLevel } = record

  return (
    <>
      <Link
        to={{
          pathname: `/assessment/${assessmentId}/${assessmentLevel}`,
          state: {
            assessmentId: record.id,
            assessmentName: record.name,
            assessmentLevel: record.cmmcLevel,
          },
        }}
      >
        {name}
      </Link>
    </>
  )
}

export default class SummaryTable extends Component {
  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.onSelectChange(
          selectedRowKeys,selectedRows,
          selectedRows[0].cmmcLevel,
          selectedRows[0].isAnswered
        )
      },
      type: 'radio',
    }
    return (
      <Table
        size="small"
        bordered
        rowKey="id"
        rowSelection={rowSelection}
        columns={colsAssessment}
        dataSource={this.props.assessments}
      />
    )
  }
}
