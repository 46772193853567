import React, { Component } from 'react'
import { Button, Dropdown, DropdownButton, Modal ,Form} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { ApiAuth, HTTPMETHODS } from '../core/api'

export default class documentRepositoryTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tableData: null,
      currentAssesment: null,
    }
  }

  componentDidMount() {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'))

    this.setState({
      tableData: null,
      currentAssesment: this.props.assessmentId ? this.props.assessmentId[0].id : null,
    })
    this.initFileDownloadCount(this.userObj)
  }

  initFileDownloadCount = () => {
    setTimeout(() => {
      this.getFileDownloadCount(this.userObj)
    }, 1900);
  }

  setAssesment = (Assessment) => {
    this.setState({
      currentAssesment: Assessment
    })
  }

  getFileDownloadByOrgnizationId = async (userObj, domainAbbreviation) => {
    try {
      const assessmentId = this.props.assessments[0].id
      if (assessmentId) {
        const responseDownloadURL = await ApiAuth(
          HTTPMETHODS.POST,
          `/repository/download/${assessmentId}/${domainAbbreviation.actions}`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        )
        window.open(responseDownloadURL.data, "_blank")
      }

    } catch (error) {
      console.log(error.message)
    }
  }

  getFileDownloadCount = async (userObj) => {
    try {
      const assessmentId = await this.props.assessments[0].id
      const domainLevel = 1
      const remainingFileList = await ApiAuth(
        HTTPMETHODS.POST,
        `/repository/downloadCount/${assessmentId}/${domainLevel}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      this.setState({
        tableData: [{ "domainname": "Access Control (AC)", "totalfiles": remainingFileList.data.AC ? remainingFileList.data.AC : 0, "actions": "AC" },
        { "domainname": "Identification and Authentication (IA)", "totalfiles": remainingFileList.data.IA ? remainingFileList.data.IA : 0, "actions": "IA" },
        { "domainname": "Media Protection (MP)", "totalfiles": remainingFileList.data.MP ? remainingFileList.data.MP : 0, "actions": "MP" },
        { "domainname": "Physical Protection (PE)", "totalfiles": remainingFileList.data.PE ? remainingFileList.data.PE : 0, "actions": "PE" },
        { "domainname": "System and Communication Protection (SC)", "totalfiles": remainingFileList.data.SC ? remainingFileList.data.SC : 0, "actions": "SC" },
        { "domainname": "System and Information Integrity (SI)", "totalfiles": remainingFileList.data.SI ? remainingFileList.data.SI : 0, "actions": "SI" }]
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  render() {
    const colsAssessment = [
      {
        title: '#',
        dataIndex: 'domainname',
        render: (value, item, index) => index + 1
      }, {
        title: 'Domain Name',
        dataIndex: 'domainname',
      },
      {
        title: 'Total Files',
        dataIndex: 'totalfiles',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, actions) =>
          <Button className="btn-sm btn btn_table mb-2 mr-2"
            disabled={actions.totalfiles<1}
            onClick={() => {
              const userObj = JSON.parse(localStorage.getItem('userInfo'))
              this.getFileDownloadByOrgnizationId(userObj, actions)
            }}
          >
            Download Attachments 
            </Button>
      },
    ]
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.onSelectChange(
          selectedRowKeys,
          selectedRows[0].cmmcLevel,
          selectedRows[0].isAnswered
        )
      },
      type: 'button',
    }
    let locale = {
      emptyText: (
        <span>
          <p>
            <b>No Documents Available </b>
          </p>
        </span>
      )
    };
    return (
      <>
        <div
          className="btn_sec mb-2"
          style={{
            width: '100%',
            textAlign: 'left',
            display: 'inline-block',
          }}
        >
          
          <label
            className="btn-sm btn btn_table mb-2 mr-2"
          >
            Select Assessment
          </label>

          <div className="btn-group">
          <Form.Control
                as="select"
                className="mr-sm-2"
                id="inlineFormCustomSelect"
                onChange={this.handleChange} 
                custom
                style={{fontWeight:'normal', fontFamily: 'Roboto'}}
                >
           
              {this.props.assessments ? this.props.assessments.map((data, index) => (
                 <option
                  style={{paddingLeft:'5px'}}
                  value={data.name} 
                 onClick={() => {  
                 this.getFileDownloadCount(this.userObj) }}>
                 {data.name}
                 </option>
              )) :
                <option
                className="btn-sm btn btn_table pl-1"
                value='Assessments'>No Assessments
                </option>}
                </Form.Control>
          </div>

          <Table
            size="small"
            bordered
            rowKey="id"
            locale={locale}
            // rowSelection={rowSelection}
            columns={colsAssessment}
            dataSource={this.state.tableData}
            pagination={false}
          />
        </div>

      </>
    )
  }
} 
