import React, { Component, useState } from 'react'
import { ApiAuth, HTTPMETHODS } from '../core/api'
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu'
import '../../../node_modules/react-simple-tree-menu/dist/main.css';
import './organization.css'
import { Card } from 'antd'
import * as Yup from 'yup';
import swal from 'sweetalert'
import _ from 'lodash'


function OrgData(props) {

  const nameRegex = /^[0-9a-zA-Z ,.]*$/i
  const phoneRegExp = /^(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/
  const stateRegExp = /^(\?-i:K[M]|A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$/
  const addressRegExp = /^[0-9a-zA-Z #,-.]*$/i
  const purposeRegExp = /^[0-9a-zA-Z ,;'-.]*$/i
  const firstNameRegax = /^[0-9a-zA-Z ,'-.]*$/i
  const emailRegax = /^(?!.*(@gmail|@hotmail|@yahoo|@aol|@outlook|@icloud|@zoho|@mail|@yopmail|@hubspot|@pepipost|@protonmail|@yandex|@tutanota|@facebook))/
  const envtRegax = /^[0-9a-zA-Z ,-.]*$/i
  const zipRegax = /^[0-9]{5}(?:-[0-9]{4})?$/

  //Organization Validation Starts here
  const nameschema = Yup.string()
    .required('Company Name is Required')
    .min(3, 'Company Name is Too Short!')
    .max(100, 'Company Name is Too Long!')
    .matches(nameRegex, 'Company Name should only contain Alphanumeric characters');

  const businessYearsSchema = Yup.number().typeError('Business Years is Required')
    .required('Business Years is Required')
    .min(1, 'Business Year cannot be smaller than 1')
    .max(100, 'Business Years cannot exceed 100 years');

  const zipSchema = Yup.string().required('Zip Code is Required')
    .typeError('Zip Code is Required')
    .matches(zipRegax, 'Zip Code should only contain exactly 5 numbers')

  const addressSchema = Yup.string().required('Address is Required')
    .min(10, 'Address is Too Short!')
    .max(100, 'Address is Too Long!')
    .matches(addressRegExp, 'Address should only contain Alphanumeric, space and , . - # characters');

  const citySchema = Yup.string().required('City is Required')
    .min(5, 'City is Too Short!')
    .max(100, 'City is Too Long!')
    .matches(nameRegex, 'City should only contain Alphanumeric characters');

  const stateSchema = Yup.string().required('State is Required')
    .matches(stateRegExp, 'State should only contain US State abbreviation Example : MD, VA');

  const aboutCompanySchema = Yup.string().required('About Company is Required')
    .min(200, 'About Company is Too Short')
    .max(2000, 'About Company is Too Long!')
    .matches(purposeRegExp, 'About Company should only contain Alphanumeric, space and , .  - ; \' characters');

  const employeeCountSchema = Yup.number()
    .typeError('Employee Count is Required')
    .min(1, 'Number of Employees cannot be smaller than 1')
    .max(100000, 'Number of Employees cannot exceed 100000')
    .required('Employee Count is Required');

  const phoneSchema = Yup.string()
    .max(15, 'Phone number should contain exactly 10 digits')
    .matches(phoneRegExp, 'Phone number should contain exactly 10 digits')
    .required('Phone Number is Required');



  let organizationschema = Yup.object().shape({
    address1: addressSchema,
    businessYears: businessYearsSchema,
    city: citySchema,
    employeeCount: employeeCountSchema,
    information: aboutCompanySchema,
    name: nameschema,
    phone: phoneSchema,
    state: stateSchema,
    zip: zipSchema
  });
  //Organization Validation ends here

  //Information System Validation starts here
  const infoAbrrevationSchema = Yup.string()
    .required('Abbreviation is Required')
    .min(3, 'Abbreviation is Too Short!')
    .max(50, 'Abbreviation is Too Long!')
    .matches(nameRegex, 'Abbreviation should only contain Alphanumeric, space and , .  characters')

  const infoNameSchema = Yup.string()
    .required('Name is Required')
    .min(10, 'Name is Too Short!')
    .max(100, 'Name is Too Long!')
    .matches(envtRegax, 'Name should only contain Alphanumeric,space and , . - characters');

  const infoFirstnameSchema = Yup.string()
    .required('Owner First Name is Required')
    .min(2, 'Owner First Name is Too Short!')
    .max(25, 'Owner First Name is Too Long!')
    .matches(firstNameRegax, 'Owner First Name should only contain Alphanumeric characters');

  const infoLastnameSchema = Yup.string()
    .required('Owner Last Name is Required')
    .min(2, 'Owner Last Name is Too Short!')
    .max(25, 'Owner Last Name is Too Long!')
    .matches(firstNameRegax, 'Owner Last Name should only contain Alphanumeric characters');

  const infoTitleSchema = Yup.string()
    .required('Title is Required')
    .min(2, 'Title is Too Short!')
    .max(100, 'Title is Too Long!')
    .matches(nameRegex, 'Title should only contain Alphanumeric characters');

  const infoEmail = Yup.string()
    .required('Email is Required')
    .min(7, 'Email is Too Short!')
    .max(100, 'Email is Too Long!')
    .matches(emailRegax, 'Email Id should only contain Business email address');

  const purposeSchema = Yup.string().required('Purpose is Required')
    .min(200, 'Purpose is Too Short')
    .max(2000, 'Purpose is Too Long!')
    .matches(purposeRegExp, 'Purpose should only contain Alphanumeric, space and , .  - ; \' characters');

  let informationschema = Yup.object().shape({
    abbreviation: infoAbrrevationSchema,
    address1: addressSchema,
    city: citySchema,
    email: infoEmail,
    firstName: infoFirstnameSchema,
    lastName: infoLastnameSchema,
    name: infoNameSchema,
    purpose: purposeSchema,
    title: infoTitleSchema
  });


  const envtNameSchema = Yup.string()
    .required('Name is Required')
    .min(3, 'Name is Too Short')
    .max(50, 'Name is Too Long!')
    .matches(envtRegax, 'Name should only contain Alphanumeric and , . - characters');

  const envtTypeSchema = Yup.string()
    .required('Type is Required')
    .min(10, 'Type is Too Short')
    .max(100, 'Type is Too Long!')
    .matches(envtRegax, 'Type should only contain Alphanumeric and , . - characters');

  const envtDescSchema = Yup.string().required('Description is Required')
    .min(200, 'Operational Description is Too Short')
    .max(2000, 'Operational Description is Too Long!')
    .matches(purposeRegExp, 'Operational Description should only contain Alphanumeric, space and  , . - ; \' characters');

  let envtschema = Yup.object().shape({
    name: envtNameSchema,
    operationalDescription: envtDescSchema,
    type: envtTypeSchema
  });

  //Environment Validation ends starts here

  const [isEdit, setIsEdit] = useState(false);
  const [displayData, setDisplayData] = useState(_.cloneDeep(props.data));
  const [initialData, setInitialData] = useState(_.cloneDeep(props.data));
  const [beforeUpdateData, setBeforeUpdateData] = useState(_.cloneDeep(props.data));
  const [display, setDisplay] = useState('Organization');
  const [isCreateInfo, setIsCreateInfo] = useState(false);
  const [isCreateEnvt, setisCreateEnvt] = useState(false);
  const [validationText, setValidationText] = useState('');
  const [infoIndex, setInfoIndex] = useState(0);
  const [envIndex, setEnvIndex] = useState(0);
  const [isDataValid, setDataValid] = useState(false);
  const [focusedInput, setfocusedInput] = useState('');
  const [newInfoSys, setNewInfoSys] = useState({
    id: '',
    abbreviation: '',
    name: '',
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    purpose: ''
  });
  const [newEnvt, setNewEnvt] = useState({
    id: '',
    name: '',
    type: '',
    operationalDescription: ''
  });


  const handleChange = () => {
    setIsEdit(false);
  }

  const AddDetails = () => {
    var tempData = initialData;
    var addURL;
    var payload;
    if (isCreateInfo) {
      if (tempData.informationSystems !== '' && tempData.informationSystems !== undefined && tempData.informationSystems !== null) {
        tempData.informationSystems.push(newInfoSys);
        var informationSystems = [];
        informationSystems.push(newInfoSys)
        payload = {'informationSystems': informationSystems}
      }
      else {
        var informationSystems = [];
        informationSystems.push(newInfoSys)
        tempData['informationSystems'] = informationSystems;
        payload = {'informationSystems': informationSystems}
      }
      addURL = '/organization?orgId=' + tempData.id + '&infoId=0&envId='
      console.log('addURL', addURL);
    }
    if (isCreateEnvt) {
      if (tempData.informationSystems[infoIndex].environments !== '' && tempData.informationSystems[infoIndex].environments !== undefined && tempData.informationSystems[infoIndex].environments !== null) {
        tempData.informationSystems[infoIndex].environments.push(newEnvt);
        var informationSystems = [];
        var environments = [];
        environments.push(newEnvt)
        informationSystems.push({'environments': environments})
        payload = {'informationSystems': informationSystems}
      }
      else {
        var environments = [];
        environments.push(newEnvt)
        tempData.informationSystems[infoIndex]['environments'] = environments;
        var informationSystems = [];
        informationSystems.push({'environments': environments})
        payload = {'informationSystems': informationSystems}
      }
      addURL = '/organization?orgId=' + tempData.id + '&infoId=' + tempData.informationSystems[infoIndex].id + '&envId=0'
      console.log('addURL', addURL);
    }

    const userObj = JSON.parse(localStorage.getItem('userInfo'))

    const addDetails = ApiAuth(
      HTTPMETHODS.PUT, addURL,
      payload,
      true,
      userObj.email,
      userObj.organizationId
    ).then((res) => {
      setInitialData(res.data);
      if (isCreateInfo) {
        var info = initialData.informationSystems.length - 1;
        console.log('info', info);
        setInfoIndex(info);
        setDisplayData(initialData.informationSystems[infoIndex]);
        initialData.informationSystems[infoIndex]['recStatus'] = 'active';
        setIsCreateInfo(false);
      }
      if (isCreateEnvt) {
        var env = initialData.informationSystems[infoIndex].environments.length - 1;
        setEnvIndex(env)
        setDisplayData(initialData.informationSystems[infoIndex].environments[envIndex]);
        initialData.informationSystems[infoIndex].environments[envIndex]['recStatus'] = 'active';
        setisCreateEnvt(false);
      }
    }).catch((adderr) => {
      if(adderr.response != undefined){
      setValidationText(adderr.response.data.message)
    }
      var tempData2 = initialData
      if (tempData2.informationSystems !== '' && tempData2.informationSystems !== undefined && tempData2.informationSystems !== null) {
        if (tempData2.informationSystems[infoIndex].environments !== '' && tempData2.informationSystems[infoIndex].environments !== undefined && tempData2.informationSystems[infoIndex].environments !== null) {
          tempData2.informationSystems[infoIndex].environments.forEach(element1 => {
            if (element1.id === '') {
              var index1 = tempData2.informationSystems[infoIndex].environments.findIndex(elem1 => elem1.id === '')
              tempData2.informationSystems[infoIndex].environments.splice(index1, 1);

            }
          })
        }
        setInitialData(tempData2);
      }

      tempData2.informationSystems.forEach(element => {
        if (element.id === '') {
          var index = tempData2.informationSystems.findIndex(elem => elem.id === '')
          tempData2.informationSystems.splice(index, 1);
        }
      });

      setInitialData(tempData2);
    })
  }

  const getAddPayload = () => {

  }


  const DeleteDetails = () => {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    if (display === 'Environment') {
      try {

        const userObj = JSON.parse(localStorage.getItem('userInfo'))
        const deleteOrganization = ApiAuth(
          HTTPMETHODS.DELETE,
          `/organization?orgId=${userObj.organizationId}&infoid=${initialData.informationSystems[infoIndex].id}&envid=${initialData.informationSystems[infoIndex].environments[envIndex].id}`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        ).then((res) => {
          window.location.reload();
        })
      } catch (e) {
        console.log('error in deleting organization', e.message)
      }
    }
    else if (display === 'Information-Systems') {
      var IsActiveEnvExist = false;
      if (initialData.informationSystems[infoIndex].environments !== '' && initialData.informationSystems[infoIndex].environments !== undefined && initialData.informationSystems[infoIndex].environments !== null) {
        initialData.informationSystems[infoIndex].environments.forEach(element => {
          if (element.recStatus === 'active') {
            IsActiveEnvExist = true;
          }
        });
      }

      if (IsActiveEnvExist === false) {
        try {
          const userObj = JSON.parse(localStorage.getItem('userInfo'))
          const deleteOrganization = ApiAuth(
            HTTPMETHODS.DELETE,
            `/organization?orgId=${userObj.organizationId}&infoid=${initialData.informationSystems[infoIndex].id}&envid=`,
            null,
            false,
            userObj.email,
            userObj.organizationId
          ).then((res) => {
            window.location.reload();
          })
        } catch (e) {
          console.log('error in deleting organization', e.message)
        }
      }
      else {
        swal('Error!', 'Cannot delete infomation system without deleting all sub environments', 'error')
      }
    }
  }


  const updateOrgDetails = () => {
    var tempData = initialData;
    const initDataWithInformationSystem = initialData;
    var payload;
    let updateURL;
    if (display === 'Organization') {
      tempData = displayData;
      if (displayData.informationSystems !== '' && displayData.informationSystems !== undefined && displayData.informationSystems !== null) {
        payload = displayData;
        delete payload.informationSystems;
        updateURL = '/organization?orgId=' + payload.id + '&infoId=&envId='
      }
      else {
        var informationSystems = []
        displayData['informationSystems'] = informationSystems;
        updateURL = '/organization?orgId=' + tempData.id + '&infoId=&envId='
        payload = displayData;
      }
    }
    else if (display === 'Information-Systems') {
      tempData.informationSystems[infoIndex] = displayData;
      var informationSystems = [];
      informationSystems.push(tempData.informationSystems[infoIndex])
      updateURL = '/organization?orgId=' + tempData.id + '&infoId=' + tempData.informationSystems[infoIndex].id + '&envId='
      payload = { 'informationSystems': informationSystems };
    }
    else if (display === 'Environment') {
      tempData.informationSystems[infoIndex].environments[envIndex] = displayData;
      var informationSystems = [];
      var environments = [];
      environments.push(tempData.informationSystems[infoIndex].environments[envIndex]);
      informationSystems.push({ 'environments': environments });
      updateURL = '/organization?orgId=' + tempData.id + '&infoId=' + tempData.informationSystems[infoIndex].id + '&envId=' + tempData.informationSystems[infoIndex].environments[envIndex].id
      payload = { 'informationSystems': informationSystems };
    }

    console.log('updateURL outside', updateURL);

    const userObj = JSON.parse(localStorage.getItem('userInfo'))

    const updateOrg = ApiAuth(
      HTTPMETHODS.PUT, updateURL,
      payload,
      true,
      userObj.email,
      userObj.organizationId
    ).then((updateres) => {
      tempData.informationSystems= initDataWithInformationSystem.informationSystems
      setIsEdit(false)
      setInitialData(tempData);
    }).catch((updateerr) => {
      setValidationText(updateerr.response.data.message)
    })


  }

  const createInfoSys = () => {
    setDisplay('Information-Systems');
    setIsEdit(false);
    setIsCreateInfo(true);
    setisCreateEnvt(false);
    setValidationText('');
    setNewInfoSys({
      id: '',
      abbreviation: '',
      name: '',
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      purpose: ''
    })
  }

  const createEnvt = (infoIndex) => {
    setDisplay('Environment');
    setIsEdit(false);
    setIsCreateInfo(false);
    setisCreateEnvt(true);
    setValidationText('');
    setInfoIndex(infoIndex)
    setNewEnvt({
      id: '',
      name: '',
      type: '',
      operationalDescription: ''
    })
  }

  const getTreeData = () => {

    let treeData = {}

    if (initialData?.informationSystems?.length) {
      const nodes = {};
      initialData.informationSystems.forEach((infoSys, i) => {
        if (initialData.informationSystems !== '' && initialData.informationSystems !== undefined && initialData.informationSystems !== null) {
          if (initialData.informationSystems[i].recStatus === 'active') {
            nodes[`Information-Systems-${i + 1}`] = {
              label: infoSys.name,
              infoIndex: i,
            };




            const thirdLevelNode = {};
            if (initialData.informationSystems[i].environments !== '' && initialData.informationSystems[i].environments !== undefined && initialData.informationSystems[i].environments !== null) {
              initialData.informationSystems[i].environments.forEach((environment, j) => {
                if (initialData.informationSystems[i].environments[j].recStatus === 'active') {
                  thirdLevelNode[`Environments-${j + 1}`] = {
                    label: environment.name,
                    infoIndex: i,
                    envIndex: j,
                  };
                }
              });
            }
            nodes[`Information-Systems-${i + 1}`].nodes = thirdLevelNode;
          }
        }
      });

      treeData = {
        'Organization': {
          label: initialData.name,
          nodes,
        }
      };
    }
    else {
      treeData = {
        'first-level-node-1': {               // key
          label: initialData.name,
          index: 0, // decide the rendering order on the same level
          nodes: {},
        }
      };
    }
    return treeData;

  }
  return (



    <>
      {/*   <p> Hello There!</p> */}
      {/*  <TreeMenu data={treeData} /> */}
      <div className="row">
        <div className="col-sm-3 removeOut">
          <TreeMenu
            className="make-bolder" data={getTreeData()}
            onClickItem={({ key, level, infoIndex, envIndex }) => {
              if (level === 0) {

                if (validationText === '') {
                  setBeforeUpdateData(_.cloneDeep(initialData))
                }
                else {
                  setInitialData(_.cloneDeep(beforeUpdateData));
                }

                setDisplayData(initialData);
                setDisplay('Organization');
                setIsEdit(false);
                setisCreateEnvt(false);
                setIsCreateInfo(false);
                setValidationText('');
              }
              else if (level === 1) {

                if (validationText === '') {
                  setBeforeUpdateData(_.cloneDeep(initialData))
                }
                else {
                  setInitialData(_.cloneDeep(beforeUpdateData));
                }

                setDisplayData(initialData.informationSystems[infoIndex]);
                setDisplay('Information-Systems');
                setIsEdit(false);
                setisCreateEnvt(false);
                setIsCreateInfo(false);
                setInfoIndex(infoIndex);
                setValidationText('');
              }
              else if (level === 2) {
                if (validationText === '') {
                  setBeforeUpdateData(_.cloneDeep(initialData))
                }
                else {
                  setInitialData(_.cloneDeep(beforeUpdateData));
                }
                setDisplayData(initialData.informationSystems[infoIndex].environments[envIndex]);
                setDisplay('Environment');
                setIsEdit(false);
                setisCreateEnvt(false);
                setIsCreateInfo(false);
                setEnvIndex(envIndex);
                setInfoIndex(infoIndex);
                setValidationText('');
              }

            }}
            initialActiveKey='first-level-node-1/second-level-node-1' // the path to the active node
            debounceTime={125}>
            {({ search, items }) => (
              <>
                <ul className="treelist">
                  {items.map((props) => {
                    if (props.level === 0) {
                      return (
                        <div className="tree-node">
                          <ItemComponent {...props} />
                          <div class="dropdown">
                            <button button class="btn dropdown-toggle DropBtn" type="button" id="dropdownMenuButton"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {/*  <i class="fa fa-ellipsis-h" aria-hidden="true"></i> */}
                              <img src={require('../../assets/dots.png')} alt="dots" />
                            </button>
                            <div class="dropdown-menu create-info" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" onClick={createInfoSys}>Create new Information System</a>
                            </div>
                          </div>
                        </div>



                      )
                    }
                    else if (props.level === 1) {
                      console.log(props,'item com')
                      return (
                        <div className="tree-node">
                          <ItemComponent {...props} />
                        
                          <div class="dropdown">
                            <button button class="btn dropdown-toggle DropBtn" type="button" id="dropdownMenuButton"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                              {/*  <i class="fa fa-ellipsis-h" aria-hidden="true"></i> */}
                              <img src={require('../../assets/dots.png')} alt="dots" />

                            </button>
                            <div class="dropdown-menu create-envt" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" onClick={()=>createEnvt(props.infoIndex)}>Create new Environment</a>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    else if (props.level === 2) {
                      return (<ItemComponent {...props} />)
                    }
                  }


                  )}
                </ul>

              </>
            )}
          </TreeMenu>
        </div>
        <div className="col-sm-9">

          <Card className="card orgCard" bordered={false}>
            {display === 'Organization' ?
              <div>
                <h5>Organization</h5>
                <div align="center" className="errorText">
                  {validationText ? <p>{validationText}</p> : null}
                </div>

                <div className="row">
                  <div className="col-sm-6 align-right org-title">

                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6> Company Name :</h6> </div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Address :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>City :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>State :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Zip Code :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>How many years in business :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Company Type :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Number of Employees :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Business Phone Number :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Which CMMC Certification is your company pursuing? :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Estimated timeframe for CMMC audit readiness? :</h6></div><br />
                    <div className="modify-enabled"> {isEdit ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>About Company :</h6></div><br />
                  </div>
                  {isEdit ? <div className="col-sm-6 align-inline-grid org-input">

                    <input
                      type="text"
                      name="name"
                      value={displayData.name}
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        nameschema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);

                        })
                        setDisplayData({ ...displayData, name: e.target.value });
                      }}
                    />

                    <input
                      type="text"
                      name="address1"
                      value={displayData.address1}
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        addressSchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({ ...displayData, address1: e.target.value });
                      }}
                    />

                    <input
                      type="text"
                      name="city"
                      value={displayData.city}
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        citySchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({ ...displayData, city: e.target.value });
                      }}
                    />

                    <input
                      type="text"
                      name="state"
                      value={displayData.state}
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        stateSchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({ ...displayData, state: e.target.value });
                      }}
                    />

                    <input
                      type="text"
                      name="zip"
                      value={displayData.zip}
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        zipSchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({ ...displayData, zip: e.target.value });
                      }}
                    />

                    <input
                      type="number"
                      name="businessYears"
                      value={displayData.businessYears}
                      onChange={(e) => {
                        e.persist();
                        setValidationText('')
                        businessYearsSchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({ ...displayData, businessYears: e.target.value });
                      }}
                    />

                    <select
                      name="companyType"
                      value={displayData.companyType}
                      onChange={(e) => {
                        setDisplayData({ ...displayData, companyType: e.target.value });
                      }}
                    >
                      <option value="Education">Education</option>
                      <option value="Enterprise">Enterprise</option>
                      <option value="Government - State & Local">Government - State & Local</option>
                      <option value="Government - Federal">Government - Federal</option>
                      <option value="Non-profit">Non-profit</option>
                      <option value="Small-Medium Business">Small-Medium Business</option>
                      <option value="Start-up">Start-up</option>
                    </select>


                    <input type="number" name="employeeCount"
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        employeeCountSchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({ ...displayData, employeeCount: e.target.value });
                      }}
                      value={displayData.employeeCount} />

                    <input type="text" name="phone"
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        phoneSchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({
                          ...displayData, phone: e.target.value.replace(
                            /(\d{3})-?(\d{3})-?(\d{4})/,
                            '($1)-$2-$3'
                          )
                        });
                      }}
                      value={displayData.phone} />

                    <select
                      name="cmmcLevel"
                      value={displayData.cmmcLevel}
                      onChange={(e) => {
                        setDisplayData({ ...displayData, cmmcLevel: e.target.value });
                      }}
                    >
                      <option value="Level 1">Level 1</option>
                      <option value="Level 2">Level 2</option>
                      <option value="Level 3">Level 3</option>
                      <option value="Level 4">Level 4</option>
                      <option value="Level 5">Level 5</option>
                    </select>

                    <select
                      name="timeframe"
                      value={displayData.timeframe}
                      onChange={(e) => {
                        setDisplayData({ ...displayData, timeframe: e.target.value });
                      }}
                    >
                      <option value="1-3 months">1-3 months</option>
                      <option value="3-6 months">3-6 months</option>
                      <option value="6-9 months">6-9 months</option>
                      <option value="12+ months">12+ months</option>
                    </select>

                    <input type="text"
                      onChange={(e) => {
                        e.persist();
                        setValidationText('');
                        aboutCompanySchema.validate(e.target.value).catch(function (err) {
                          setValidationText(err.errors);
                        });
                        setDisplayData({ ...displayData, information: e.target.value });
                      }}
                      name="information" value={displayData.information} />

                  </div>
                    :
                    <div className="col-sm-6" >
                      <div>
                        {props.data && displayData ?
                          <div className="org-value">
                            <p> {displayData.name}  </p>
                            {displayData.address1 ? <p> {displayData.address1} </p> : <p>-</p>}
                            {displayData.city ? <p> {displayData.city} </p> : <p>-</p>}
                            {displayData.state ? <p> {displayData.state} </p> : <p>-</p>}
                            {displayData.zip ? <p> {displayData.zip} </p> : <p>-</p>}
                            <p> {displayData.businessYears} </p>
                            <p> {displayData.companyType} </p>
                            <p> {displayData.employeeCount} </p>
                            {displayData.phone ? <p> {displayData.phone} </p> : <p>-</p>}
                            <p> {displayData.cmmcLevel} </p>
                            <p> {displayData.timeframe} </p>
                            {displayData.information ? <p> {displayData.information} </p> : <p>-</p>}
                          </div> : null
                        }

                      </div>
                    </div>}
                </div>

              </div>
              :
              <div>
                {display === 'Information-Systems' ?
                  <div>
                    <h5>Information Systems</h5>
                    <div align="center" className="errorText">
                      {validationText ? <p>{validationText}</p> : null}
                    </div>

                    <div className="row">
                      <div className="col-sm-6 align-right org-title">
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Information System Abbreviation :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Information System Name :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Information System Owner First Name :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Information System Owner Last Name :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Title :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Email id :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Address :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>City :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>State :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Zip Code :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Business Phone Number :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateInfo ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Purpose :</h6></div><br />
                      </div>
                      {isEdit ? <div className="col-sm-6 align-inline-grid org-input">
                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            infoAbrrevationSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, abbreviation: e.target.value });
                          }}
                          name="info-abbreviation" value={displayData.abbreviation} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            infoNameSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, name: e.target.value });
                          }}
                          name="info-name" value={displayData.name} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            infoFirstnameSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, firstName: e.target.value });
                          }}
                          name="info-firstName" value={displayData.firstName} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            infoLastnameSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, lastName: e.target.value });
                          }}
                          name="info-lastName" value={displayData.lastName} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            infoTitleSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, title: e.target.value });
                          }}
                          name="info-title" value={displayData.title} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            infoEmail.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, email: e.target.value });
                          }}
                          name="info-email" value={displayData.email} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            addressSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, address1: e.target.value });
                          }}
                          name="info-address1" value={displayData.address1} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            citySchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, city: e.target.value });
                          }}
                          name="info-city" value={displayData.city} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            stateSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, state: e.target.value });
                          }}
                          name="info-state" value={displayData.state} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            zipSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, zip: e.target.value });
                          }}
                          name="info-zip" value={displayData.zip} />

                        <input
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            phoneSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({
                              ...displayData, phone: e.target.value.replace(
                                /(\d{3})-?(\d{3})-?(\d{4})/,
                                '($1)-$2-$3'
                              )
                            });
                          }}
                          type="text" name="info-phone" value={displayData.phone} />

                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            purposeSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, purpose: e.target.value });
                          }}
                          name="info-purpose" value={displayData.purpose} />

                      </div>
                        :
                        <div className="col-sm-6" >

                          {/* CREATE INFORMATION SYSTEM */}
                          {isCreateInfo ?
                            <div class="align-inline-grid-create org-input">
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  infoAbrrevationSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, abbreviation: e.target.value });
                                }}
                                value={newInfoSys.abbreviation}
                                name="create-info-abbreviation" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  infoNameSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, name: e.target.value });
                                }}
                                value={newInfoSys.name}
                                name="create-info-name" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  infoFirstnameSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, firstName: e.target.value });
                                }}
                                value={newInfoSys.firstName}
                                name="create-info-firstName" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  infoLastnameSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, lastName: e.target.value });
                                }}
                                value={newInfoSys.lastName}
                                name="create-info-lastName" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  infoTitleSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, title: e.target.value });
                                }}
                                value={newInfoSys.title}
                                name="create-info-title" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  infoEmail.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, email: e.target.value });
                                }}
                                value={newInfoSys.email}
                                name="create-info-email" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  addressSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, address1: e.target.value });
                                }}
                                value={newInfoSys.address1}
                                name="create-info-address1" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  citySchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, city: e.target.value });
                                }}
                                value={newInfoSys.city}
                                name="create-info-city" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  stateSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, state: e.target.value });
                                }}
                                value={newInfoSys.state}
                                name="create-info-state" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  zipSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, zip: e.target.value });
                                }}
                                value={newInfoSys.zip}
                                name="create-info-zip" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  phoneSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({
                                    ...newInfoSys, phone: e.target.value.replace(
                                      /(\d{3})-?(\d{3})-?(\d{4})/,
                                      '($1)-$2-$3'
                                    )
                                  });
                                }}
                                value={newInfoSys.phone}
                                name="create-info-phone" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  purposeSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewInfoSys({ ...newInfoSys, purpose: e.target.value });
                                }}
                                value={newInfoSys.purpose}
                                name="create-info-purpose" />
                            </div>
                            :
                            <div>
                              {props.data !== null && props.data !== undefined ? <div className="org-value">
                                <p> {displayData.abbreviation}  </p>
                                <p> {displayData.name} </p>
                                <p> {displayData.firstName} </p>
                                <p> {displayData.lastName} </p>
                                <p> {displayData.title} </p>
                                <p> {displayData.email} </p>
                                <p> {displayData.address1} </p>
                                <p> {displayData.city} </p>
                                <p> {displayData.state} </p>
                                <p> {displayData.zip} </p>
                                <p> {displayData.phone} </p>
                                <p> {displayData.purpose} </p>
                              </div> : null
                              }

                            </div>
                          }

                        </div>}
                    </div>


                  </div>
                  :
                  <div>
                    <h5>Environment</h5>
                    <div align="center" className="errorText">
                      {validationText ? <p>{validationText}</p> : null}
                    </div>

                    <div className="row">
                      <div className="col-sm-6 align-right org-title">
                        <div className="modify-enabled"> {isEdit || isCreateEnvt ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Environment Name :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateEnvt ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Environment Type :</h6></div><br />
                        <div className="modify-enabled"> {isEdit || isCreateEnvt ? <h6 class="star-icon">*&nbsp;</h6> : null}<h6>Operational Description :</h6></div><br />
                      </div>
                      {isEdit ? <div className="col-sm-6 align-inline-grid org-input">
                        <input type="text"
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            envtNameSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, name: e.target.value });
                          }}
                          name="envt-name" value={displayData.name} />
                        <input
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            envtTypeSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, type: e.target.value });
                          }}
                          type="text" name="envt-type" value={displayData.type} />
                        <input
                          onChange={(e) => {
                            e.persist();
                            setValidationText('');
                            envtDescSchema.validate(e.target.value).catch(function (err) {
                              setValidationText(err.errors);
                            });
                            setDisplayData({ ...displayData, operationalDescription: e.target.value });
                          }}
                          type="text" name="envt-desc" value={displayData.operationalDescription} />
                      </div>
                        :
                        <div className="col-sm-6" >
                          {isCreateEnvt ?
                            <div className="align-inline-grid-create org-input">
                              <input type="text"
                                onChange={(e) => {

                                  e.persist();
                                  setValidationText('');
                                  envtNameSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewEnvt({ ...newEnvt, name: e.target.value });
                                }}
                                value={newEnvt.name}
                                name="create-envt-name" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  envtTypeSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewEnvt({ ...newEnvt, type: e.target.value });
                                }}
                                value={newEnvt.type}
                                name="create-envt-type" />
                              <input type="text"
                                onChange={(e) => {
                                  e.persist();
                                  setValidationText('');
                                  envtDescSchema.validate(e.target.value).catch(function (err) {
                                    setValidationText(err.errors);
                                  });
                                  setNewEnvt({ ...newEnvt, operationalDescription: e.target.value });
                                }}
                                value={newEnvt.operationalDescription}
                                name="create-envt-desc" />
                            </div>
                            :
                            <div>
                              {props.data !== null && props.data !== undefined ? <div className="org-value">
                                <p> {displayData.name}  </p>
                                <p> {displayData.type} </p>
                                <p> {displayData.operationalDescription} </p>
                              </div> : null
                              }

                            </div>
                          }

                        </div>}
                    </div>

                  </div>

                }
              </div>
            }


            <div align="center" style={{ marginTop: '10px' }}>
              {isEdit ?
                <div>
                  {display === 'Organization' ? <button onClick={() => updateOrgDetails()}
                    type="button" disabled={validationText !== '' || displayData.address1 === undefined || displayData.city === undefined || displayData.state === undefined
                      || displayData.zip === undefined || displayData.phone === undefined || displayData.information === undefined}

                    className="btn btn-sm btn-primary btn-rounded">
                    Update
  </button> :
                    <button onClick={() => updateOrgDetails()}
                      type="button" disabled={validationText !== ''}
                      className="btn btn-sm btn-primary btn-rounded">Update</button>
                  }

                  <button onClick={() => {
                    setIsEdit(false)
                    setValidationText('');
                    if (display === 'Organization') {
                      setDisplayData(initialData);
                    }
                    else if (display === 'Information-Systems') {
                      setDisplayData(initialData.informationSystems[infoIndex]);
                    }
                    else if (display === 'Environment') {
                      setDisplayData(initialData.informationSystems[infoIndex].environments[envIndex]);
                    }
                  }} style={{ marginLeft: '15px' }}
                    type="button" className="btn btn-sm btn-primary btn-rounded"> Cancel
</button>

                </div>

                :

                <div>
                  {isCreateEnvt || isCreateInfo ?
                    <div>
                      <button
                        type="button" disabled={validationText !== ''} onClick={() => AddDetails()}
                        className="btn btn-sm btn-primary btn-rounded">
                        Add
                      </button>

                      <button style={{ marginLeft: '15px' }}
                        type="button" onClick={() => {
                          setIsEdit(false)
                          setisCreateEnvt(false);
                          setIsCreateInfo(false);
                          setValidationText('');
                          if (display === 'Organization') {
                            setDisplayData(initialData);
                          }
                          else if (display === 'Information-Systems') {
                            if (initialData.informationSystems === undefined) {
                              setDisplay('Organization');
                              setDisplayData(initialData);
                            }
                            else if (initialData.informationSystems[infoIndex].recStatus === 'Inactive') {
                              setDisplay('Organization');
                              setDisplayData(initialData);
                            }
                            else {
                              setDisplayData(initialData.informationSystems[infoIndex]);
                            }
                          }
                          else if (display === 'Environment') {
                            if (isCreateEnvt) {
                              setDisplay('Information-Systems');
                              setDisplayData(initialData.informationSystems[infoIndex]);
                            }
                            else {
                              if (initialData.informationSystems[infoIndex].environments === undefined) {
                                setDisplay('Information-Systems');
                                setDisplayData(initialData.informationSystems[infoIndex]);
                              }
                              else {
                                setDisplayData(initialData.informationSystems[infoIndex].environments[envIndex]);
                              }
                            }

                          }
                        }}
                        className="btn btn-sm btn-primary btn-rounded">
                        Cancel
      </button>
                    </div>
                    :
                    <div>
                      <button onClick={() => {
                        setIsEdit(true)
                      }}
                        type="button"
                        className="btn btn-sm btn-primary btn-rounded">
                        Edit
       </button>
                      {display === 'Information-Systems' || display === 'Environment' ?
                        <button style={{ marginLeft: '15px' }}
                          type="button"
                          onClick={() => DeleteDetails()}
                          className="btn btn-sm btn-primary btn-rounded">
                          Delete
          </button> : null
                      }

                    </div>
                  }
                </div>
              }

            </div>

          </Card>
        </div>
      </div>

    </>
  )
}

export default OrgData
