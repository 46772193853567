import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { ApiAuth, HTTPMETHODS } from '../core/api'

const colsReports = [
  {
    title: '#',
    dataIndex: 'index',
  },
   
  {
    title: 'Name',
    dataIndex: 'reportType',
    //render: (reportType) => renderName(reportType),
  },

  {
    title: 'CMMC Version',
    dataIndex: 'cmmcVersion',
  },
  {
    title: 'CMMC Level',
    dataIndex: 'cmmcLevel',
  },
  {
    title: 'Generated Date',
    dataIndex: 'generatedDate',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => <button type="button"
    className="btn-sm btn btn-secondary btn_table"
    onClick={(e) => {
      e.preventDefault();
      window.location.href=record.url;
      }}><i class="fas fa-download"></i>Download</button>,
  }
]

/* const DownloadSSP = (url) => {
  console.log('onPress', url)
  ApiAuth(HTTPMETHODS.GET, url);
} */

export default class ReportsTable extends Component {
  render() {
    /* const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.onSelectChange(
          selectedRowKeys,
          selectedRows[0].cmmcLevel,
          selectedRows[0].isAnswered
        )
      },
      type: 'radio',
    } */

    const data = this.props.reports.map((item, i) => ({
      ...item, index: i+1
    }));

    return (
      <Table
        size="small"
        bordered
        rowKey="id"
        //rowSelection={rowSelection}
        columns={colsReports}
        dataSource={data}
        locale={{ emptyText: 'No Reports currently available' }}
      />
    )
  }
}