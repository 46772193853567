import { ApiAuth, HTTPMETHODS } from '../../components/core/api'
import React, { Component } from 'react'
import BoxContainer from './boxCointainer'
import ChartElement from './ChartElement'

const completedColor = '#f1b44c'
const practicesColor = '#00b6e9'

let calculatedWidth, calculatedHeight

class QuestionDomain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      practiceData: [4,2,1,4,2,4],
      completedData: [0,0,0,0,0,0],
      labels: ["Access Control (AC)","Identification and Authentication (IA)","Media Protection (MP)","Physical Protection (PE)","System and Communications Protection (SC)","System and Information Integrity (SI)"],
      chartData: {},
      chartOptions: {},      
    }

    this.getDomainQuestionData = this.getDomainQuestionData.bind(this)
  }

  componentDidMount = async () => {
    const modifiedWidth  = document.body.clientWidth
   /*  if(modifiedWidth <= 1600 ) {
      calculatedWidth = 140
      calculatedHeight = 215
    }
    else if(modifiedWidth >= 1518 && modifiedWidth < 1600){
    //  90%
      calculatedWidth = 400
      calculatedHeight = 183
    }
    else if(modifiedWidth >= 1366 && modifiedWidth < 1518){
      // 100%
      calculatedWidth = 140
      calculatedHeight = 153
    }
    else if(modifiedWidth >= 1228 && modifiedWidth < 1518 && modifiedWidth < 1366 ){
      // 110%
      calculatedWidth = 140
      calculatedHeight = 150
    } */

    // this.getDomainNames()
    await this.getDomainQuestionData()
    const { practiceData, completedData, labels } = this.state
    const chartData = {
      // labels: labels,
      labels: ['AC','IA','MP','PE', 'SC','SI'],
      datasets: [
        {
          label: 'Practices',
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: '#00b6e9',
          data: practiceData,
        },
        {
          label: 'Completed',
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: '#f1b44c',
          data: completedData,
        },
      ],
    }

    const chartOptions = {
      responsive: true,
     maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        onClick: (e) => e.stopPropagation(),
   
      labels: {
      fontColor: "#333",
      fontSize: 12
      }
      },

      animation: {
        animateScale: false,
        animateRotate: false,
      },
      scales: {
        xAxes: [
          {
            borderWidth: 40,
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              stepSize: 0.5,
              max: 4,
              min: 0,
            },
          },
        ],
      },
    }
    this.setState({ chartData, chartOptions })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }
  async getDomainQuestionData() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    const orgId = encodeURIComponent(userObj.organizationId)

    try {
      let domainData = await ApiAuth(
        HTTPMETHODS.GET,
        `/questions/domain?organizationId=${orgId}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      domainData = domainData.data.sort(function (a, b) {
        if (a.domainName > b.domainName) {
          return 1
        }
        if (a.domainName < b.domainName) {
          return -1
        }
        return 0
      })
      let practiceData = [],
        completedData = [],
        labels = []
      domainData.forEach((item) => {
        labels.push(item.domainName + ' (' + item.domainCode + ')')
        practiceData.push(item.totalPractices)
        completedData.push(item.completedPractices)
      })
      this.setState({ labels, practiceData, completedData })
    } catch (e) {
      console.log('error in fetching compliance score', e.message)
    }
  }
  render() {
    const { chartData, chartOptions } = this.state
    const {width, height} = this.props
    const widthIndicator = '10px'

    return (
      <>
    <div className="white_box">
        <h3 class="graph_title">Practices Completed Per Domain</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 10px 10px 10px',
            margin:'0px auto'
          }}
        >
          <ChartElement
            chartId="questionareChart"
            type="bar1"
            data={chartData}
            options={chartOptions}
            width={width}
            height={height}
          />
        </div>
        <div
              style={{
                // justifyContent: 'center',
                // position: 'relative',
                // marginTop: '1px',
                // marginLeft: '20%',
                // // marginRight: '0px',    
                // fontSize: '13px',
                // fontWeight: '400',
                // color: '#4c4c4c',
                // paddingBottom:'16px',
                // // padding: '10px'
                display: 'flex',
                alignItems:'center',
                justifyContent: 'center',
                // padding:'0px 0px 21px 0px '
              }}
            >
              <ul class="list-group list-group-horizontal-sm">
                <li class="list-group-item" style={{border: 'none',padding:'1px',whiteSpace: 'pre'}}>
                <BoxContainer color={practicesColor} widthIndicator={widthIndicator} />Practices  </li> 
                <br/>
                <li class="list-group-item" style={{border: 'none',padding:'1px'}}>
                <BoxContainer color={completedColor} widthIndicator={widthIndicator}/>Completed </li>
              </ul>  <br/>  <br/>
            </div>
        </div>
      </>
    )
  }
}

export default QuestionDomain