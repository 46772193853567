import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'

import ChartElement from './ChartElement'

class gapReportByLevel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      practiceData: [],
      completedData: [],
      labels: [],
      chartData: {},
      chartOptions: {},
    }

    this.getDomainQuestionData = this.getDomainQuestionData.bind(this)
  }

  componentDidMount = async () => {
    await this.getDomainQuestionData()
    const { practiceData, completedData, labels } = this.state
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Practices',
          barPercentage: 0.5,
          barThickness: 14,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: '#3498db',
          data: practiceData,
        },
        {
          label: 'Completed',
          barPercentage: 0.5,
          barThickness: 14,
          maxBarThickness: 16,
          minBarLength: 2,
          backgroundColor: '#e67300',
          data: completedData,
        },
      ],
    }

    const chartOptions = {
      responsive: false,
      legend: {
        position: 'bottom',
        onClick: (e) => e.stopPropagation(),
      },

      animation: {
        animateScale: false,
        animateRotate: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              max: 4,
              min: 0,
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    }
    this.setState({ chartData, chartOptions })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }
  async getDomainQuestionData() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    const orgId = encodeURIComponent(userObj.organizationId)

    try {
      let domainData = await ApiAuth(
        HTTPMETHODS.GET,
        `/questions/domain?organizationId=${orgId}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      domainData = domainData.data.sort(function (a, b) {
        if (a.domainName > b.domainName) {
          return 1
        }
        if (a.domainName < b.domainName) {
          return -1
        }
        return 0
      })
      let practiceData = [],
        completedData = [],
        labels = []
      domainData.forEach((item) => {
        labels.push(item.domainCode)
        practiceData.push(item.totalPractices)
        completedData.push(item.completedPractices)
      })
      this.setState({ labels, practiceData, completedData })
    } catch (e) {
      console.log(e.message)
    }
  }

  render() {
    const { chartData, chartOptions } = this.state
    const { height, width } = this.props
    return (
      <div>
        <ChartElement
          chartId="gapReportChart"
          type="bar2"
          data={chartData}
          options={chartOptions}
          height={height}
          width={width}
          handleBaseURL={this.props.handleBaseURL}
        />
      </div>
    )
  }
}

export default gapReportByLevel
