import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
// import Amplify from 'aws-amplify'

import AppRouter from '../src/components/approuter'

// Amplify.configure(awsconfig)

ReactDOM.render(<AppRouter />, document.getElementById('root'))

serviceWorker.unregister()
