import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Accordion, Button, Badge } from 'react-bootstrap'
import './survey.css'

const CardItem = (props) => {
  const {
    isActive,
    isDone,
    label,
    assessmentId,
    domainName,
    total,
    practiceNo,
  } = props

  const [code, level, capacity] = (label && label.split('.')) || []
  return (
    <li className={`${isActive ? 'active' : ''}`}>
      <span>
        {isDone ? (
          <i className="done-mark badge" style={{ fontStyle: 'normal' }}>
            Done
          </i>
        ) : null}
        <Link
          to={{
            pathname: `/assessment/${assessmentId}/${level}`,
            state: {
              assessmentId: assessmentId,
              assessmentLevel: level,
              domainCode: code,
              capacityCode: capacity,
              domainName: domainName,
              practiceNo: practiceNo + 1,
              total: total,
            },
          }}
        >
          {label}
        </Link>
      </span>
    </li>
  )
}

const Counter = ({ total, solved }) => (
  <Badge className="counter-badge" variant="primary">
    {solved}/{total}
  </Badge>
)

const CardHeader = ({
  label,
  shortName,
  total,
  totalSolvedNumber,
  isOpen,
  handleOpen,
}) => (
  <Card.Header onClick={() => handleOpen(!isOpen)}>
    <Accordion.Toggle as={Button} variant="link" eventKey="1">
      {total && isOpen ? (
        <i className="fas fa-caret-down" />
      ) : total && !isOpen ? (
        <i className="fas fa-caret-right" />
      ) : null}
      {`${label} (${shortName})`}
      <Counter total={total} solved={totalSolvedNumber} />
    </Accordion.Toggle>
  </Card.Header>
)

const CardBody = ({
  assessmentId,
  curCode,
  curCapacityCode,
  curLevel,
  domain,
  isOpen,
  data,
  domainName,
  total,
}) => (
  <Accordion.Collapse className={isOpen ? 'show' : 'hide'} eventKey="1">
    <Card.Body>
      <ul>
        {data.map((item, index) => (
          <CardItem
            key={index}
            practiceNo={index}
            assessmentId={assessmentId}
            isActive={
              domain === curCode && item.capacityCode === curCapacityCode
            }
            isDone={item.done}
            label={`${domain}.${curLevel}.${item.capacityCode}`}
            domainName={domainName}
            total={total}
          />
        ))}
      </ul>
    </Card.Body>
  </Accordion.Collapse>
)

const SurveryCard = ({
  assessmentId,
  curCode,
  curLevel,
  curCapacityCode,
  data,
}) => {
  // const isActivePanel = data.data.filter(
  //   (item) => data.domain === curCode && item.capacityCode === curCapacityCode
  // ).length
  // console.log('isActivePanel', data.domain, isActivePanel)
  const [isOpen, handleOpen] = useState(false)

  return (
    <Accordion>
      <Card>
        <CardHeader
          label={data.domainName}
          shortName={data.domain}
          total={data.totalCapacities}
          totalSolvedNumber={data.totalSolved}
          isOpen={isOpen}
          handleOpen={() => handleOpen(!isOpen)}
        />
        <CardBody
          domainName={data.domainName}
          total={data.totalCapacities}
          assessmentId={assessmentId}
          curCode={curCode}
          curLevel={curLevel}
          curCapacityCode={curCapacityCode}
          domain={data.domain}
          data={data.data}
          isOpen={isOpen}
        />
      </Card>
    </Accordion>
  )
}

const Surveybar = (props) => {
  const { assessmentId, curCode, curLevel, curCapacityCode, surveyData } = props
  return (
    <div className="survey-bar">
    <div className="survey-bar-inn">
      {surveyData.map((data, index) => {
        const isActive = data.domain === curCode
        return (
          <SurveryCard
            key={index}
            assessmentId={assessmentId}
            curCode={curCode}
            curLevel={curLevel}
            curCapacityCode={curCapacityCode}
            isActive={isActive}
            data={data}
          />
        )
      })}
    </div>
    </div>
  )
}
// eslint-disable-next-line no-extend-native
Array.prototype.unique = function () {
  return this.filter(function (value, index, self) {
    return self.indexOf(value) === index
  })
}

export default Surveybar
