import React from 'react'
import './landingPage.css'
import 'font-awesome/css/font-awesome.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'

function TermPage() {
  return (
    <div className="term-page">
      <header>
        <div className="container">
          <div className="row align_center">
            <div className="col-xl-3 col-md-6 col-xs-12">
              <div className="logo">
                <a href="/">
                  <img src={require('../../assets/logo.jpg')} alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-xl-9 col-md-6 col-xs-12">
              <div className="right-part">
                <ul>
                  <li>
                    <Link to="/login">
                      <i
                        className="fa fa-address-card-o"
                        aria-hidden="true"
                      ></i>{' '}
                      Sign In
                    </Link>
                  </li>
                  <li>
                    <a href="https://intersecinc.com/contact-us">
                      <i className="fa fa-user-o" aria-hidden="true"></i>{' '}
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="security_compliance">
        <div className="container">
          <h2 className="text-center">Terms of Use</h2>

          <p>
            <b>Effective Date</b>: April 2, 2020
          </p>
          <p>
            InterSec Inc. (collectively “InterSec,” “we,” “us,” or “our”)
            maintains the www.securitycompliance.io website (“Site”) and all its
            subdomains. By using the (“Site”), you agree that you have read,
            understand, and agree to these terms of use (“Terms of Use”), which
            incorporate our Privacy Policy, available on our Site. Please note
            that certain sections of the Site may have additional or different
            terms of use or privacy policies applicable to those sections of the
            Site. Any such additional or different terms will be posted on the
            applicable section of the Site. If you do not agree to these Terms
            of Use or the Privacy Policy, please do not use the Site. If you
            have any questions regarding these Terms of Use, please contact us
            at inquiries@intersecinc.com.
          </p>

          <p>
            <strong>User Content</strong>
          </p>
          <p>
            Except as may be permitted on certain pages of the Site, you are not
            permitted to post any content on the Site. Where you are permitted
            to post content on the Site, you may not post or link to any content
            that is false, racial, defamatory, inaccurate, abusive, harassing,
            obscene, sexually oriented, threatening, invasive of a person's
            privacy, or that would infringe on any third party’s rights
            (including copyrights and other intellectual property rights) or
            content that otherwise violate any applicable law, rules, or
            regulations. We have the right to distribute, copy, adapt,
            reproduce, and otherwise use any content that you post to the Site.
            We may, but are not required to, moderate, review, edit, or remove
            any content you post to the Site for any reason. Any information
            that you post to our Site becomes publicly available and may be
            viewable by any visitors to our Site. By posting content to our
            Site, you agree that such posting is subject to these Terms of Use
            and our Privacy Policy.
          </p>

          <p>
            <strong>User Obligations</strong>
          </p>
          <p>
            <b>Materials on the Site. </b>Unless otherwise indicated, the Site
            and the material on the Site, including but not limited to all text,
            HTML code, graphics, button images, and the site design and "look
            and feel," are owned or licensed by us and are protected pursuant to
            U.S. and foreign copyright, trademark, and other laws. No
            intellectual property or other rights in or to this Site or its
            contents are transferred to you. This Site and the material on this
            Site may not be modified, copied, distributed, republished,
            downloaded, uploaded, or commercially exploited in any manner
            without our prior written consent, except that you are permitted to
            download a copy of any of the materials on this Site on a single
            computer for personal, noncommercial use, provided no copyright,
            trademark or other proprietary notices are removed. The information
            provided on the Site is provided solely for illustrative and/or
            informational purposes and does not create any business,
            contractual, or employment relationship. "InterSec," “Security
            Compliance,” and any and all other services marks or trademarks used
            on the Site are the registered and/or unregistered service marks or
            trademarks of InterSec or its licensors. The use of these or any
            other service marks or trademarks on the Site is prohibited without
            express written permission from us and/or the owner of any such
            service mark or trademark.
          </p>
          <p>
            <b>Third-Party Linking. </b>Unless expressly permitted, you may not
            create a link to this Site. Our Site may contain links to other
            websites, including various social media websites. We do not
            regularly review materials on websites linked to from this Site and
            do not necessarily endorse the materials appearing on any websites
            linked to from this Site. We assume no responsibility for the
            content or the policies and practices of such other websites. We
            encourage you to be aware when you leave our Site and to read the
            privacy policies of any other website that you visit.
          </p>

          <p>
            <strong>Using Website</strong>
          </p>
          <p>
            When you use this Site for CMMC certification compliance, we will
            ask you to provide us with information about yourself, including
            information about your organization and systems. As part of the
            application process we give you an opportunity to sign-up to receive
            ongoing email communications from us regarding CMMC that may be of
            interest to you. Through this process you can let us know what types
            of CMMC information you are interested in and how frequently you
            would like to be updated. You can opt-out of receiving these
            communications at any time. Please note that even if you do not
            sign-up to receive ongoing communications from us, we may use the
            information collected via this process to contact you regarding
            other services. If at any time you would like to stop receiving
            these email communications, you can follow the opt-out procedures
            described in such email.
          </p>

          <p>
            <strong>
              No Warranty and Limitation of Liability and Indemnification
            </strong>
          </p>
          <p>
            THE MATERIAL ON THIS SITE IS PROVIDED ’AS IS’ AND ‘AS AVAILABLE’ AND
            IS WITHOUT WARRANTY OF ANY KIND. INTERSEC DISCLAIMS ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED
            WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS
            FOR A PARTICULAR PURPOSE, WITH RESPECT TO THIS SITE AND THE MATERIAL
            ON THIS SITE. INTERSEC ALSO MAKES NO REPRESENTATIONS OR WARRANTIES
            AS TO WHETHER THE INFORMATION ACCESSIBLE ON THIS SITE IS ACCURATE,
            COMPLETE, OR CURRENT. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
            LAWS, IN NO EVENT SHALL INTERSEC BE LIABLE FOR ANY DAMAGES OF ANY
            KIND OR CHARACTER, INCLUDING WITHOUT LIMITATION ANY COMPENSATORY,
            INCIDENTAL, DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL
            DAMAGES, LOSS OF USE, LOSS OF DATA, LOSS OF INCOME OR PROFIT, LOSS
            OF OR DAMAGE TO PROPERTY, CLAIMS OF THIRD PARTIES, OR OTHER LOSSES
            OF ANY KIND OR CHARACTER, EVEN IF INTERSEC HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES OR LOSSES, ARISING OUT OF OR IN
            CONNECTION WITH THIS SITE OR THE CONTENTS OF THIS SITE. CERTAIN
            STATE LAWS MAY NOT PERMIT LIMITATIONS ON IMPLIED WARRANTIES OR THE
            EXCLUSION OR LIMITATION OF CERTAIN TYPES OF DAMAGES, AND THUS SOME
            OR ALL OF THE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS ABOVE MAY NOT
            APPLY TO YOU.
          </p>
          <p>
            You will indemnify and hold us harmless from and against any and all
            claims, losses, and damages, including attorneys’ fees, that arise
            in connection with your use of the Site or your breach of these
            Terms of Use and/or the Privacy Policy.
          </p>

          <p>
            <strong>Security</strong>
          </p>
          <p>
            We follow generally accepted industry standards to protect the
            information we collect. That said, no data security measures are
            100% secure. Therefore, while we implement reasonable physical,
            technical, and administrative measures to protect the information we
            collect, we cannot guarantee its absolute security.
          </p>

          <p>
            <strong>Changes to These Terms of Use</strong>
          </p>
          <p>
            We have the right to change these Terms of Use without prior
            notification to you. If we make a change to these Terms of Use, we
            will change the effective date above. We suggest you review these
            Terms of Use from time to time. If we materially change these Terms
            of Use, we will attempt to provide you with notice of such changes
            via email or by posting a notice on our Site.
          </p>

          <p>
            <strong>Choice of Law</strong>
          </p>
          <p>
            These Terms of Use shall be governed by the laws of the Commonwealth
            of Virginia without regard to its conflicts of law rules.
          </p>

          <p>
            <strong>Contact</strong>
          </p>
          <p>
            If you have any questions regarding these Terms of Use, please
            contact us at inquiries@intersecinc.com
          </p>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <p>Powered by InterSec, Inc.</p>
            </div>
            <div className="col-xl-4 col-md-4 text-center">
              <p>Copyright © 2021 InterSec, Inc.</p>
            </div>
            <div className="col-xl-4 col-md-4 text-right">
              <ul>
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                  <a href="https://intersecinc.com/contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default TermPage
