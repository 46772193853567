import React, { Component } from 'react'
import { ApiAuth, HTTPMETHODS } from '../core/api'
import ChartElement from './ChartElement'
import PieChart from './piechart'
import BoxContainer from './boxCointainer'

const Yes = '#3eb7e9'
const No = '#899dcc'
const Partially = '#feac5e'
const DoesNotApply = '#e39397'
const AlternativeApproach = '#c779d0'
const NotAnswered = '#f6f6f6'

let calculatedWidth, calculatedHeight

const backgroundColor = [
  Yes,
  No,
  Partially,
  DoesNotApply,
  AlternativeApproach,
]
class ResponseTypeDistribution extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      labels: []
    }

    this.getResponsiveTypeDistribution = this.getResponsiveTypeDistribution.bind(this)
  }

  componentWillMount() {
   
  }
  componentDidMount() {
    const modifiedWidth  = document.body.clientWidth
    if(modifiedWidth > 1600){
      // 90%
      calculatedWidth = 400
      calculatedHeight = 235
    }
    else if(modifiedWidth <= 1600){
    //  100%
      calculatedWidth = 400
      calculatedHeight = 157
    }

    this.setState({

      initPieChartData: {
        datasets: [{
          backgroundColor: backgroundColor,
          data: [0,0,0,0,0,100]
        }],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [
          'Yes',
          'No',
          'Partially',
          'Does Not Apply',
          'Alternative Approach',
          'Not Completed'
        ],
        label: [
          'Yes',
          'No',
          'Partially',
          'Does Not Apply',
          'Alternative Approach',
          'Not Completed'
        ]
      },
    })
    this.getResponsiveTypeDistribution()
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }

  async getResponsiveTypeDistribution() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const assessmentId = await this.getAssessmentIDResponsiveTypeDistribution()
      let responseData = await ApiAuth(
        HTTPMETHODS.GET,
        `/response/percentage?assessId=${assessmentId.data[0].id}&level=1`,
        // template -> baseURL/response?assessId=3443d085-dce3-4217-ab15-d0595f704325&level=1
        null,
        false,
        userObj.email,
        userObj.organizationId
      )

      const updatedDataset = [{
        backgroundColor: backgroundColor,
        data:
          [responseData.data.yes, responseData.data.no,
          responseData.data.partial, responseData.data.donotApply,
          responseData.data.alternatives, responseData.data.notAnswered]
      }]
      let currentData = { ...this.state.initPieChartData }
      currentData.datasets = updatedDataset
      this.setState({
        initPieChartData: currentData
      });


    } catch (e) {
      console.log('error in fetching responsive type distribution score', e.message)
    }
  }

  async getAssessmentIDResponsiveTypeDistribution() {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const orgId = encodeURIComponent(userObj.organizationId)
      let assessmentId = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${orgId}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      const assessmentIdValue = assessmentId;

      this.setState({ assessmentId: assessmentId })

      return assessmentId

    } catch (e) {
      console.log('error in fetching responsive type distribution score', e.message)
    }
  }

  render() {
    const { height,width } = this.props

    return (
      <>
        <div className="white_box">
          <h3 class="graph_title">Response Type Distribution</h3>
          <div
          style={{
            display: 'flex',
            alignItems:'center',
            justifyContent: 'center',
            position: 'relative',
            minHeight: 300,
            marginTop:'10px'
          }}
        >
            
            <ChartElement
              chartId="responseTypeDistribution"
              type="doughnut"
             data={this.state.initPieChartData}
             // dynamic
              width={calculatedWidth}
              height={calculatedHeight}
            />
            </div>
          
            {/* <PieChart
              data={this.state.initPieChartData}
              // width={200}
              // height={350}
            /> */}
              <div style={{
              marginBottom:'33px !important',
              marginTop:'20px !important',
              fontSize:'13px'
            }}>

             <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                 <li class="list-group-item " style={{border: 'none',padding:'1px',whiteSpace: 'pre'}}>
                 <BoxContainer color={Yes}  />Yes  </li> 
                 <li class="list-group-item ml-2 mr-2" style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={No} />No </li>

                 <li class="list-group-item " style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={Partially} />Partially </li>
                 </ul>
                 <ul style={{justifyContent: 'center'}} class="list-group list-group-horizontal-sm">
                 <li class="list-group-item mr-2" style={{border: 'none',padding:'1px',paddingBottom:'5px'}}>
                 <BoxContainer color={DoesNotApply} />Does Not Apply</li>

                 <li class="list-group-item" style={{border: 'none',padding:'1px'}}>
                 <BoxContainer color={AlternativeApproach} />Alternative Approach </li>
               
               </ul>

          </div>

        </div>
      </>
    )
  }
}

export default ResponseTypeDistribution