import './Accordian.css'
import { ApiAuth, HTTPMETHODS } from '../core/api'
import { Input, Radio } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Files from "react-butterfiles";
import swal from 'sweetalert'
const { TextArea } = Input
const descriptionRegex = /^[^<>\\]*$/
function Accordion(props) {
  const contentRef = useRef()
  const hiddenFileInput = useRef(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [uploadFiles, setUploadFiles] = useState([])
  const [files, setFiles] = useState([])
  const [uploadHandle, setUploadHandle] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  useEffect(() => {
    async function fetchUploadFiles() {
      const abortController = new AbortController()
      const signal = abortController.signal
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      try {
        const allUploadFiles = await ApiAuth(
          HTTPMETHODS.GET,
          `/file/list/${userObj.organizationId}`,
          null,
          false,
          userObj.email,
          userObj.organizationId,
          false,
          signal
        )
        setUploadFiles(allUploadFiles.data.fileInfo)
      } catch (e) {
        console.log('error in file uploading', e.message)
      }
      return function cleanup() {
        abortController.abort()
      }
    }
    fetchUploadFiles()
  }, [uploadHandle])
  const contentClasses = !props.isActive
    ? '0px'
    : `${contentRef.current && contentRef.current.scrollHeight + 20}px`
  const contentPadding = !props.isActive ? '0px 12px' : '12px'
  const createOrUpdateResponse = async (values) => {
    let { response, explanation } = props.oldProps
    if (values.response === response && values.explanation === explanation) {
      console.log('Form loaded fresh. Returning!')
      return
    }
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    let responseObj = {}
    responseObj.assessmentId = props.assessmentId
    responseObj.questionId = values.id
    responseObj.questionCode = values.practiceCode + '.' + values.questionNo
    responseObj.userId = userObj.id
    responseObj.organizationId = userObj.organizationId
    responseObj.response = values.response
    responseObj.explanation = values.explanation
    const assessmentReturnObj = await ApiAuth(
      HTTPMETHODS.POST,
      '/response',
      responseObj,
      true,
      userObj.email,
      userObj.organizationId
    )
    return assessmentReturnObj
  }
  const handlePreviousQuestion = async (e) => {
    props.onClick(props.index, false, 'prev')
    e.preventDefault()
  }
  const handleFile = (e) => {
    hiddenFileInput.current.click()
  }
  const handleFileDelete = async (questionCode, name) => {
    swal('Are you sure you want to delete this file?').then(async (val) => {
      if (val) {
        try {
          const userObj = JSON.parse(localStorage.getItem('userInfo'))
          const deleteFileInfo = await ApiAuth(
            HTTPMETHODS.DELETE,
            `/file/delete/${userObj.organizationId}/${questionCode}/${name}`,
            null,
            false,
            userObj.email,
            userObj.organizationId
          )
          setUploadHandle(deleteFileInfo.data)
        } catch (e) {
          console.log('error in file uploading', e.message)
        }
      }
    })
  }
  const handleFileChange = async (e) => {
    const fileUploaded = e.target.files[0]
    if (fileUploaded) {
      let fd = new FormData()
      fd.append('multipartFile', fileUploaded)
      const name = fileUploaded.name
      const fileLenByDot = fileUploaded.name.split('.').length
      const type = fileUploaded.name.split('.')[fileLenByDot - 1]
      const size = fileUploaded.size
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      let fileParams = {}
      fileParams.questionCode =
        props.questionObj.practiceCode + '.' + props.questionObj.questionNo
      fileParams.organizationId = userObj.organizationId
      const flag =
        name.length < 100 &&
        (type === 'docx' ||
          type === 'xlsx' ||
          type === 'pptx' ||
          type === 'vsd' ||
          type === 'pdf' ||
          type === 'png' ||
          type === 'jpeg' ||
          type === 'jpg' ||
          type === 'txt' ||
          type === 'gif' ||
          type === 'DOCX' ||
          type === 'XLSX' ||
          type === 'PPTX' ||
          type === 'VSD' ||
          type === 'PDF' ||
          type === 'PNG' ||
          type === 'JPEG' ||
          type === 'JPG' ||
          type === 'TXT' ||
          type === 'GIF') &&
        size < 5000000
      // if (flag) {
      //   const newFile = { name: name, size: size }
      //   setUploadFiles((prevState) => [...prevState, newFile])
      // }
      if (flag) {
        try {
          const newUploadHandle = await ApiAuth(
            HTTPMETHODS.POST,
            `/file/upload/${fileParams.organizationId}/${fileParams.questionCode}`,
            fd,
            false,
            userObj.email,
            userObj.organizationId,
            true
          )
          setUploadHandle(newUploadHandle.data)
        } catch (e) {
          console.log('error in file uploading', e.message)
        }
      } else {
        var str =
          `The specified file ${name}  could not be uploaded.\n` +
          'Only following file types are allowed: docx, pdf, xlsx, pptx, vsd, png, jpeg, jpg, gif, txt\n'
        swal('Sorry!', str, 'error')
        return
      }
    }
  }

  const uploadFile = async (e) => {
    const fileUploaded = e[0].src.file;
    if (fileUploaded) {
      let fd = new FormData()
      fd.append('multipartFile', fileUploaded)
      const name = fileUploaded.name
      
      const fileLenByDot = fileUploaded.name.split('.').length
      const type = fileUploaded.name.split('.')[fileLenByDot - 1]
      const size = fileUploaded.size
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      let fileParams = {}
      fileParams.questionCode =
        props.questionObj.practiceCode + '.' + props.questionObj.questionNo
      fileParams.organizationId = userObj.organizationId
      const flag =
        name.length < 100 &&
        (type === 'docx' ||
          type === 'xlsx' ||
          type === 'pptx' ||
          type === 'vsd' ||
          type === 'pdf' ||
          type === 'png' ||
          type === 'jfif' ||
          type === 'jfe' ||
          type === 'jpeg' ||
          type === 'jpg' ||
          type === 'txt' ||
          type === 'gif' ||
          type === 'DOCX' ||
          type === 'XLSX' ||
          type === 'PPTX' ||
          type === 'VSD' ||
          type === 'PDF' ||
          type === 'PNG' ||
          type === 'JFIF' ||
          type === 'JFE' ||
          type === 'JPEG' ||
          type === 'JPG' ||
          type === 'TXT' ||
          type === 'GIF') &&
        size < 5000000
      // if (flag) {
      //   const newFile = { name: name, size: size }
      //   setUploadFiles((prevState) => [...prevState, newFile])
      // }

      if (flag) {
        try {
          const newUploadHandle = await ApiAuth(
            HTTPMETHODS.POST,
            `/file/upload/${fileParams.organizationId}/${fileParams.questionCode}`,
            fd,
            false,
            userObj.email,
            userObj.organizationId,
            true
          )
          setUploadHandle(newUploadHandle.data)
        } catch (e) {
          console.log('error in file uploading', e.message)
        }
      } else {
        var str =
          `The specified file ${name}  could not be uploaded.\n` +
          'Only following file types are allowed: docx, pdf, xlsx, pptx, vsd, png, jpeg, jpg, gif, txt\n'
        swal('Sorry!', str, 'error')
        return
      }
    }
  }

  function findDescriptionRegex(value) {
    if (value.search(descriptionRegex) < 0) {
      setDescriptionError('Description should not contain <>\\ Character!')
      return true
    }
  }
  const handleNoteChange = async (e) => {
    props.handleChange(e)
    if (!findDescriptionRegex(e.target.value)) setDescriptionError('')
  }
  const handleNextQuestion = async (e, values) => {
    if (findDescriptionRegex(values.explanation)) return
    setDescriptionError('')
    e.preventDefault()
    //If the option is not chosen, Let's not allow Next button click!
    if (!values || !values.response || values.response === '') {
      return
    }
    //Let's call the API only when the values are touched!
    let { response, explanation } = props.oldProps
    if (values.response !== response || values.explanation !== explanation) {
      await createOrUpdateResponse(values)
      // make enable action buttons while submitting...
      setSubmitting(false)
    }
    props.onClick(props.index, false, 'next')
  }
  const handleSave = async (e, values, isFlag) => {
    if (findDescriptionRegex(values.explanation)) return
    setDescriptionError('')
    props.onClick(props.index, false, 'save')
    e.preventDefault()
    // make disable action buttons while submitting...
    setSubmitting(true)
    //Let's call the API only when the values are touched!
    let { response, explanation } = props.oldProps
    if (values.response === response && values.explanation === explanation) {
      // console.log('Form not touched. Returning!')
      // make enable action buttons while submitting...
      setSubmitting(false)
      // handle navigate for next action
      if (!isFlag) {
        props.handleNextCapacityQuestionData()
        return
      }
      props.history.push('/assessment')
      return
    }
    let responseObj = await createOrUpdateResponse(values)
    if (responseObj.status === 200) {
      // this.props.history.push('/assessment')
      // update survey data
      await props.fetchTotalAssessmentData()
      // make enable action buttons while submitting...
      setSubmitting(false)
      // handle navigate for next action
      if (!isFlag) {
        props.handleNextCapacityQuestionData()
        return
      }
      props.history.push('/assessment')
      return
    }
  }
  return (
    <div className="accordion__section">
      {!props.isFreeOrg &&
        (props.accordianLen > 1 ? (
          <div
            className={`${props.isActive ? 'active' : ''}`}
            onClick={() => props.onClick(props.index, !props.isActive, null)}
          >
            {props.isActive ? (
              <i className="fas fa-caret-down" />
            ) : !props.isActive ? (
              <i className="fas fa-caret-right" />
            ) : null}{' '}
            {props.questionObj.questionNo}. {props.questionObj.question}
            &nbsp;
          </div>
        ) : (
          <div className={`${props.isActive ? 'active' : ''}`}>
            {props.questionObj.questionNo}. {props.questionObj.question}
            &nbsp;
          </div>
        ))}
      <div
        ref={contentRef}
        style={{
          maxHeight: `${
            !props.isFreeOrg && props.accordianLen > 1 ? contentClasses : 'auto'
          }`,
          padding: `${contentPadding}`,
        }}
        className="accordion__content"
      >
        {props.questionObj.answerChoices && (
          <div className="form-group has-feedback row">
            <label className="col-form-label"></label>
            <div className="col-sm-6">
              <Radio.Group
                buttonStyle="solid"
                name="response"
                onChange={(e) => props.handleChange(e)}
                value={props.questionObj.response}
              >
                {props.questionObj.answerChoices.map((choice) => (
                  <div key={choice}>
                    <Radio value={choice}>{choice}</Radio>
                    <br />
                  </div>
                ))}
              </Radio.Group>
            </div>
          </div>
        )}



        {!props.isFreeOrg?<div>
        <div className="row">
          {props.questionObj.isExplanationRequired === true && (
            <div className="col-sm-12">
              <div className="form-group has-feedback">
                <label>
                  Implementation Details
                </label>
                <TextArea
                  rows={4}
                  name="explanation"
                  className={
                    descriptionError
                      ? 'form-control is-invalid'
                      : 'form-control'
                  }
                  value={props.questionObj.explanation}
                  onChange={(e) => handleNoteChange(e)}
                ></TextArea>
                {descriptionError ? (
                  <small className="text-danger">{descriptionError}</small>
                ) : null}
              </div>
            </div>
          )}
          {props.questionObj.isEvidenceRequired === true && (
            <div className="col-sm-12">
             {/*  <button id="upload_evidence_btn"
                type="submit"
                className="btn btn-sm btn-primary btn-rounded upload_btn"
                onClick={(e) => handleFile(e)}
              >
                {' '}
                Upload Evidences
              </button> */}




              <input
                type="file"
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)}
              />
              
            </div>
          )}
		  
        </div>
       

        <Files
    multiple={false} 
    maxSize="5mb"
    onSuccess={(e) => uploadFile(e)}
     onError={(error)=>console.log('error', error)}
> 

    {({ browseFiles, getDropZoneProps }) => (
        <>
            <div {...getDropZoneProps({ className: "myDropZone" })}> 

            
            <div align="center" className="dropHeader">
              <p className="drop-text">Upload Evidence. Drag File Here or</p>
            <a className="dropBrowse" onClick={(e) => handleFile(e)}>Browse</a>
            </div>

          
        <p className="note-text">Maximum 3 files can be uploaded. Allowed file types: docx, pdf, xlsx, pptx, vsd, png, jpeg, jpg, gif, txt</p>
     

            <div style={{ marginTop: '10px', marginLeft:'10px' }}>
                {uploadFiles &&
                  uploadFiles.map(function (file, index) {
                    return (
                      <div key={index}>
                        {file.questionCode ===
                          props.questionObj.practiceCode +
                            '.' +
                            props.questionObj.questionNo &&
                          file.fileNames.map(function (fileName, index) {
                            return (
                              <div key={index}>
                                <label className="filename-text">{fileName}</label>
                                <i
                                  className="fa fa-trash"
                                  style={{ marginLeft: '10px',fontSize:'12px', color: "#515151"}}
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleFileDelete(
                                      file.questionCode,
                                      fileName
                                    )
                                  }
                                ></i>
                                <br></br>
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
              </div>
           

            </div>
            
          
        </>
    )}
</Files>
</div>:null}



        <div className="row"><hr id="save_hr"/></div>
        <div className="row">
		
          <button id="savenxt_btn"
            disabled={isSubmitting}
            onClick={(e) => handleSave(e, props.questionObj, true)}
            type="submit"
            className="btn btn-sm btn-secondary btn-rounded"
          >
            {' '}
            Save and Exit
          </button>
          &nbsp;
          {props.questionObj.questionNo > 1 ? (
            <button
              onClick={(e) => handlePreviousQuestion(e)}
              type="submit"
              className="btn btn-sm btn-primary btn-rounded"
            >
              {' '}
              Previous
            </button>
          ) : null}
          &nbsp;
          {props.accordianLen > 1 &&
          props.questionObj.questionNo !== props.accordianLen ? (
            <button
              onClick={(e) => handleNextQuestion(e, props.questionObj)}
              type="button"
              className="btn btn-sm btn-primary btn-rounded"
            >
              {' '}
              Next
            </button>
          ) : null}
          &nbsp;
          {props.questionObj.questionNo === props.accordianLen && (
            <button
              disabled={isSubmitting}
              onClick={(e) =>
                handleSave(e, props.questionObj, props.isLastQuestion)
              }
              type="submit"
              className="btn btn-sm btn-primary btn-rounded"
            >
              {' '}
              {props.isLastQuestion ? 'Finish' : 'Next'}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
export default Accordion
