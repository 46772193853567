import React, { Component } from 'react'
import swal from 'sweetalert'
import { withRouter } from 'react-router-dom'
import { ApiAuth, HTTPMETHODS } from '../core/api'
import { Logout } from './logout'
import Logo from '../../assets/logo_new.jpg'
import './layout.css'
import { Auth } from 'aws-amplify'

let userData
const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orgName: '',
      userName: '',
      isFreeOrg:true,
      userData:null,
      isAdmin:false
    }

    this.getOrgName = this.getOrgName.bind(this)
    this.getUserName = this.getUserName.bind(this)
    this.getUpperName = this.getUpperName.bind(this)
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "resource.js";
    script.async = true;
    document.body.appendChild(script);
    this.userObj = JSON.parse(localStorage.getItem('userInfo'))
    this.getOrgName()
    this.getUserName()
    this.getUserGroupList(this.userObj)
  }

  async Logout(e) {
    e.preventDefault()
    try {
      const logoutStatus = await Logout()
      if (logoutStatus) {
        return this.props.history.push('/')
      }
    } catch (ex) {
      console.log("Access Token error");
    }
  }

  async LogoutOnException() {
    try {
      const logoutStatus = await Logout()
      if (logoutStatus) {
        return this.props.history.push('/')
      }
    } catch (ex) {
      console.log("Session Error");
      localStorage.removeItem('isUserLoggedIn')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('reloadCount')
      localStorage.removeItem('token')
      localStorage.removeItem('accessToken')
    }
  }

  async getOrgName() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))

    try {
      const resOrganization = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )

      if (resOrganization.data !== null) {
        this.setState({
           orgName: resOrganization.data.name,
           isFreeOrg: resOrganization.data.isFreeOrg
         })
      }
    } catch (e) {
      console.log(e.message)
      this.setState({ error_message: e.message })
      // Auth.signOut()
      this.LogoutOnException()
      localStorage.removeItem('isUserLoggedIn')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userEmail')
    }
  }

  getUserGroupList = async (userObj) => {
    try {
      const listUsers = await ApiAuth(
        HTTPMETHODS.GET,
        `/user/list/group`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (listUsers.data !== null) {
        await Promise.all(
          listUsers.data.map(async (item,index) => {
            if(item.email==userObj.email)
              if(item.role=='admin'||item.role=='Admin')
                  this.setState({
                      isAdmin:true
              })
          })
        )
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  getUserName() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    if (userObj) {
      const firstName = userObj.firstName || null
      const lastName = userObj.lastName || null
      const userName = firstName + ' ' + lastName
      this.setState({ userName })
    }
  }

  getUpperName(orgName) {
    const wrods = orgName.split(' ')
    const upperName = wrods.map((e) => capitalizeFirstLetter(e)).join(' ')
    return upperName
  }
  initProductFeed() {
    // alert('sample')
  }
  render() {
    return (
      <nav
        className="main-header navbar navbar-expand header-bar nav_new"
        style={{ marginLeft: '0px', height: '61px' }}
      >
        <div
          className="brand-link"
          style={{
            width: '239',
            display: 'block',
            padding: '0px',
          }}
        >
          <img
            src={Logo}
            width="150px"
            alt="Security Compliance Logo"
            className="brand-image elevation-3"
            style={{
              margin: '0px',
              width: '150px',
              height: '48px',
            }}
          ></img>
        </div>
        <span className="header-title" style={{ marginLeft: '260px' }}>
          {this.getUpperName(this.state.orgName)}
        </span>

        <ul className="navbar-nav ml-auto">
          <li className="text-position">
            <span className="nav-item" style={{ fontSize: '20px' }}>
              {this.getUpperName(this.state.userName)}
            </span>
          </li>
          <li className="text-position">
            <span className="nav-item" style={{ fontSize: '20px' , paddingLeft:'20px'}}
             onClick={this.initProductFeed}
             >
            <i class="fas fa-bell" aria-hidden="false"
              id="productfeed-trigger"
              target="_blank"
              href="/"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              // className="nav-link dropdown-toggle"
               ></i>
            </span>
          </li>
          <li className="nav-item dropdown">
            <a
              id="dropdownSubMenu1"
              target="_blank"
              href="/"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              className="nav-link dropdown-toggle"
            >
              My Account
            </a>
            <ul
              aria-labelledby="dropdownSubMenu1"
              className="dropdown-menu border-0 shadow leftAlign"
            >
              {/* <li>
                <a href="/account" className="dropdown-item">
                  Account Settings{' '}
                </a>
              </li>
              <li>
                <a href="/billing" className="dropdown-item">
                  Billing
                </a>
              </li> */}
              <li >
                <div
                  onClick={() => this.props.history.push('/accountsettings')}
                  className="btn btn-sm btn-primary logout_btn"
                >
                  <i className="fa fa-gear"></i> Account Settings
 </div>
              </li>

              <li >
                <div
                  onClick={() => this.props.history.push('/organization')}
                  className="btn btn-sm btn-primary logout_btn"
                >
                  <i className="far fa-building"></i> Organization
                </div>
              </li>
              {!this.state.isFreeOrg?
              this.state.isAdmin?
              <li style={{ paddingLeft: '0px' }}>
                <div
                  onClick={() => this.props.history.push('/manageUsers')}
                  className="btn btn-sm btn-primary logout_btn"
                >
                  <i className="far fa-user"></i> Users
                </div>
              </li>:null:null}
              <li >
                <div
                  onClick={(e) => this.Logout(e)}
                  className="btn btn-sm btn-primary logout_btn"
                >
                  <i className="fas fa-sign-out-alt"></i> Sign Out
                </div>
              </li>
            </ul>
          </li>
          {/* <li className="nav-item">
            <a href="/support" className="nav-link">
              Support{' '}
            </a>
          </li> */}
        </ul>
      </nav>
    )
  }
}

export default withRouter(Header)