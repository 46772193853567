import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ChartElement from './ChartElement'
import moment from "moment";

const totalQuestionsFreeOrg = 17
const totalQuestionsPaidOrg = 45
let assessmentId1
class SummaryTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      remaining: 0,
      completed: 0,
      uploadedCount: 0,
      assessmentDaysRemaining: 0,
      isFreeOrg: null,
      assessments:null,
      assessmentDaysRemainingColor:'',
      assessmentDaysRemainingFontSize:'25px'
    }

    /* this.getDomainQuestionData = this.getDomainQuestionData.bind(this) */
  }

  componentWillmount = async () => {
    // await this.getOrgInfo()
  }

  componentDidMount = async () => {
    await this.getSummaryData()
    await this.getSummaryDataCompleted()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }

  getOrgInfo = async () => {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const resOrg = await ApiAuth(
        HTTPMETHODS.GET,
        `/organization/${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      
      await this.setState({ isFreeOrg: resOrg.data.isFreeOrg })
      await this.assessmentDaysRemaining(resOrg)

    } catch (error) {
      console.log(error.message)
    }
  }

  async getSummaryData() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    const orgId = encodeURIComponent(userObj.organizationId)
    // assessmentId1 = await this.getAssessmentID()
    // const assessmentId = assessmentId1?assessmentId1.data[0].id:null 
    assessmentId1 = await this.getAssessmentID()
    if (assessmentId1)
      try {
        const assessmentId = assessmentId1.data[0].id
        const responseDataFilescount = await ApiAuth(
          HTTPMETHODS.GET,
          `/file/filecount?orgId=${orgId}&assessId=${assessmentId}&level=1`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        )
        this.setState({ uploadedCount: responseDataFilescount.data })
      } catch (e) {
        console.log('error in fetching compliance score', e.message)
      }
  }
  async getAssessmentID() {
    try {
      const isFreeOrg = await this.getOrgInfo()
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const orgId = encodeURIComponent(userObj.organizationId)

      let assessmentId = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${orgId}&level=1`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )

      this.setState({ assessmentId: assessmentId })

      return assessmentId

    } catch (e) {
      console.log('error in fetching responsive type distribution score', e.message)
    }
  }
  async getSummaryDataCompleted() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))
    const orgId = encodeURIComponent(userObj.organizationId)
    assessmentId1 = await this.getAssessmentID()
    if (assessmentId1)
      try {
        const assessmentId = assessmentId1.data[0].id
        let responseCompletedCount = await ApiAuth(
          HTTPMETHODS.GET,
          `/response/count?assessId=${assessmentId}&level=1`,
          null,
          false,
          userObj.email,
          userObj.organizationId
        )
        this.state.isFreeOrg ?
          this.setState({
            remaining: responseCompletedCount.data.notAnsweredTotal,
            completed: totalQuestionsFreeOrg -
              responseCompletedCount.data.notAnsweredTotal
          }) :
          this.setState({
            remaining: responseCompletedCount.data.notAnsweredTotal,
            completed: totalQuestionsPaidOrg -
              responseCompletedCount.data.notAnsweredTotal
          })
        
        
      } catch (e) {
        console.log('error in fetching compliance score', e.message)
      }
  }

  getAssessments = async () => {
    try {
      const userObj = JSON.parse(localStorage.getItem('userInfo'))
      const lstAssessments = await ApiAuth(
        HTTPMETHODS.GET,
        `/assessment?orgId=${encodeURIComponent(userObj.organizationId)}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )
      if (lstAssessments.data !== null) {
        await Promise.all(
          lstAssessments.data.map(async (item) => {
           /*  if (item.name.length > 20) {
              item.name = item.name.substring(0, 20)
            } */
            var oldDate = item.updatedDate.split(' ')[0]
            var mm = oldDate.split('-')[1]
            var dd = oldDate.split('-')[2]
            var yy = oldDate.split('-')[0]
            var newDate = mm + '/' + dd + '/' + yy
            var newTime = item.updatedDate.split(' ')[1].split('.')[0]
            item.updatedDate = newDate + ' ' + newTime
            let newItem = item
            try {
              let responseCountData = await ApiAuth(
                HTTPMETHODS.GET,
                `/response/count?assessId=${item.id}&level=${item.cmmcLevel}`,
                null,
                false,
                userObj.email,
                userObj.organizationId
              )
              let totalQuestions = responseCountData.data.totalQuestions
              let answeredQuestion = totalQuestions - responseCountData.data.notAnsweredTotal
              let notAnsweredTotal = responseCountData.data.notAnsweredTotal
                if(totalQuestions === notAnsweredTotal){
                  newItem.processStatus = 'Created';
                }
                else if(notAnsweredTotal === 0){
                  newItem.processStatus = 'Completed';
                }
                else if(notAnsweredTotal > 0){
                  newItem.processStatus = 'In Progress';
                }
               
              newItem.questionCount = answeredQuestion + ' / ' + totalQuestions
              newItem.isAnswered = answeredQuestion === totalQuestions
            } catch (error) {
              console.log(error.message)
            }
            return newItem
          })
        )
        this.setState({ assessments: lstAssessments.data })
        this.assessmentDaysRemaining(this.state.assessments)
      }
    } catch (error) {
      console.log(error)
      this.setState({ assessments: null })
      this.setState({ error_message: error.message })
    }
  }

  async splitDate(date) {
    
      var dateString = toString(date)
      var oldDate = dateString.split('T')[0]
      var mm = oldDate.split('-')[1]
      var dd = oldDate.split('-')[2]
      var yy = oldDate.split('-')[0]
      var newDate = mm + '/' + dd + '/' + yy
      return newDate
  }

  async assessmentDaysRemaining()
  {   
      const startDate = moment(this.state.assessmentId.data[0].plannedStartDate)
      const timeEnd = moment(this.state.assessmentId.data[0].plannedEndDate)
      const formattedStartDate = startDate.format('DD/MM/YYYY')
      const formattedEndDate = timeEnd.format('DD/MM/YYYY')
      const currentDate = moment()
      const diff = timeEnd.diff(currentDate)
      const diffDuration = moment.duration(diff)
      const dateRemaining = Math.ceil(diffDuration / (1000 * 60 * 60 * 24)); 

      if(this.state.assessmentId.data[0].plannedStartDate&&this.state.assessmentId.data[0].plannedEndDate){
        if(moment() > timeEnd || dateRemaining <= 1)
        {
          this.setState({
            assessmentDaysRemaining:"Assessment Overdue",
            assessmentDaysRemainingColor:'red',
            assessmentDaysRemainingFontSize:'13px'
          }) 
        }
        else {
          this.setState({
            assessmentDaysRemaining:dateRemaining
          }) 
        }
      }
      else {
        this.setState({
          assessmentDaysRemaining:'-'
        }) 
      } 
  }

  render() {
    let that = this;
    const { chartData, chartOptions,isFreeOrg } = this.state
    const { width } = this.props
    return (
      <>
        <div className="white_box">
          <h3 class="graph_title">Summary</h3>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px',
              background: '#FFFFFF',
              textAlign: 'center',
              borderWidth: '1px',
              color: '#4c4c4c'
            }}
          // height: 'calc(100vh - 250px)',
          >
            <br /><br /><br />
            <Container >
              <div className='summary_table'>
              <Row>
                <Col style={{
                  borderRight: '1px solid black',
                  height: '5%', margin: '5% 0% 5% 0%' 
                }}>

                  <div style={{ fontWeight: 'bold', padding: '5px', margin: '5% 0% 5% 0%' }}>
                    Total <br />Questions <br /> Completed
                  </div>
                  <div style={{ fontWeight: 'bold', padding: '7px', fontSize: '25px' }}>
                    {this.state.completed}
                  </div>
                </Col>
                <Col style={{
                  borderRight: '1px solid black',
                  height: '5%', margin: '5% 0% 5% 0%'
                }}>

                  <div style={{ fontWeight: 'bold', padding: '5px', margin: '5% 0% 5% 0%' }}>
                    Total <br />Questions <br /> Remaining
                  </div>
                  <div style={{ fontWeight: 'bold', padding: '7px', fontSize: '25px' }}>
                    {this.state.remaining}
                  </div>
                </Col>
                <Col style={{ height: '5%', borderRight: '1px solid black', margin: '5% 0% 5% 0%' }}>

                  <div style={{ fontWeight: 'bold', padding: '5px', margin: '5% 0% 5% 0%' }}>
                    Total <br /> File <br /> Size (MB)
                  </div>
                  <div style={{ fontWeight: 'bold', padding: '7px', fontSize: '25px' }}>
                    {this.responseDataFilescount&&!isFreeOrg ? this.responseDataFilescount : isFreeOrg?'N/A':this.state.uploadedCount}
                  </div>
                </Col>
                <Col style={{ height: '5%', margin: '5% 0% 5% 0%' }}>

                  <div style={{ fontWeight: 'bold', padding: '5px', margin: '5% 0% 5% 0%' }}>
                    Assessment <br /> Days <br /> Remaining
                  </div>
                  <div style={{ fontWeight: 'bold', padding: '7px', fontSize: this.state.assessmentDaysRemainingFontSize ,
                   color:this.state.assessmentDaysRemainingColor, wordWrap: 'break-word' }}>
                  {this.state.assessmentDaysRemaining ? this.state.assessmentDaysRemaining : '-'}
                  </div>
                </Col>
              </Row>
              </div>
            </Container>
          </div>
        </div>
      </>
    )
  }
}

export default SummaryTable