import { ApiAuth, HTTPMETHODS } from '../../components/core/api'
import React, { Component } from 'react'
import { Formik } from "formik";
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import swal from 'sweetalert'
import { Container, Row, Col } from 'react-bootstrap'
import { Card } from 'antd'
import { bcAccountSettings } from '../core/breadcrumbpaths'
import Breadcrumb from '../core/breadcrumb'
import _ from 'lodash'

const titleRegex = /^[0-9a-zA-Z ,.]*$/i
const nameRegax = /^[0-9a-zA-Z '-.]*$/i

const validationSchemaSimple = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
});

let userObj = {
    firstName: '',
    lastName: '',
    title: ''
}

const validationSchema = Yup.object().shape({
    firstName: Yup
        .string()
        .min(2, "First Name is Too Short!")
        .max(25, "First Name is Too Long!")
        .required("First Name is Required")
        .matches(nameRegax, "First Name should only contain Alphanumeric characters"),

    lastName: Yup
        .string()
        .min(2, "First Name is Too Short!")
        .max(25, "Last Name is Too Long!")
        .required("Last Name is Required!")
        .matches(nameRegax, "Last Name should only contain Alphanumeric characters"),

    title: Yup.string()
        .min(2, "Title is Too Short!")
        .max(100, "Title is Too Long!")
        .required("Title is Required")
        .matches(titleRegex, "Title should only contain Alphanumeric characters"),
})
var that
class AccountSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editStatus: false,
            email: '',
            firstName: '',
            lastName: '',
            title: '',
            userid: '',
            isUpdate: false
        }
        this.toggleEditStatusTrue = this.toggleEditStatusTrue.bind(this)
        this.toggleEditStatusFalse = this.toggleEditStatusFalse.bind(this)

    }

    componentWillMount() {
        that = this
        userObj = JSON.parse(localStorage.getItem('userInfo'))
        this.setState({
            firstName: userObj.firstName,
            lastName: userObj.lastName,
            email: userObj.email,
            title: userObj.title,
            userid: userObj.id,
            editStatus: false
        })
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }


    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    // shouldComponentUpdate(nextProps, nextState) {
    //   if (nextState == this.state) return false
    //   return true
    // }

    toggleEditStatusTrue() {
        this.setState({
            editStatus: _.cloneDeep(true)
        })
    }

    async toggleEditStatusFalse() {
        this.setState({
            editStatus: _.cloneDeep(false)
        })
    }
    showUpdatedSuccessMsg(updatedUserData) {
        localStorage.setItem('userInfo', JSON.stringify(updatedUserData.data))
        return "success"
    }

    updateUserdata(formValues) {
        try {
            const userObj = JSON.parse(localStorage.getItem('userInfo'))
            const orgId = encodeURIComponent(userObj.organizationId)
            const payload = {

                "id": this.state.userid,
                'firstName': formValues.firstName,
                'lastName': formValues.lastName,
                'title': formValues.title,
                "email": this.state.email,
            }
            ApiAuth(
                HTTPMETHODS.PUT,
                `/user/`,
                payload,
                true,
                userObj.email,
                userObj.organizationId
            ).then((res) => {
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                this.setState({
                    editStatus: _.cloneDeep(false)
                })

                swal("Success!", "The Account Details has been updated.", "success")
                    .then((value) => {
                        window.location.reload();
                    })

            })

        } catch (e) {
            console.log('error in fetching data', e.message)
        }
    }



    render() {
        return (
            <div
                className="content-wrapper"
                style={{
                    width: '100%',
                    height: '100%',
                    marginLeft: '0px',
                    overflow: 'hidden',
                }}
            >
                <Breadcrumb pathsObject={bcAccountSettings} />
                <section style={{ width: '80%', margin: '0 auto' }} className="content">
                    <div className="container-fluid ">
                        <Card className="card" bordered={false}>
                            <Formik

                                initialValues={{ firstName: this.state.firstName, lastName: this.state.lastName, title: this.state.title }}
                                validationSchema={validationSchema}
                            >

                                {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, editStatus, setErrors }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='align-center'>
                                            <div className='alignFormCenter'>
                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer align-right">
                                                        <label className=" col-form-label align-right p-2 mb-3 ">
                                                            <span style={{ color: 'red', paddingRight: '5px' }}></span>
                                                         Email : </label>
                                                    </div>
                                                </div>
                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer">
                                                        <label className=" col-form-label align-right p-2 mb-3 "> {this.state.email} </label>
                                                    </div>
                                                </div>

                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer align-right">
                                                        <label className=" col-form-label align-right p-2 mb-3 ">
                                                            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
                                                         First Name : </label>
                                                    </div>
                                                </div>
                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer">
                                                        {this.state.editStatus ? <input
                                                            name="firstName"
                                                            type="text"
                                                            placeholder="Enter Your First Name"
                                                            className={
                                                                errors.firstName && touched.firstName
                                                                    ? 'form-control is-invalid  col-sm-4 col-form-input align-left'
                                                                    : 'form-control  col-sm-4 col-form-input align-left'
                                                            }
                                                            value={values.firstName}
                                                            onChange={handleChange}
                                                        /> : <label className=" col-form-label align-right p-2 mb-3 "> {this.state.firstName} </label>
                                                        }
                                                        {errors.firstName && touched.firstName ? (
                                                            <small className="text-danger">{errors.firstName}</small>
                                                        ) : null}
                                                    </div>
                                                </div>


                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer align-right">
                                                        <label className=" col-form-label align-right p-2 mb-3 ">
                                                            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
                                                        Last Name : </label>
                                                    </div>
                                                </div>
                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer">
                                                        {this.state.editStatus ? <input
                                                            name="lastName"
                                                            type="text"
                                                            placeholder="Enter Your Last Name"
                                                            className={

                                                                errors.lastName && touched.lastName
                                                                    ? 'form-control is-invalid  col-sm-4 col-form-input align-left'
                                                                    : 'form-control  col-sm-4 col-form-input align-left'
                                                            }
                                                            value={values.lastName}
                                                            onChange={handleChange}
                                                        /> :
                                                            <label className=" col-form-label align-right p-2 mb-3 "> {this.state.lastName} </label>
                                                        }
                                                        {errors.lastName && touched.lastName ? (
                                                            <small className="text-danger">{errors.lastName}</small>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer align-right">
                                                        <label className=" col-form-label align-right p-2 mb-3 ">
                                                            <span style={{ color: 'red', paddingRight: '5px' }}>*</span>
                                                         Title : </label>
                                                    </div>
                                                </div>

                                                <div className="form-group has-feedback row col_hlf">
                                                    <div className="col-sm-12 form_outer " style={{ height: '25px !important' }}>
                                                        {this.state.editStatus ? <input
                                                            name="title"
                                                            type="text"
                                                            placeholder=" Enter Your Title "
                                                            className={
                                                                errors.title && touched.title
                                                                    ? 'form-control is-invalid  col-sm-4 col-form-input align-left'
                                                                    : 'form-control  col-sm-4 col-form-input align-left'
                                                            }
                                                            value={values.title}
                                                            onChange={handleChange}

                                                        /> :

                                                            <label className=" col-form-label align-right p-2 mb-3 "> {this.state.title} </label>
                                                        }
                                                        {errors.title && touched.title ? (
                                                            <small className="text-danger">{errors.title}</small>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="form-group has-feedback row col_hlf">

                                                </div>


                                            </div>
                                            {!this.state.editStatus ? <div style={{ textAlign: 'center' }}>
                                                <button
                                                    className="col-2 btn btn-primary  mr-2"
                                                    type="button"
                                                    onClick={this.toggleEditStatusTrue}
                                                >
                                                    Edit
                                              </button>

                                                <Link to="/passwordchange">Change Password</Link>

                                            </div> :
                                                <div style={{ textAlign: 'center' }}>
                                                    <button
                                                        className="col-2 btn btn-primary  mr-2"
                                                        // disabled={isSubmitting}
                                                        // type="submit"
                                                        onClick={() => this.updateUserdata(values)}
                                                    >
                                                        Update
                                          </button>
                                                    <button
                                                        type="Button"
                                                        className="col-2 btn btn-primary "
                                                        onClick={() => {
                                                            this.toggleEditStatusFalse()
                                                            setErrors({})
                                                        }}
                                                    >
                                                        Cancel
                                          </button>
                                                </div>}
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </Card>
                    </div>
                </section>
            </div>
        )
    }
}

export default AccountSettings