import './responseCountReport.css'

import { ApiAuth, HTTPMETHODS } from '../core/api'
import React, { Component } from 'react'

import ChartElement from './ChartElement'
import html2canvas from 'html2canvas'

class responseCountReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      yesData: [],
      noData: [],
      partialData: [],
      noApplyData: [],
      alterData: [],
      labels: [],
      chartData: {},
      chartOptions: {},
      doSomething: true,
    }

    this.getResponseCount = this.getResponseCount.bind(this)
  }

  componentDidMount = async () => {
    await this.getResponseCount()
    const {
      yesData,
      noData,
      partialData,
      noApplyData,
      alterData,
      labels,
    } = this.state
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Yes',
          backgroundColor: '#3498db',
          data: yesData,
        },
        {
          label: 'No',
          backgroundColor: '#e67300',
          data: noData,
        },
        {
          label: 'Partially',
          backgroundColor: '#C8C8C8',
          data: partialData,
        },
        {
          label: 'Does Not Apply',
          backgroundColor: '#ffbf00	',
          data: noApplyData,
        },
        {
          label: 'Alternative Approach',
          backgroundColor: '#0033cc',
          data: alterData,
        },
      ],
    }

    const chartOptions = {
      responsive: false,
      legend: {
        display: false,
      },

      animation: {
        animateScale: false,
        animateRotate: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              max: 6,
              min: 0,
            },
            gridLines: {
              drawBorder: false,
              display: true,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
    }
    this.setState({ chartData, chartOptions })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) return false
    return true
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.chartData !== this.state.chartData) {
      if (
        this.state.chartData &&
        this.state.chartData.datasets &&
        this.state.doSomething
      ) {
        this.setState({ doSomething: true })
        html2canvas(document.getElementById('responseCountTable'), {
          logging: true,
          profile: true,
          useCORS: true,
          allowTaint: true,
          width: this.props.width,
          height: this.props.height,
        }).then((canvas) => {
          var data = canvas.toDataURL('image/png')
          this.props.handleBaseURL(1, data)
        })
        this.setState({ doSomething: false })
      }
    }
  }
  async getResponseCount() {
    const userObj = JSON.parse(localStorage.getItem('userInfo'))

    try {
      let responseCountData = await ApiAuth(
        HTTPMETHODS.GET,
        `/response/count?assessId=${this.props.assessId}&level=${this.props.level}`,
        null,
        false,
        userObj.email,
        userObj.organizationId
      )

      responseCountData = responseCountData.data.responsesCountAssementList.sort(
        function (a, b) {
          if (a.name > b.name) {
            return 1
          }
          if (a.name < b.name) {
            return -1
          }
          return 0
        }
      )

      let yesData = [],
        noData = [],
        partialData = [],
        noApplyData = [],
        alterData = [],
        labels = []
      responseCountData.forEach((item) => {
        labels.push(item.code)
        yesData.push(item.yes)
        noData.push(item.no)
        partialData.push(item.partially)
        noApplyData.push(item.doesNotApply)
        alterData.push(item.alternativeApproach)
      })
      this.setState({
        labels,
        yesData,
        noData,
        partialData,
        noApplyData,
        alterData,
      })
    } catch (e) {
      console.log(e.message)
    }
  }

  render() {
    const { labels, chartData, chartOptions } = this.state
    const { height, width } = this.props
    return (
      <div
        id="responseCountTable"
        className="responseLayout"
        style={{ width: width, height: height }}
      >
        {chartData && (
          <div className="responseChart">
            <div className="numberResponse">Number of Response</div>
            <ChartElement
              chartId="responseCountChart"
              type="bar1"
              data={chartData}
              options={chartOptions}
              width={(width * 88) / 100 - 60}
              height={height / 2}
              // handleBaseURL={this.props.handleBaseURL}
            />
          </div>
        )}
        {chartData.datasets && (
          <table>
            <thead>
              <tr>
                <td></td>
                {labels.map((item, key) => {
                  return <td key={key}>{item}</td>
                })}
              </tr>
            </thead>
            <tbody>
              {chartData.datasets.map((item, key) => (
                <tr key={key}>
                  <td>
                    <div
                      className="labelColor"
                      style={{ background: item.backgroundColor }}
                    ></div>
                    <div>{item.label}</div>
                  </td>
                  {item.data.map((item1, key1) => (
                    <td key={key1}>{item1}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

export default responseCountReport
